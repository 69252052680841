import React, { ReactNode } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import Dashboard from "./components/Dashboard";
import UserProfile from "./components/UserProfile";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import useGlobalIntercept from "hooks/useGlobalIntercept";

import "./assets/css/App.css";
import initialTheme from "./theme/theme";
import { useState } from "react";

const SessionWrapperComponent: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/auth/sign-in/default"
        replace
      />
    );
  }
  //console.log("isAuthenticated", isAuthenticated)
  return <>{children}</>;
};

/* const Navigation: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <nav>
      <ul>
        <li><Link to="/">Home</Link></li>
        {!isAuthenticated && (
          <>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/register">Register</Link></li>
          </>
        )}
        {isAuthenticated && (
          <>
            <li><Link to="/dashboard">Dashboard</Link></li>
            <li><Link to="/profile">Profile</Link></li>
            <li><Logout /></li>
          </>
        )}
      </ul>
    </nav>
  );
}; */

const App: React.FC = () => {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  // useGlobalIntercept()
  return (
    <AuthProvider>
      <GlobalInterceptorWrapper>
        <Routes>
          <Route
            path="/auth/*"
            element={<AuthLayout />}
          />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/"
              element={
                <Navigate
                  to="/admin/dashboards"
                  replace
                />
              }
            />
            <Route
              path="/admin/*"
              element={
                <SessionWrapperComponent>
                  <AdminLayout
                    theme={currentTheme}
                    setTheme={setCurrentTheme}
                  />
                </SessionWrapperComponent>
              }
            />
            <Route
              path="/dashboard"
              element={<Dashboard />}
            />
            <Route
              path="/profile"
              element={<UserProfile />}
            />
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to="/auth/sign-in/default"
                replace
              />
            }
          />
        </Routes>
      </GlobalInterceptorWrapper>
    </AuthProvider>
  );
};

const GlobalInterceptorWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useGlobalIntercept();
  return <>{children}</>;
};

export default App;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  useColorModeValue,
  useToast,
  //SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Icon,
  Tab,
  Collapse,
} from '@chakra-ui/react';
import { AddIcon, ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons'; // Import the MenuDots icon
import Card from 'components/card/Card';
import ReportWizard from './components/ReportWizard';
import { reportService } from '../../../../services/reportService';
import ZoneListPage from '../zones/ZoneListPage';
import axios from 'axios';
import { useAuth } from '../../../../contexts/AuthContext';
//import AiChat from 'components/AiChat';
//import { ReactComponent as DibiAiIcon } from '../../../../assets/svg/dibiai.svg';
import { FaFileAlt } from 'react-icons/fa';
import { zoneService } from '../../../../services/zoneService';

interface Condition {
  field: string;
  condition: string;
  value: string | number;
  id: string;
  type: string;
  op: string;
  options: string[]
}

interface Report {
  _id: string;
  zone: string;
  zoneId: string;
  zoneType: string;
  conditions: Condition[];
  order: number; // Add this line
  // Add the missing properties
  body: any;
  type: string;
  url: string;
  toJSON: () => string;
  zoneName: string
}

const ReportsPage: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [currReportResult, setCurrReportResult] = useState<any[]>([]);
  const [currReportZoneId, setCurrReportZoneId] = useState<string>("");
  const [currReportId, setCurrReportId] = useState<string>("");
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [editingReport, setEditingReport] = useState<Report | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentUser, setCurrentUser] = useState<{ companyId: string } | null>(null);
  const companyId = useAuth().user.companyId;
  const userEmail = useAuth().user.email;
  const userRole = useAuth().user.role;
  const [isLeftColumnMinimized, setIsLeftColumnMinimized] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showDetailedView, setShowDetailedView] = useState(false);



  const handleTabChange = useCallback((index: number) => {
    setActiveTab(index);
  }, []);

  // Memoize the left column content
  const LeftColumnContent = useMemo(() => {
    if (isLeftColumnMinimized) {
      return (
        <VStack spacing={4} pt={2}>
          <IconButton
            aria-label="Reports"
            icon={<Icon as={FaFileAlt} />}
            size="sm"
            variant={activeTab === 0 ? "lightBrand" : "outline"}
            colorScheme="brand"
            onClick={() => handleTabChange(0)}
          />
          {/* AI Assistance tab temporarily disabled
          <IconButton
            aria-label="AI Assistance"
            icon={<DibiAiIcon width="20px" height="20px" />}
            size="sm"
            variant={activeTab === 1 ? "lightBrand" : "outline"}
            colorScheme="brand"
            onClick={() => handleTabChange(1)}
          />
          */}
        </VStack>
      );
    }

    return (
      <Tabs variant="soft-rounded" colorScheme="brand" onChange={handleTabChange}>
        <TabList mb={4}>
          <Tab>Pre-saved reports</Tab>
          {/* AI Assistance tab temporarily disabled
          <Tab>AI Assistance</Tab>
          */}
        </TabList>

        <TabPanels>
          <TabPanel p={0} height="100%">
            <VStack align="stretch" spacing={4} height="100%" overflow="hidden">
              <HStack justify="space-between" flexShrink={0}>
                <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                  Pre-saved reports
                </Text>
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="brand"
                  fontSize="12px"
                  size="sm"
                  onClick={() => setIsWizardOpen(true)}
                >
                  CREATE NEW REPORT
                </Button>
              </HStack>
              <Box flex="1" overflowY="auto" marginRight="-16px" paddingRight="16px">
                {reports.length === 0 ? (
                  <Text color={textColor}>
                    You currently have no reports. Add a new report using the "CREATE NEW REPORT" button above.
                  </Text>
                ) : (
                  <VStack align="stretch" spacing={4} height="100%">
                    {reports.map((report, index) => (
                      <Card key={index} p={2} borderWidth={1} borderRadius="md" boxShadow="none">
                        <Flex justify="space-between" align="flex-start">
                          <VStack align="stretch" spacing={1}>
                            <Text fontWeight="bold">{report.zone || report.zoneName}</Text>
                            {report.conditions && Array.isArray(report.conditions) && report.conditions.map((condition: Condition, condIndex: number) => (
                              <Text key={condIndex} fontSize="sm">
                                {condition.field} - {condition.condition} - {condition.value}
                              </Text>
                            ))}
                          </VStack>
                          <HStack mt="8px">
                            <Button
                              colorScheme="brand"
                              variant="outline"
                              size="sm"
                              onClick={() => runReport(report, 'render')}
                            >
                              Run Report
                            </Button>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={<MenuDots boxSize={6} />}
                                variant="ghost"
                                size="sm"
                              />
                              <MenuList>
                                <MenuItem onClick={() => setEditingReport(report)}>Edit</MenuItem>
                                <MenuItem onClick={() => duplicateReport(report)} isDisabled={isLoading}>
                                  {isLoading ? "Duplicating..." : "Duplicate"}
                                </MenuItem>
                                <MenuItem onClick={() => deleteReport(report._id)} isDisabled={isLoading}>
                                  {isLoading ? "Deleting..." : "Delete"}
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </HStack>
                        </Flex>
                      </Card>
                    ))}
                  </VStack>
                )}
              </Box>
            </VStack>
          </TabPanel>

          {/* AI Assistance tab temporarily disabled
          <TabPanel p={0}>
            <Flex direction="column" height="100%">
              <Flex alignItems="center" mb={2}>
                <DibiAiIcon width="95px" height="30px" style={{ marginRight: '15px' }} />
                <Text fontSize="2xl" fontWeight="bold" color={textColor} mt="14px">
                  assistance
                </Text>
              </Flex>
              <Box flex="1">
                <AiChat companyId={companyId} />
              </Box>
            </Flex>
          </TabPanel>
          */}
        </TabPanels>
      </Tabs>
    );
  }, [isLeftColumnMinimized, activeTab, textColor, companyId, handleTabChange]);

  // Memoize the right column content
  const RightColumnContent = useMemo(() => {
    // Find the current report being displayed
    let currentReport = reports.find(r => r._id === currReportId);
    
    // Fallback: If report not found by ID, try to find by zoneId and matching conditions
    if (!currentReport && currReportZoneId) {
      currentReport = reports.find(r => 
        r.zoneId === currReportZoneId && 
        r.conditions?.some(c => c.condition === 'sum')
      );
    }
    
    // Debug info
    console.log('Looking for report with ID:', currReportId);
    console.log('Fallback to zoneId:', currReportZoneId);
    console.log('Found report:', currentReport);
    
    // Check if any condition is a sum condition
    const hasSumCondition = currentReport?.conditions?.some(c => c.condition === 'sum');

    return (
      <Card height="100%" display="flex" flexDirection="column">
        <Box flex="1" overflow="auto">
          {currReportResult.length > 0 ? (
            <>
              {hasSumCondition && currentReport ? (
                <VStack align="stretch" p={5} spacing={4}>
                  <Text fontSize="xl" fontWeight="bold">Summary Report for "{currentReport.zoneName || currentReport.zone}"</Text>
                  
                  <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <Box bg="orange.50" p={4} borderBottomWidth="1px">
                      <Flex justify="space-between" align="center">
                        <Text fontSize="lg" fontWeight="semibold" color="orange.600">Calculated Results</Text>
                        <Text color="gray.600" fontSize="sm">{currReportResult.length} records found</Text>
                      </Flex>
                    </Box>
                    
                    <VStack align="stretch" spacing={0} divider={<Box borderBottomWidth="1px" />}>
                      {currentReport.conditions.filter(c => c.condition === 'sum').map((condition, idx) => {
                        // Determine if this is a price field that should be grouped by currency
                        const isPriceField = condition.field.toLowerCase().includes('price') || 
                                          condition.field.toLowerCase().includes('cost') || 
                                          condition.field.toLowerCase().includes('fee') ||
                                          condition.field.toLowerCase().includes('cap') ||  // For "Expense Caps"
                                          condition.field.toLowerCase().includes('guarantee') ||  // For "Advance/Minimum Guarantee"
                                          condition.field.toLowerCase().includes('advance') ||  // For "Advance/Minimum Guarantee"
                                          condition.type === 'price';
                        
                        // For regular number fields, calculate a simple sum
                        if (!isPriceField) {
                          const sum = currReportResult.reduce((total, item, idx) => {
                            console.log(`Looking for field with ID: ${condition.id} (${condition.field})`);
                            
                            if (idx === 0) {
                              console.log('Sample of first item data structure:', JSON.stringify(item.data ? 
                                Object.keys(item.data).slice(0, 3).reduce((acc, key) => ({ ...acc, [key]: item.data[key] }), {}) 
                                : {}, null, 2));
                            }
                            
                            let value: number | undefined;
                            let valueSource = 'not found';
                            
                            if (item.data) {
                              Object.entries(item.data).forEach(([key, dataObj]) => {
                                try {
                                  if (dataObj && typeof dataObj === 'object') {
                                    if ('key' in dataObj && (dataObj as any).key === condition.id) {
                                      const rawValue = (dataObj as any).value;
                                      
                                      if (typeof rawValue === 'string' && rawValue.includes(',')) {
                                        const cleanValue = Number(rawValue.replace(/,/g, ''));
                                        if (!isNaN(cleanValue)) {
                                          value = cleanValue;
                                          valueSource = `data[${key}].value (matched by key)`;
                                        }
                                      } else if (typeof rawValue === 'number') {
                                        value = rawValue;
                                        valueSource = `data[${key}].value (matched by key)`;
                                      } else if (rawValue) {
                                        const parsedValue = Number(rawValue);
                                        if (!isNaN(parsedValue)) {
                                          value = parsedValue;
                                          valueSource = `data[${key}].value (converted)`;
                                        }
                                      }
                                    }
                                    
                                    if (key === condition.id && 'value' in dataObj) {
                                      const rawValue = (dataObj as any).value;
                                      
                                      if (typeof rawValue === 'string' && rawValue.includes(',')) {
                                        const cleanValue = Number(rawValue.replace(/,/g, ''));
                                        if (!isNaN(cleanValue)) {
                                          value = cleanValue;
                                          valueSource = `data[${key}].value (direct match)`;
                                        }
                                      } else if (typeof rawValue === 'number') {
                                        value = rawValue;
                                        valueSource = `data[${key}].value (direct match)`;
                                      }
                                    }
                                  }
                                } catch (err) {
                                  // Just continue if error
                                }
                              });
                            }
                            
                            console.log(`Item ${idx}:`, item._id, '- Value:', value, '- Source:', valueSource);
                            
                            return value === undefined ? total : total + value;
                          }, 0);

                          return (
                            <Box key={idx} p={4} bg="white">
                              <Flex justify="space-between" align="center">
                                <VStack align="start" spacing={0}>
                                  <Text fontWeight="medium">Sum of {condition.field}</Text>
                                  <Text fontSize="sm" color="gray.500">Based on {currReportResult.length} records</Text>
                                </VStack>
                                <Text fontSize="2xl" fontWeight="bold" color="orange.500">{sum.toLocaleString()}</Text>
                              </Flex>
                            </Box>
                          );
                        } 
                        else {
                          // For price fields, group by currency
                          const sumsByCurrency: { [currency: string]: number } = {};
                          
                          // Keep track of items for value-based currency inference
                          const items: { value: number, id: string, currency?: string }[] = [];
                          
                          // Process each item - improved logic for price fields
                          currReportResult.forEach((item, idx) => {
                            // Enhanced debugging for the first few items
                            if (idx < 5) {
                              const rawData = item.data && item.data[condition.id] ? 
                                JSON.stringify(item.data[condition.id]) : 'not found';
                              console.log(`Item ${idx} raw data for ${condition.id}:`, rawData);
                              
                              // Directly check if the raw data contains currency
                              if (rawData.includes('"currency"')) {
                                try {
                                  const parsedData = JSON.parse(rawData);
                                  if (parsedData.options && parsedData.options.currency) {
                                    console.log(`FOUND CURRENCY in raw data for item ${idx}: ${parsedData.options.currency}`);
                                  }
                                } catch (e) {
                                  console.error("Error parsing raw data", e);
                                }
                              }
                            }
                            
                            // Try to get the value and currency for this item's price field
                            if (item.data && condition.id in item.data) {
                              const fieldData = item.data[condition.id];
                              
                              // Safely extract price value
                              let amount: number | undefined;
                              let currency: string | undefined;
                              
                              // First try to extract currency directly from options
                              if (fieldData.options && typeof fieldData.options === 'object' && fieldData.options.currency) {
                                currency = fieldData.options.currency;
                                console.log(`Item ${idx}: Using currency from options: ${currency}`);
                              }
                              
                              // Then try to get value
                              if (fieldData && typeof fieldData === 'object' && 'value' in fieldData) {
                                const rawValue = fieldData.value;
                                
                                // Parse numeric value
                                if (typeof rawValue === 'string') {
                                  // Check for currency in the value string
                                  if (rawValue.startsWith('£') || rawValue.includes('GBP')) {
                                    currency = 'GBP';
                                  } else if (rawValue.startsWith('$') || rawValue.includes('USD')) {
                                    currency = 'USD';
                                  } else if (rawValue.startsWith('€') || rawValue.includes('EUR')) {
                                    currency = 'EUR';
                                  } else if (rawValue.startsWith('¥') || rawValue.includes('JPY')) {
                                    currency = 'JPY';
                                  } else if (rawValue.startsWith('A$') || rawValue.includes('AUD')) {
                                    currency = 'AUD';
                                  } else if (rawValue.startsWith('₪') || rawValue.includes('NIS')) {
                                    currency = 'NIS';
                                  } else {
                                    // Try to parse just as a number, using NIS as default
                                    console.log(`Using default NIS for value: ${rawValue}`);
                                  }
                                  
                                  // Clean and parse the number, removing any currency symbols or commas
                                  amount = Number(rawValue.replace(/[$£€¥₪A-Z,\s]/g, ''));
                                } else if (typeof rawValue === 'number') {
                                  amount = rawValue;
                                }
                              }
                              
                              // Store the amount for later currency assignment if needed
                              if (amount !== undefined && !isNaN(amount)) {
                                items.push({
                                  value: amount,
                                  id: item._id,
                                  currency
                                });
                              }
                            }
                          });
                          
                          // Infer currencies when not explicitly provided
                          const INFER_CURRENCIES = true; // Set to false to disable inference
                          
                          if (INFER_CURRENCIES) {
                            // Count how many items have explicit currency
                            const explicitCurrencyCount = items.filter(item => !!item.currency).length;
                            const totalItems = items.length;
                            
                            // Only do inference if at least one item has currency and not all have currency
                            if (explicitCurrencyCount > 0 && explicitCurrencyCount < totalItems) {
                              console.log(`Inferring currencies: ${explicitCurrencyCount}/${totalItems} items have explicit currency`);
                              
                              // Value-based heuristics - common currency amounts often have specific sizes
                              items.forEach(item => {
                                if (!item.currency) {
                                  // Default to USD for all items without currency
                                  item.currency = 'USD';
                                  console.log(`Assigned default USD currency for value ${item.value}`);
                                }
                              });
                            } else if (explicitCurrencyCount === 0) {
                              // If no items have currency, assign all to USD
                              items.forEach((item) => {
                                item.currency = 'USD';
                                console.log(`Assigned default USD currency for value ${item.value}`);
                              });
                            }
                          }
                          
                          // Now add all items to their currency groups
                          items.forEach(item => {
                            const currency = item.currency || 'NIS'; // Default to NIS instead of USD
                            const amount = item.value;
                            
                            if (sumsByCurrency[currency]) {
                              sumsByCurrency[currency] += amount;
                            } else {
                              sumsByCurrency[currency] = amount;
                            }
                          });
                          
                          // Log the summed results for debugging
                          console.log(`Currency sums for ${condition.field}:`, sumsByCurrency);
                          console.log(`Found ${Object.keys(sumsByCurrency).length} different currencies for ${condition.field}`);
                          
                          return (
                            <Box key={idx} p={4} bg="white">
                              <VStack align="start" spacing={3} width="100%">
                                <Text fontWeight="medium">Sum of {condition.field}</Text>
                                <Text fontSize="sm" color="gray.500">Based on {currReportResult.length} records</Text>
                                
                                {Object.entries(sumsByCurrency).length > 0 ? (
                                  Object.entries(sumsByCurrency).map(([currency, sum], currencyIdx) => {
                                    // Get proper formatting for each currency
                                    const formatCurrency = (currency: string, value: number) => {
                                      const currencySymbols: { [code: string]: string } = {
                                        'USD': '$',
                                        'EUR': '€',
                                        'GBP': '£',
                                        'JPY': '¥',
                                        'CAD': 'C$',
                                        'AUD': 'A$',
                                        'NIS': '₪'
                                      };
                                      
                                      const symbol = currencySymbols[currency] || currency;
                                      
                                      // Format based on whether it's a symbol or code
                                      if (currencySymbols[currency]) {
                                        return `${symbol}${value.toLocaleString()}`;
                                      } else {
                                        return `${value.toLocaleString()} ${symbol}`;
                                      }
                                    };
                                    
                                    return (
                                      <Flex 
                                        key={currencyIdx} 
                                        justify="space-between" 
                                        width="100%" 
                                        p={3} 
                                        bg={currencyIdx % 2 === 0 ? "orange.50" : "white"} 
                                        borderRadius="md"
                                        borderWidth="1px"
                                        borderColor="orange.100"
                                        mb={1}
                                      >
                                        <Text fontWeight="semibold">{currency}:</Text>
                                        <Text fontSize="xl" fontWeight="bold" color="orange.500">
                                          {formatCurrency(currency, sum)}
                                        </Text>
                                      </Flex>
                                    );
                                  })
                                ) : (
                                  <Text color="gray.500">No data available for this currency</Text>
                                )}
                              </VStack>
                            </Box>
                          );
                        }
                      })}
                    </VStack>
                  </Box>
                  <Button 
                    rightIcon={showDetailedView ? <ChevronUpIcon /> : <ChevronDownIcon />} 
                    variant="outline" 
                    size="sm" 
                    onClick={() => setShowDetailedView(!showDetailedView)}
                  >
                    {showDetailedView ? "Hide Detailed Records" : "Show Detailed Records"}
                  </Button>
                  
                  <Collapse in={showDetailedView} animateOpacity>
                    <Box mt={2} borderWidth="1px" borderRadius="lg" p={2}>
                      <ZoneListPage
                        type='report'
                        reportZoneId={currReportZoneId}
                        reportResult={currReportResult}
                        noPadding={true}
                      />
                    </Box>
                  </Collapse>
                </VStack>
              ) : (
                <ZoneListPage
                  type='report'
                  reportZoneId={currReportZoneId}
                  reportResult={currReportResult}
                  noPadding={true}
                />
              )}
            </>
          ) : (
            <Text color={textColor}>
              No report results to display. Create a new report or select an existing one and click "Run Report" to see the results here.
            </Text>
          )}
        </Box>
      </Card>
    );
  }, [textColor, currReportZoneId, currReportResult, reports, showDetailedView, currReportId]);

  const duplicateReport = async (report: Report) => {
    setIsLoading(true);
    try {
      const duplicatedReport = await reportService.duplicateReport(report._id);
      setReports(prevReports => {
        const newReports = [...prevReports];
        const index = newReports.findIndex(r => r._id === report._id);
        if (index !== -1) {
          newReports.splice(index + 1, 0, duplicatedReport);
          // Update the order of all reports
          newReports.forEach((r, i) => {
            r.order = i + 1;
          });
          // Update the order on the server
          reportService.updateReportsOrder(newReports.map(r => ({ _id: r._id, order: r.order })));
        } else {
          newReports.push(duplicatedReport);
        }
        return newReports;
      });
      toast({
        title: "Report duplicated",
        description: "The report has been successfully duplicated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error duplicating report:", error);
      toast({
        title: "Error",
        description: "Failed to duplicate the report. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteReport = async (reportId: string) => {
    setIsLoading(true);
    try {
      await reportService.deleteReport(reportId);
      setReports(prevReports => prevReports.filter(r => r._id !== reportId));
      toast({
        title: "Report deleted",
        description: "The report has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      toast({
        title: "Error",
        description: "Failed to delete the report. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //const { user: currentUser } = useAuth();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser && 'companyId' in currentUser) {
      fetchReports();
    }
  }, [currentUser]);

  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/info`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setCurrentUser({ companyId: response.data._id });
      }
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const fetchReports = async () => {
   // console.log('currentUser2:', currentUser);
    if (currentUser && 'companyId' in currentUser) {
      const fetchedReports = await reportService.getAllReports(currentUser.companyId,userRole,userEmail);
      // const filteredReports = fetchedReports.result.filter(report => report.companyId === currentUser.companyId);
      setReports(fetchedReports.result);
    }
  };

  const runReport = async (report: any, reportType: 'preview' | 'render') => {
    // Check if this report has any sum conditions (price or number)
    const hasSumCondition = report.conditions.some(
      (c: Condition) => c.condition === "sum"
    );
    
    // For any report with sum conditions, use direct zone service instead of report API
    if (hasSumCondition) {
      try {
        console.log('Sum condition detected, using direct zone service');
        
        // Store report IDs for UI
        setCurrReportId(report._id);
        setCurrReportZoneId(report.zoneId);
        
        // Use zoneService to get all items
        const zoneItems = await zoneService.getZoneItems(report.zoneId);
        console.log(`Retrieved ${zoneItems.length} items directly from zone`);
        
        // Process each item to get full data
        const processedItems = await Promise.all(
          zoneItems.map(async (item: any) => {
            try {
              const fullItem = await zoneService.getZoneItemById(report.zoneId, item._id, false);
              return fullItem;
            } catch (err) {
              console.error('Error fetching item:', err);
              return null;
            }
          })
        );
        
        // Filter out failed items
        const validItems = processedItems.filter(item => item !== null);
        
        // Set the result
        setCurrReportResult(validItems);
        
        if (reportType === "preview") {
          fetchReports();
        }
        return;
      } catch (error) {
        console.error('Error fetching zone data:', error);
        toast({
          title: "Error running report",
          description: "Could not fetch zone data for this report.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
    
    // For all other reports, use the regular report API
    const fetchQuery = {
      type: (report.zoneType || report.type),
      zoneId: report.zoneId,
      conditions: report.conditions,
      zoneName: (report.zone || report.zoneName),
      zone: (report.zone || report.zoneName),
      conditionType: reportType === 'render' ? "show" : "save",
      companyId
    };

    try {
      console.log('Sending regular report query:', JSON.stringify(fetchQuery, null, 2));
      const res = await reportService.queryReport(fetchQuery);
      
      if(res && res?.data){
        console.log(`Received ${res.data.length} items from report API`);
        setCurrReportResult(res.data);
        setCurrReportZoneId(report.zoneId);
        setCurrReportId(report._id);

        if(reportType === "preview"){
          fetchReports();
        }
      }
    } catch (error: any) {
      console.error('Error running report:', error);
      if (error && error.response && error.response.data) {
        console.error('Server error details:', error.response.data);
      }
      
      toast({
        title: "Error running report",
        description: "There was a server error. Please try a different report configuration.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} height="calc(100vh - 44px)" display="flex" gap={4}>
      {/* Left Column */}
      <Box 
        width={isExpanded ? "0px" : "400px"} 
        transition="width 0.3s"
        borderRight="0px solid"
        borderColor={useColorModeValue('gray.200', 'whiteAlpha.100')}
        overflow="hidden"
        borderRadius="20px"
      >
        <Tabs height="100%">
          <TabList>
            <Tab>Pre-saved reports</Tab>
            {/* AI Assistance tab temporarily disabled
            <Tab>Assistance</Tab>
            */}
          </TabList>

          <TabPanels height="calc(100% - 40px)" overflow="hidden">
            <TabPanel height="100%" p={0}>
              <Card height="100%" borderRadius={0} p={1}>
                <VStack align="stretch" spacing={1} height="100%">
                  <HStack justify="space-between" p={4}>
                    <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                      Saved reports
                    </Text>
                    <Button
                      leftIcon={<AddIcon />}
                      colorScheme="brand"
                      fontSize="12px"
                      size="sm"
                      onClick={() => setIsWizardOpen(true)}
                    >
                      CREATE NEW REPORT
                    </Button>
                  </HStack>
                  <Box overflowY="auto" flex="1" px={1}>
                    {reports.length === 0 ? (
                      <Text color={textColor}>
                        You currently have no reports. Add a new report using the "CREATE NEW REPORT" button above.
                      </Text>
                    ) : (
                      <VStack align="stretch" spacing={4}>
                        {reports.map((report, index) => (
                          <Card key={index} p={2} borderWidth={1} borderRadius="md" boxShadow="none">
                            <Flex justify="space-between" align="flex-start">
                              <VStack align="stretch" spacing={1}>
                                <Text fontWeight="bold">{report.zone || report.zoneName}</Text>
                                {report.conditions && Array.isArray(report.conditions) && report.conditions.map((condition: Condition, condIndex: number) => (
                                  <Text key={condIndex} fontSize="sm">
                                    {condition.field} - {condition.condition} - {condition.value} {/* {condition.op || "And"} */}
                                  </Text>
                                ))}
                              </VStack>
                              <HStack mt="8px">
                                <Button
                                  colorScheme="brand"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => runReport(report, 'render')}
                                >
                                  Run Report
                                </Button>
                                <Menu>
                                  <MenuButton
                                    as={IconButton}
                                    icon={<MenuDots boxSize={6} />}
                                    variant="ghost"
                                    size="sm"
                                  />
                                  <MenuList>
                                    <MenuItem onClick={() => setEditingReport(report)}>Edit</MenuItem>
                                    <MenuItem onClick={() => duplicateReport(report)} isDisabled={isLoading}>
                                      {isLoading ? "Duplicating..." : "Duplicate"}
                                    </MenuItem>
                                    <MenuItem onClick={() => deleteReport(report._id)} isDisabled={isLoading}>
                                      {isLoading ? "Deleting..." : "Delete"}
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </HStack>
                            </Flex>
                          </Card>
                        ))}
                      </VStack>
                    )}
                  </Box>
                </VStack>
              </Card>
            </TabPanel>

            {/* AI Assistance tab temporarily disabled
            <TabPanel height="100%" p={0}>
              <Card height="100%" borderRadius={0}>
                <Flex alignItems="center" mb={2} p={4}>
                  <DibiAiIcon width="95px" height="30px" style={{ marginRight: '15px' }} />
                  <Text fontSize="2xl" fontWeight="bold" color={textColor} mt="14px">
                    assistance
                  </Text>
                </Flex>
                <Box height="calc(100% - 60px)" px={4}>
                  <AiChat companyId={companyId} />
                </Box>
              </Card>
            </TabPanel>
            */}
          </TabPanels>
        </Tabs>
      </Box>

      {/* Right Column */}
      <Box 
        flex="1" 
        display="flex" 
        flexDirection="column" 
        height="100%"
        overflow="hidden"
        pr={4}  // Add right padding to avoid content touching the window edge
      >
        <Card height="100%" borderRadius="lg">
          <Flex alignItems="center" p="0 0 15px 0" borderBottomWidth="1px">
            <IconButton
              aria-label={isExpanded ? "Expand" : "Minimize"}
              icon={isExpanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              onClick={() => setIsExpanded(!isExpanded)}
              mr={2}
              size="sm"
              colorScheme="orange"
              variant="outline"
            />
            <Text fontSize="2xl" fontWeight="bold" color={textColor}>
              Report Results
            </Text>   
          </Flex>
          
          <Box height="calc(100% - 73px)" overflow="hidden">
            {RightColumnContent}
          </Box>
        </Card>
      </Box>

      {(isWizardOpen || editingReport) && (
        <ReportWizard
          isOpen={isWizardOpen || !!editingReport}
          onClose={() => {
            setIsWizardOpen(false);
            setEditingReport(null);
          }}
          onShow={async (newReport) => {
            runReport(newReport, "render")
          }}
          onSave={async (newReport) => {
            if (currentUser && 'companyId' in currentUser) {
              newReport.companyId = currentUser.companyId;
              newReport.zoneType = newReport.type;
              newReport.zone = newReport.zoneName;
              newReport.fields = newReport.conditions.map((cond: { field: string }) => cond.field);
              
              let updatedOrCreatedReport: Report | null = null;
              try {
                // First save/update the report
                if (editingReport) {
                  updatedOrCreatedReport = await reportService.updateReport(editingReport._id, newReport);
                  setReports(prevReports => prevReports.map(r => 
                    r._id === updatedOrCreatedReport._id ? updatedOrCreatedReport : r
                  ));
                } else {
                  updatedOrCreatedReport = await reportService.createReport(newReport);
                  setReports(prevReports => [...prevReports, updatedOrCreatedReport]);
                }

                // Only after successful save/update, run the report
                if (updatedOrCreatedReport) {
                  // Set the current report ID explicitly
                  setCurrReportId(updatedOrCreatedReport._id);
                  await runReport(updatedOrCreatedReport, 'render');
                }

                setEditingReport(null);
                setIsWizardOpen(false);
                
                toast({
                  title: "Report saved",
                  description: "Your report has been successfully saved.",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              } catch (error: unknown) {
                console.error('Error saving report:', error);
                if (error instanceof Error && 'response' in error) {
                  const axiosError = error as { response?: { data: unknown } };
                  console.error('Server error response:', axiosError.response?.data);
                }
                toast({
                  title: "Error saving report",
                  description: "There was an error saving your report. Please try again.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            } else {
              console.error('Current user or companyId not available');
              toast({
                title: "Error",
                description: "Unable to save report. User information is missing.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          }}
          initialReport={editingReport}
          editingReport={editingReport}
        />
      )}
    </Box>
  );
};

export default React.memo(ReportsPage);
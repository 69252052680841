/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


import { useState, useEffect, useCallback } from 'react';
import { Flex, Grid, Text, Button, IconButton, Spinner, Menu, MenuButton, MenuList, MenuItem, useToast } from '@chakra-ui/react';
import {  RepeatIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons';
import Card from 'components/card/Card';
import { DashboardPanel } from '../../../../types/dashboard';
import BarChart from './components/BarChart';
import PieChart from './components/PieChart';
import NumberPanel from './components/NumberPanel';
import DashboardPanelWizard from './components/DashboardPanelWizard';
import { dashboardService } from '../../../../services/dashboardService';
import axios from 'axios';
import CompanyInfoCard from './components/CompanyInfoCard';

export default function Default() {
  const toast = useToast();
  const [currentUser, setCurrentUser] = useState<{ companyId: string } | null>(null);
  const [dashboardPanels, setDashboardPanels] = useState<DashboardPanel[]>([]);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const [panelToEdit, setPanelToEdit] = useState<DashboardPanel | null>(null);

  interface PanelState {
    isLoading: boolean;
    error: string | null;
  }

  const [panelStates, setPanelStates] = useState<Record<string, PanelState>>({});

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    //console.log('currentUser:', currentUser);
    if (currentUser) {
      fetchDashboardPanels();
      fetchCompanyData();
    }
  }, [currentUser]);

  useEffect(() => {
    //console.log('Dashboard panels updated:', dashboardPanels);
  }, [dashboardPanels]);

  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/info`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setCurrentUser({ companyId: response.data._id });
      }
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const fetchDashboardPanels = async () => {
    try {
      const panels = await dashboardService.getAllDashboardPanels();
      if (currentUser) {
        const filteredPanels = panels.filter(panel => panel.companyId === currentUser.companyId);
        setDashboardPanels(filteredPanels);
      }
    } catch (error) {
      console.error('Error fetching dashboard panels:', error);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/info`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setCompanyData(response.data);
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

  const handleAddPanel = () => {
    setPanelToEdit(null);
    setIsWizardOpen(true);
  };

  const handleSavePanel = async (panelData: DashboardPanel) => {
    try {
      let savedPanel: DashboardPanel;
      if (panelData._id) {
        savedPanel = await dashboardService.updateDashboardPanel(panelData._id, panelData);
      } else {
        savedPanel = await dashboardService.createDashboardPanel(panelData);
      }

      setDashboardPanels(prev => {
        const newPanels = panelData._id 
          ? prev.map(p => p._id === panelData._id ? savedPanel : p)
          : [...prev, savedPanel];
        //console.log('Updated panels:', newPanels);
        return newPanels;
      });
      
      setIsWizardOpen(false);
      setPanelToEdit(null);
      
      toast({
        title: 'Success',
        description: panelData._id ? 'Panel updated successfully' : 'Panel created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving panel:', error);
      toast({
        title: 'Error',
        description: 'Failed to save the panel. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const refreshPanel = useCallback(async (panelId: string) => {
    setPanelStates(prev => ({
      ...prev,
      [panelId]: { isLoading: true, error: null }
    }));
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setPanelStates(prev => ({
        ...prev,
        [panelId]: { ...prev[panelId], isLoading: false }
      }));
    } catch (err) {
      setPanelStates(prev => ({
        ...prev,
        [panelId]: { ...prev[panelId], isLoading: false, error: 'Failed to refresh panel data' }
      }));
    }
  }, []);

  const handleEditPanel = (panel: DashboardPanel) => {
    setIsWizardOpen(true);
    setPanelToEdit(panel);
  };

  const handleDeletePanel = async (panelId: string) => {
    try {
      await dashboardService.deleteDashboardPanel(panelId);
      setDashboardPanels(panels => panels.filter(p => p._id !== panelId));
      setPanelStates(prev => {
        const newState = { ...prev };
        delete newState[panelId];
        return newState;
      });
      toast({
        title: 'Success',
        description: 'Panel deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting panel:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete the panel. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderPanelContent = useCallback((panel: DashboardPanel) => {
    const panelState = panelStates[panel._id] || { isLoading: false, error: null };

    if (panelState.isLoading) {
      return <Spinner />;
    }

    if (panelState.error) {
      return <Text color="red.500">{panelState.error}</Text>;
    }

    switch (panel.type) {
      case 'bar':
        return <BarChart 
          zoneId={panel.zoneId} 
          xAxis={panel.xAxis!} 
          yAxis={panel.yAxis!}
          xAxisName={panel.xAxisName}
          yAxisName={panel.yAxisName}
        />;
      case 'pie':
        return <PieChart zoneId={panel.zoneId} field={panel.field!} />;
      case 'number':
        return <NumberPanel zoneId={panel.zoneId} field={panel.field!} />;
      default:
        return null;
    }
  }, [panelStates, currentUser]);

  if (!currentUser || !companyData) {
    return <Spinner />;
  }

  //console.log('Current user:', currentUser);

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      h="100vh"
      overflow="hidden"
    >
      <Flex 
        direction="column" 
        width="stretch"
        overflow="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.300',
            borderRadius: '24px',
          },
        }}
      >
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
          gap="20px"
          alignItems="stretch"
        >
          <CompanyInfoCard companyData={companyData} height="100%" />
          {dashboardPanels.length === 0 ? (
            <Card mb="20px" p={6} height="100%">
              <Flex direction="column" alignItems="center">
                <Text fontSize="xl" mb={4}>
                  Welcome to your dashboard! Here you can add Charts and calculations of the zones items of your company.
                </Text>
                <Button colorScheme="brand" onClick={handleAddPanel}>
                  Add Panel
                </Button>
              </Flex>
            </Card>
          ) : (
            dashboardPanels.map((panel: DashboardPanel) => (
              <Card key={panel._id} height="100%">
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="lg" fontWeight="bold">{panel.title}</Text>
                  <Flex>
                    <IconButton
                      aria-label="Refresh panel"
                      icon={<RepeatIcon />}
                      size="sm"
                      mr={2}
                      onClick={() => refreshPanel(panel._id)}
                    />
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<MenuDots boxSize={6} />}
                        variant='outline'
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem onClick={() => handleEditPanel(panel)}>Edit</MenuItem>
                        <MenuItem onClick={() => handleDeletePanel(panel._id!)}>Delete</MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
                {renderPanelContent(panel)}
              </Card>
            ))
          )}
        </Grid>
        <Flex direction="column" alignItems="flex-start" pb="40px">
          <Button colorScheme="brand" size="sm" onClick={handleAddPanel} mb="20px">
            Add Panel
          </Button>
        </Flex>
      </Flex>
      <DashboardPanelWizard
        isOpen={isWizardOpen}
        onClose={() => {
          setIsWizardOpen(false);
          setPanelToEdit(null);
        }}
        onSave={handleSavePanel}
        user={currentUser}
        editPanel={panelToEdit}
      />
    </Flex>
  );
}

import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Flex, FormControl, SimpleGrid, Text, useColorModeValue, Button, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useAxiosWithAuth } from '../../../../../../hooks/useAxiosWithAuth';

interface UserProfile {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface InformationProps {
  userProfile: UserProfile | null;
}

export default function Information({ userProfile }: InformationProps) {
  const [profile, setProfile] = useState<UserProfile>({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
  });
  const toast = useToast();
  const axiosInstance = useAxiosWithAuth();

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, [e.target.id]: e.target.value });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axiosInstance.put('/api/users/update-profile', profile);
      setProfile(response.data);
      toast({
        title: 'Profile updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: 'Error updating profile',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!userProfile) {
    return <Card>Loading...</Card>;
  }

  return (
    <FormControl>
      <Card>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Account Settings
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can change user account information
          </Text>
        </Flex>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
          <InputField
            mb='25px'
            me='30px'
            id='firstName'
            label='First Name'
            value={profile.firstName}
            onChange={handleInputChange}
          />
          <InputField
            mb='25px'
            id='lastName'
            label='Last Name'
            value={profile.lastName}
            onChange={handleInputChange}
          />
           <InputField
            mb='25px'
            id='email'
            label='Email Address'
            value={profile.email}
            onChange={handleInputChange}
          />
        </SimpleGrid>
        <Button
          variant='brand'
          minW='183px'
          fontSize='sm'
          fontWeight='500'
          ms='auto'
          onClick={handleSaveChanges}
        >
          Save changes
        </Button>
      </Card>
    </FormControl>
  );
}

import { Input } from "@chakra-ui/react";
import { Box } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import React from "react";

interface TableHeaderRendererProps {
  params: GridColumnHeaderParams;
  editingColumn: string | null;
  handleRenameColumn: (oldField: string, newHeader: string) => void;
}
const TableHeaderRenderer: React.FC<TableHeaderRendererProps> = ({
  params,
  editingColumn,
  handleRenameColumn,
}) => {
  const handleRename = (oldField: string, newValue: string) => {
    // Only rename if the value actually changed
    if (oldField !== newValue && newValue.trim() !== "") {
      handleRenameColumn(oldField, newValue);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
    >
      {editingColumn === params.colDef?.field ? (
        <Input
          defaultValue={params.colDef.headerName}
          autoFocus
          size="small"
          onBlur={(e) => handleRename(params.colDef.field, e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleRename(params.colDef.field, e.currentTarget.value);
            }
          }}
          sx={{
            fontSize: "0.875rem",
            padding: "2px 4px",
            width: "100px",
            "&:focus-visible": {
              outline: "0px solid #FE7053",
              backgroundColor: "#f3f3f3",
              outlineOffset: "-1px",
              boxShadow: "none",
            },
          }}
        />
      ) : (
        <React.Fragment>
          <span>{params.colDef?.headerName}</span>
        </React.Fragment>
      )}
    </Box>
  );
};

export default TableHeaderRenderer;

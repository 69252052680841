import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Input,
  Text,
  useColorModeValue,
  useToast,
  UnorderedList,
  ListItem,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import BackgroundLogin from 'assets/img/auth/BackgroundLogin.png';
import axios from 'axios';
import { DibiLogo } from 'components/icons/Icons';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { BsCheck2, BsX } from 'react-icons/bs';

const AcceptInvitation = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const toast = useToast();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = React.useState(false);

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';

  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });

  const checkPasswordRequirements = (pass: string) => {
    setPasswordRequirements({
      length: pass.length >= 8,
      uppercase: /[A-Z]/.test(pass),
      lowercase: /[a-z]/.test(pass),
      number: /[0-9]/.test(pass),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(pass)
    });
  };

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];
    
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter');
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must contain at least one number');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Password must contain at least one special character');
    }
    
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate all required fields
    const validationErrors = [];
    
    if (!firstName.trim()) {
      validationErrors.push('First name is required');
    }
    
    if (!lastName.trim()) {
      validationErrors.push('Last name is required');
    }
    
    if (!password.trim()) {
      validationErrors.push('Password is required');
    } else {
      const passwordErrors = validatePassword(password);
      validationErrors.push(...passwordErrors);
    }

    // If there are validation errors, show them and return
    if (validationErrors.length > 0) {
      validationErrors.forEach(error => {
        toast({
          title: 'Validation Error',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/accept-invitation`, {
        token,
        firstName,
        lastName,
        password,
      });
      toast({
        title: 'Account created.',
        description: "We've created your account for you.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/auth/sign-in/default');
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: 'Unable to create your account. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClick = () => setShow(!show);

  const brandStars = useColorModeValue('red.500', 'red.500');

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: '70vh', md: '50vh' }}
        w={{ md: 'calc(100vw - 50px)' }}
        borderRadius={{ md: '15px' }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={BackgroundLogin}
        bgSize="cover"
        mx={{ md: 'auto' }}
        mt={{ md: '14px' }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="30px"
        mb="10px"
      >
         <Stack
        direction="row"
        spacing="2px"
        alignItems="center"
        justify="center"
        mb="50px"
      >
        <Box h="26px" w="175px" >
          <DibiLogo />
        </Box>
      </Stack>
        <Heading color={textColor} fontSize="32px" mb="10px">
          Accept Invitation
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColor}
          fontWeight="normal"
          fontSize="14px"
        >
          Enter your details to create your account
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: '100px' }}
          bg={useColorModeValue('white', 'navy.800')}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              First Name <Text color={brandStars} as="span">*</Text>
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="text"
              placeholder="Your first name"
              mb="24px"
              size="lg"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Last Name <Text color={brandStars} as="span">*</Text>
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="text"
              placeholder="Your last name"
              mb="24px"
              size="lg"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password <Text color={brandStars} as="span">*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type={show ? 'text' : 'password'}
                placeholder="Your password"
                mb="5px"
                size="lg"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  checkPasswordRequirements(e.target.value);
                }}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Text fontSize="xs" color="gray.700" mb="4" ml="2">
              Password must contain at least:
              <UnorderedList m={0}>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.length ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.length ? BsCheck2 : BsX} 
                    color={passwordRequirements.length ? 'green.500' : 'gray.700'} 
                  />
                  8 characters
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.uppercase ? BsCheck2 : BsX} 
                    color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'} 
                  />
                  One uppercase letter
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.lowercase ? BsCheck2 : BsX} 
                    color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'} 
                  />
                  One lowercase letter
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.number ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.number ? BsCheck2 : BsX} 
                    color={passwordRequirements.number ? 'green.500' : 'gray.700'} 
                  />
                  One number
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.special ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.special ? BsCheck2 : BsX} 
                    color={passwordRequirements.special ? 'green.500' : 'gray.700'} 
                  />
                  One special character
                </ListItem>
              </UnorderedList>
            </Text>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={handleSubmit}
            >
              Create Account
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AcceptInvitation;
import React from 'react';
import {
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  Flex,
} from '@chakra-ui/react';

interface PriceFieldProps {
  field: {
    id: string;
    label: string;
    options?: any;
  };
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
}

const currencies = [
  { value: 'USD', symbol: '$' },
  { value: 'EUR', symbol: '€' },
  { value: 'GBP', symbol: '£' },
  { value: 'JPY', symbol: '¥' },
  { value: 'AUD', symbol: 'A$' },
  { value: 'CAD', symbol: 'C$' },
  { value: 'NIS', symbol: '₪' },
];

const PriceField: React.FC<PriceFieldProps> = ({
  field,
  value,
  onChange,
  isDisabled = false,
  isInvalid = false,
}) => {
  const currentCurrency = field.options?.currency || 'USD';
  const currencySymbol = currencies.find(c => c.value === currentCurrency)?.symbol || '$';

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = e.target.value;
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    // Format the number with commas
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    // Update the field options with new currency - modified to include both value and currency
    onChange({
      target: {
        name: field.id,
        value: formattedValue,
        // Pass the currency as an attribute - this will be picked up by handleTabFields
        currency: newCurrency
      },
    } as any);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove all non-numeric characters except decimal point
    const numericValue = e.target.value.replace(/[^0-9.]/g, '');
    
    // Format the number with commas
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    onChange({
      target: {
        name: field.id,
        value: formattedValue,
        // Include the current currency to ensure it persists with value changes
        currency: currentCurrency
      },
    } as any);
  };

  return (
    <FormControl isInvalid={isInvalid}>
      <Flex direction="column" gap={2}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            {currencySymbol}
          </InputLeftElement>
          <Input
            type="text"
            value={value}
            onChange={handleValueChange}
            disabled={isDisabled}
            placeholder="0.00"
            name={field.id}
            borderColor={isInvalid ? 'red.500' : undefined}
          />
          <InputRightElement width="100px">
            <Select
              value={currentCurrency}
              onChange={handleCurrencyChange}
              disabled={isDisabled}
              size="sm"
              variant="unstyled"
              width="100%"
            >
              {currencies.map((currency) => (
                <option key={currency.value} value={currency.value}>
                  {currency.value}
                </option>
              ))}
            </Select>
          </InputRightElement>
        </InputGroup>
      </Flex>
    </FormControl>
  );
};

export default PriceField; 
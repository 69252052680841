import React from "react";
import { Box, Chip, Typography } from "@mui/material";

interface MultiSelectCellProps {
  value: string | string[];
  options?: string[];
}

const MultiSelectCell: React.FC<MultiSelectCellProps> = React.memo(
  ({ value, options }) => {
    // Handle empty or undefined values
    if (!value) {
      return <Typography variant="body2">-</Typography>;
    }

    // Convert to array if it's a string
    const values = Array.isArray(value) ? value : [value];

    // Don't display anything if the array is empty
    if (values.length === 0) {
      return <Typography variant="body2">-</Typography>;
    }

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {values.map((val, index) => (
          <Chip
            key={`${val}-${index}`}
            label={val}
            size="small"
            sx={{ 
              height: "20px",
              fontSize: "12px",
              "& .MuiChip-label": {
                padding: "0 6px"
              }
            }}
          />
        ))}
      </Box>
    );
  }
);

export default MultiSelectCell; 
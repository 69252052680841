import React, { useState, useEffect } from 'react';
import {
  Select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Text,
  useDisclosure,
  VStack,
  Box,
} from '@chakra-ui/react';
import { zoneService } from 'services/zoneService';
import { Field } from '../types/Field';
import { useAuth } from 'contexts/AuthContext';

interface RelationsFieldProps {
  field: Field;
  updateFieldOptions: (side: 'left' | 'right', fieldId: string, newOptions: any) => void;
  side: 'left' | 'right';
  isEditable: boolean;
  itemData: any;
  handleTabFields: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isConfigOpen: boolean;
  onConfigClose: () => void;
}

interface RelationsFieldOptions {
  zoneId: string;
  options: string[];
  value?: string;
}

const RelationsField: React.FC<RelationsFieldProps> = ({ field, updateFieldOptions, side, isEditable, itemData, handleTabFields, isConfigOpen, onConfigClose }) => {
  const [zones, setZones] = useState<{ _id: string, name: string }[]>([]);
  const [selectedZone, setSelectedZone] = useState<string>((field.options as RelationsFieldOptions)?.zoneId || '');
  const [options, setOptions] = useState<string[]>((field.options as RelationsFieldOptions)?.options || []);
  const { isOpen, onClose } = useDisclosure({ isOpen: isConfigOpen, onClose: onConfigClose });
  const companyId = useAuth().user.companyId;
  const userType = useAuth().user.role;
  const userEmail = useAuth().user.email;
  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchZoneItems(selectedZone);
    }
  }, [selectedZone]);

  const fetchZones = async () => {
    try {
  
      const allZones = await zoneService.getAllZones(companyId , userType , userEmail);
      const tableZones = allZones.filter((zone: { type: string }) => zone.type === 'table');
      setZones(tableZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchZoneItems = async (zoneId: string) => {
    try {
      const zoneData = await zoneService.getZoneById(zoneId);
   
      if (zoneData && zoneData.data && Object.keys(zoneData.data).length > 0) {
        
         const firstColumnKey = Object.keys(zoneData.data.data[0])[0];
   
        const firstColumnOptions = zoneData.data.data.map((item: any) => item[firstColumnKey]);
        setOptions(firstColumnOptions);
        updateFieldOptions(side, field.id, { 
          zoneId, 
          options: firstColumnOptions,
          value: (field.options as RelationsFieldOptions)?.value || ''
        });
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error('Error fetching zone items:', error);
      setOptions([]);
    }
  };

  const handleZoneSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const zoneId = e.target.value;
    setSelectedZone(zoneId);
    fetchZoneItems(zoneId);
    onClose();
  };

  return (
    <>
      <Select
        placeholder="Select an option"
        value={itemData[field?.id]?.value || ""}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleTabFields(e as unknown as React.ChangeEvent<HTMLInputElement>)}
        name={field.id}
        disabled={!isEditable}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </Select>
      {!isEditable && (
        <Popover isOpen={isOpen} onClose={onClose}>
          <PopoverContent>
            <PopoverBody>
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text mb={2} fontWeight="medium">Select a zone to relate:</Text>
                  <Text fontSize="sm" color="gray.600">
                    This will create a dropdown menu containing values from the first column of the selected zone. These values can be used to establish relationships between different zones.
                  </Text>
                </Box>
                <Select onChange={handleZoneSelect} value={selectedZone}>
                  <option value="">Select a zone</option>
                  {zones.map((zone) => (
                    <option key={zone._id} value={zone._id}>
                      {zone.name}
                    </option>
                  ))}
                </Select>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

export default RelationsField;
export const tableStyles = {
  components: {
    Table: {
      baseStyle: {
        td: {
          borderWidth: '1px',
          borderColor: '#ccc',
          borderStyle: 'solid',
          height: '39px',
          maxHeight: '39px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          transition: 'width 0.1s ease-out',
        },
        th: {
          textTransform: 'capitalize',
          fontWeight: 'normal',
          letterSpacing: '0',
          height: '39px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          transition: 'width 0.1s ease-out',
          position: 'relative',
        },
      },
    },
  },
};

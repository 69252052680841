import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Chip,
  Box,
  FormControl,
  OutlinedInput,
  useTheme,
  ListSubheader,
  Divider,
} from "@mui/material";

interface MultiSelectFieldProps {
  options: string[];
  value: string | string[];
  onChange: (value: string[]) => void;
  api: any;
  id: any;
  field: string;
  isDisabled?: boolean;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = React.memo(
  ({ options, value = [], onChange, api, id, field, isDisabled }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [selectedValues, setSelectedValues] = useState<string[]>(
      Array.isArray(value) ? value : value ? [value] : []
    );

    useEffect(() => {
      // Update selected values when the external value changes
      setSelectedValues(Array.isArray(value) ? value : value ? [value] : []);
    }, [value]);

    const handleChange = (event: any) => {
      const selectedValue = event.target.value;
      
      // Handle "All" option selection
      if (selectedValue.includes("__all__")) {
        // If "All" is being selected, select all options
        if (selectedValues.length !== options.length) {
          const newValues = [...options];
          setSelectedValues(newValues);
          onChange(newValues);
        } else {
          // If all options are already selected, deselect all
          setSelectedValues([]);
          onChange([]);
        }
      } else {
        // Normal selection handling
        setSelectedValues(selectedValue);
        onChange(selectedValue);
      }
    };

    // Check if all options are selected
    const allSelected = selectedValues.length === options.length;

    return (
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        disabled={isDisabled}
        sx={{ 
          height: "24px",
          display: 'flex !important',
          flexDirection: 'row !important',
          '& .MuiInputBase-root': {
            display: 'flex !important',
            flexDirection: 'row !important',
            height: '100%'
          }
        }}
      >
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={(event) => {
            setOpen(false);
            api.stopCellEditMode({ id, field });
          }}
          input={<OutlinedInput id={`select-multiple-chip-${field}`} />}
          renderValue={(selected) => (
            <Box sx={{ 
              display: 'flex !important',
              flexDirection: 'row !important',
              alignItems: 'center',
              gap: '4px',
              flexWrap: 'wrap',
              height: '100%',
              width: '100%'
            }}>
              {(selected as string[]).map((value) => (
                <Chip 
                  key={value} 
                  label={value} 
                  size="small"
                  sx={{
                    height: '20px',
                    '& .MuiChip-label': {
                      padding: '0 6px'
                    }
                  }}
                />
              ))}
            </Box>
          )}
          autoFocus
          sx={{
            height: '100%',
            display: 'flex !important',
            '& .MuiSelect-select': {
              padding: '0 8px !important',
              height: '100% !important',
              display: 'flex !important',
              flexDirection: 'row !important',
              alignItems: 'center'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '0px'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px'
            },
            '& .MuiSelect-icon': {
              display: 'none'
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                mt: 1,
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
              }
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            style: { marginTop: '3px' }
          }}
        >
          <MenuItem
            value="__all__"
            sx={{
              fontWeight: 'bold',
              borderBottom: '1px solid #eee'
            }}
          >
            {allSelected ? "Deselect All" : "Select All"}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default MultiSelectField; 
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import ZonePage from './ZonePage';
// import ZonePage from './ZonePageNew';
import ZonePage from "./ZonePageNew_V2";
import ZoneTabPage from "./ZoneTabPage";
import { zoneService } from "services/zoneService";

const ZoneRouter: React.FC<{
  setCurrentZoneName: (name: string) => void;
  isEditable?: boolean;
}> = ({ setCurrentZoneName, isEditable = true }) => {
  const { id: zoneId, itemId = "" } = useParams<{
    id: string;
    itemId?: string;
  }>();
  const [zoneType, setZoneType] = useState<string | null>(null);

  useEffect(() => {
    const fetchZoneType = async () => {
      if (!zoneId) {
        return;
      }
      try {
        //console.log('Fetching zone data for ID:', zoneId);
        const zoneData = await zoneService.getZoneById(zoneId);

        if (zoneData && zoneData.data) {
          if (zoneData.data.type) {
            setZoneType(zoneData.data.type);
          } else {
            console.error("Zone type is missing in the data");
            setZoneType("table"); // Default to 'table' if type is missing
          }
        } else {
          console.error("Zone data is missing or invalid");
          setZoneType("table"); // Default to 'table' if data is missing
        }
      } catch (error) {
        console.error("Error fetching zone type:", error);
        setZoneType("table"); // Default to 'table' on error
      }
    };

    fetchZoneType();
  }, [zoneId]);

  //console.log('Current zoneType:', zoneType);

  if (zoneType === null) {
    return <div>Loading...</div>;
  }

  return zoneType === "tabs" ? (
    <ZoneTabPage
      setCurrentZoneName={setCurrentZoneName}
      zoneId={zoneId}
      itemId={itemId}
      isEditable={isEditable}
    />
  ) : (
    <ZonePage
      setCurrentZoneName={setCurrentZoneName}
      isEditable={isEditable}
    />
  );
};

export default ZoneRouter;

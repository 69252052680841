import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  useColorModeValue,
  useToast,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { AddIcon, ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons'; // Import the MenuDots icon
import Card from 'components/card/Card';
import ReportWizard from './components/ReportWizard';
import { reportService } from '../../../../services/reportService';
import ZoneListPage from '../zones/ZoneListPage';
import axios from 'axios';
import { useAuth } from '../../../../contexts/AuthContext';
import AiChat from 'components/AiChat';
import { ReactComponent as DibiAiIcon } from '../../../../assets/svg/dibiai.svg';

interface Condition {
  field: string;
  condition: string;
  value: string | number;
  id: string;
  type: string;
  op: string;
  options: string[]
}

interface Report {
  _id: string;
  zone: string;
  zoneId: string;
  zoneType: string;
  conditions: Condition[];
  order: number; // Add this line
  // Add the missing properties
  body: any;
  type: string;
  url: string;
  toJSON: () => string;
  zoneName: string
}

const ReportsPage: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [currReportResult, setCurrReportResult] = useState<any[]>([]);
  const [currReportZoneId, setCurrReportZoneId] = useState<string>("");
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [editingReport, setEditingReport] = useState<Report | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentUser, setCurrentUser] = useState<{ companyId: string } | null>(null);
  const companyId = useAuth().user.companyId;
  const userEmail = useAuth().user.email;
  const userRole = useAuth().user.role;


  
  const duplicateReport = async (report: Report) => {
    setIsLoading(true);
    try {
      const duplicatedReport = await reportService.duplicateReport(report._id);
      setReports(prevReports => {
        const newReports = [...prevReports];
        const index = newReports.findIndex(r => r._id === report._id);
        if (index !== -1) {
          newReports.splice(index + 1, 0, duplicatedReport);
          // Update the order of all reports
          newReports.forEach((r, i) => {
            r.order = i + 1;
          });
          // Update the order on the server
          reportService.updateReportsOrder(newReports.map(r => ({ _id: r._id, order: r.order })));
        } else {
          newReports.push(duplicatedReport);
        }
        return newReports;
      });
      toast({
        title: "Report duplicated",
        description: "The report has been successfully duplicated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error duplicating report:", error);
      toast({
        title: "Error",
        description: "Failed to duplicate the report. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteReport = async (reportId: string) => {
    setIsLoading(true);
    try {
      await reportService.deleteReport(reportId);
      setReports(prevReports => prevReports.filter(r => r._id !== reportId));
      toast({
        title: "Report deleted",
        description: "The report has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      toast({
        title: "Error",
        description: "Failed to delete the report. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //const { user: currentUser } = useAuth();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser && 'companyId' in currentUser) {
      fetchReports();
    }
  }, [currentUser]);

  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/info`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setCurrentUser({ companyId: response.data._id });
      }
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const fetchReports = async () => {
    console.log('currentUser2:', currentUser);
    if (currentUser && 'companyId' in currentUser) {
      const fetchedReports = await reportService.getAllReports(currentUser.companyId,userRole,userEmail);
      // const filteredReports = fetchedReports.result.filter(report => report.companyId === currentUser.companyId);
      setReports(fetchedReports.result);
    }
  };

  const runReport = async (report: any, reportType: 'preview' | 'render') => {
    let reportQuery;

    if (reportType === 'render') {
      reportQuery = {
        type: (report.zoneType || report.type),
        zoneId: report.zoneId,
        conditions: report.conditions,
        zoneName: (report.zone || report.zoneName),
        zone: (report.zone || report.zoneName), // Add this line
        conditionType: "show",
        companyId
      }
    } else {
      reportQuery = {
        type: report.zoneType,
        ...report,
        zone: (report.zone || report.zoneName), // Add this line
        conditionType: "save",
        companyId
      };
    }

    try {
      const res = await reportService.queryReport(reportQuery)
      
      if(res && res?.data){
        setCurrReportResult(res.data);
        setCurrReportZoneId(report.zoneId);

        if(reportType === "preview"){
          fetchReports()
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} height="calc(100vh - 80px)" display="flex" flexDirection="column" overflow="hidden">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={4} flex={isExpanded ? "0 0 auto" : "0"} transition="flex 0.3s">
        <Card height={isExpanded ? "77px" : "400px"} transition="height 0.3s">
          <VStack align="stretch" spacing={4} height="100%">
            <HStack justify="space-between">
              <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                Pre-saved reports
              </Text>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="brand"
                fontSize="12px"
                size="sm"
                onClick={() => setIsWizardOpen(true)}
              >
                CREATE NEW REPORT
              </Button>
            </HStack>
            <Box overflowY="auto" flex="1">
              {reports.length === 0 ? (
                <Text color={textColor}>
                  You currently have no reports. Add a new report using the "CREATE NEW REPORT" button above.
                </Text>
              ) : (
                <VStack align="stretch" spacing={4}>
                  {reports.map((report, index) => (
                    <Card key={index} p={2} borderWidth={1} borderRadius="md" boxShadow="none">
                      <Flex justify="space-between" align="flex-start">
                        <VStack align="stretch" spacing={1}>
                          <Text fontWeight="bold">{report.zone || report.zoneName}</Text>
                          {report.conditions && Array.isArray(report.conditions) && report.conditions.map((condition: Condition, condIndex: number) => (
                            <Text key={condIndex} fontSize="sm">
                              {condition.field} - {condition.condition} - {condition.value} {/* {condition.op || "And"} */}
                            </Text>
                          ))}
                        </VStack>
                        <HStack mt="8px">
                          <Button
                            colorScheme="brand"
                            variant="outline"
                            size="sm"
                            onClick={() => runReport(report, 'render')}
                          >
                            Run Report
                          </Button>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<MenuDots boxSize={6} />}
                              variant="ghost"
                              size="sm"
                            />
                            <MenuList>
                              <MenuItem onClick={() => setEditingReport(report)}>Edit</MenuItem>
                              <MenuItem onClick={() => duplicateReport(report)} isDisabled={isLoading}>
                                {isLoading ? "Duplicating..." : "Duplicate"}
                              </MenuItem>
                              <MenuItem onClick={() => deleteReport(report._id)} isDisabled={isLoading}>
                                {isLoading ? "Deleting..." : "Delete"}
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </HStack>
                      </Flex>
                    </Card>
                  ))}
                </VStack>
              )}
            </Box>
          </VStack>
        </Card>
        <Card height={isExpanded ? "77px" : "400px"} transition="height 0.3s" overflow="hidden">
          <Flex alignItems="center" mb={2}>
            <DibiAiIcon width="95px" height="30px" style={{ marginRight: '15px' }} />
            <Text fontSize="2xl" fontWeight="bold" color={textColor} mt="14px">
              assistance
            </Text>
          </Flex>
          <Box height="calc(100% - 40px)">
            <AiChat companyId={companyId} />
          </Box>
        </Card>
      </SimpleGrid>
      <Box flex="1" display="flex" flexDirection="column" overflow="hidden">
        <Flex alignItems="center" mb={2}>
        <IconButton
            aria-label={isExpanded ? "Minimize" : "Expand"}
            icon={isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
            onClick={() => setIsExpanded(!isExpanded)}
            mr={2}
            size="xs"
            colorScheme="orange"
            variant="outline"
          />
          <Text fontSize="2xl" fontWeight="bold" color={textColor}>
            Report Results
          </Text>   
        </Flex>
        
        <Box flex="1" overflow="auto">
          {currReportResult.length > 0 ? (
            <ZoneListPage
              type='report'
              reportZoneId={currReportZoneId}
              reportResult={currReportResult}
              noPadding={true}
            />
          ) : (
            <Text color={textColor}>
              No report results to display. Create a new report or select an existing one and click "Run Report" to see the results here.
            </Text>
          )}
        </Box>
      </Box>
      {(isWizardOpen || editingReport) && (
        <ReportWizard
          isOpen={isWizardOpen || !!editingReport}
          onClose={() => {
            setIsWizardOpen(false);
            setEditingReport(null);
          }}
          onShow={async (newReport) => {
            runReport(newReport, "render")
          }}
          onSave={async (newReport) => {
            if (currentUser && 'companyId' in currentUser) {
              newReport.companyId = currentUser.companyId;
              newReport.zoneType = newReport.type;
              newReport.zone = newReport.zoneName;
              newReport.fields = newReport.conditions.map((cond: { field: string }) => cond.field);
              
              let updatedOrCreatedReport: Report | null = null;
              try {
                // First save/update the report
                if (editingReport) {
                  updatedOrCreatedReport = await reportService.updateReport(editingReport._id, newReport);
                  setReports(prevReports => prevReports.map(r => 
                    r._id === updatedOrCreatedReport._id ? updatedOrCreatedReport : r
                  ));
                } else {
                  updatedOrCreatedReport = await reportService.createReport(newReport);
                  setReports(prevReports => [...prevReports, updatedOrCreatedReport]);
                }

                // Only after successful save/update, run the report
                if (updatedOrCreatedReport) {
                  await runReport(updatedOrCreatedReport, 'render');
                }

                setEditingReport(null);
                setIsWizardOpen(false);
                
                toast({
                  title: "Report saved",
                  description: "Your report has been successfully saved.",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              } catch (error: unknown) {
                console.error('Error saving report:', error);
                if (error instanceof Error && 'response' in error) {
                  const axiosError = error as { response?: { data: unknown } };
                  console.error('Server error response:', axiosError.response?.data);
                }
                toast({
                  title: "Error saving report",
                  description: "There was an error saving your report. Please try again.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            } else {
              console.error('Current user or companyId not available');
              toast({
                title: "Error",
                description: "Unable to save report. User information is missing.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          }}
          initialReport={editingReport}
          editingReport={editingReport}
        />
      )}
    </Box>
  );
};

export default ReportsPage;
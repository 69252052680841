import { createTheme, styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#FE7053',
      light: '#FFE5E0',
      dark: '#E65A3F',
    },
    secondary: {
      main: '#2D3748',
      light: '#4A5568',
      dark: '#1A202C',
    },
    text: {
      primary: '#000000',
      secondary: '#4A5568',
      disabled: '#999999'
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(254, 112, 83, 0.1)',
      selected: 'rgba(254, 112, 83, 0.2)'
    },
    error: {
      main: '#E31A1A',
      light: '#FEEFEE'
    },
    success: {
      main: '#01B574',
      light: '#E6FAF5'
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030',
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiDataGridPro: {
      styleOverrides: {
        //   root: {
        //     '& .MuiDataGrid-cell': {
        //       borderRight: '1px solid rgba(224, 224, 224, 1)',
        //       '&:focus, &:focus-within': {
        //         outline: 'none',
        //       },
        //     },
        //     '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
        //       '&:focus-within': {
        //         outline: '1px solid #FE7053',
        //         outlineOffset: '-5px',
        //         boxShadow: 'none',
        //       },
        //       '&[data-field*="date"]': {
        //         padding: '0 10px',
        //         '&:focus-within': {
        //           outline: '1px solid #FE7053',
        //           outlineOffset: '0px',
        //           boxShadow: 'none',
        //           padding: '0 10px',
        //         },
        //       },
        //     },
        //     '& .MuiDataGrid-columnHeader': {
        //       borderRight: '1px solid rgba(224, 224, 224, 1)',
        //       borderTop: '1px solid rgba(224, 224, 224, 1)',
        //     },
        //     '& .MuiDataGrid-columnHeaders': {
        //       backgroundColor: '#fff',
        //       borderBottom: 'none',
        //       boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        //     },
        //   },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
        label: {
          fontSize: '0.875rem',
          color: '#000000',
          '&.Mui-disabled': {
            color: '#999999'
          }
        }
      },
      defaultProps: {
        componentsProps: {
          typography: {
            color: 'inherit'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#FE7053',
                opacity: 1,
                border: 0,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#FE7053',
          '&:hover': {
            backgroundColor: 'rgba(254, 112, 83, 0.1)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FE7053',
          '&:hover': {
            backgroundColor: 'rgba(254, 112, 83, 0.1)',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem!important',
          '& .MuiSvgIcon-root': {
            color: '#FE7053',
          },
          '& .MuiTypography-root': {
            fontSize: '0.875rem!important',
          },
          '&:hover': {
            backgroundColor: 'rgba(254, 112, 83, 0.1)',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#FE7053',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: '#FFFFFF',
          //fontSize: '12px'
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '20px',
          backgroundColor: '#F5F5F5',
          color: '#000000',
          fontSize: '0.75rem',
         
        },
        label: {
          color: '#000000'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '24px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE7053',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE7053',
          }
        },
        input: {
          padding: '0 8px !important',
          height: '24px !important',
          minHeight: '24px !important',
          color: '#000000'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.MuiFormControl-root': {
            display: 'flex !important',
            flexDirection: 'row !important',
            height: '100%'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '0px'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px'
            },
            '& .MuiSelect-icon': {
              display: 'none'
            }
          }
        },
        select: {
          padding: '0 8px',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }
      },
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              mt: 1,
              boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          style: { marginTop: '3px' }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0'
        }
      }
    },
  },
});

export const DibiDataGrid = styled(DataGridPro)(() => ({
  '& .MuiDataGrid-cell': {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    '&:focus-within': {
      outline: '1px solid #FE7053',
      outlineOffset: '-5px',
      boxShadow: 'none',
    },
    '&[data-field*="date"]': {
      padding: '0 10px',
      '&:focus-within': {
        outline: '1px solid #FE7053',
        outlineOffset: '0px',
        boxShadow: 'none',
        padding: '0 10px',
      },
    },
  },
  '& .MuiDataGrid-columnHeader': {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#fff',
    borderBottom: 'none',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
}));

import React from "react";
import { Checkbox, Flex, Text, Box } from "@chakra-ui/react";

interface TrueFalseFieldProps {
  id: string;
  name: string;
  label: string;
  value: boolean;
  onChange: (e: { target: { name: string; value: boolean } }) => void;
  isDisabled?: boolean;
  fieldLabel?: string;
}

const TrueFalseField: React.FC<TrueFalseFieldProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  isDisabled = false,
  fieldLabel,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      target: {
        name,
        value: e.target.checked,
      },
    });
  };

  return (
    <Box>
      <Checkbox
        id={id}
        name={name}
        isChecked={value === true}
        onChange={handleChange}
        isDisabled={isDisabled}
        colorScheme="orange"
        size="lg"
        iconColor="white"
      />
    </Box>
  );
};

export default TrueFalseField; 
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useToast,
  Icon,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/variants/Default';
import illustration from 'assets/img/auth/auth.png';
import { BsCheck2, BsX } from 'react-icons/bs';

function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  const textColor = useColorModeValue('navy.700', 'white');

  const handleClick = () => setShow(!show);

  const checkPasswordRequirements = (pass: string) => {
    setPasswordRequirements({
      length: pass.length >= 8,
      uppercase: /[A-Z]/.test(pass),
      lowercase: /[a-z]/.test(pass),
      number: /[0-9]/.test(pass),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(pass),
    });
  };

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];
    
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter');
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must contain at least one number');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Password must contain at least one special character');
    }
    
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate password requirements
    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      passwordErrors.forEach(error => {
        toast({
          title: 'Validation Error',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
      return;
    }

    if (password !== confirmPassword) {
      toast({
        title: 'Passwords do not match',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL;
      await axios.post(`${API_BASE_URL}/api/auth/reset-password`, { token, password });
      toast({
        title: 'Password reset successful',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/auth/sign-in/default');
    } catch (error) {
      console.error('Password reset error:', error);
      let errorMessage = 'Please try again or request a new reset link.';
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          errorMessage = 'Invalid or expired reset link.';
        } else if (error.response?.status === 404) {
          errorMessage = 'User not found.';
        }
      }
      toast({
        title: 'Password reset failed',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColor}
            fontWeight="400"
            fontSize="md"
          >
            Enter your new password below
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Text fontSize="xs" color="gray.700" mb="4" ml="2">
              Password must contain at least:
              <UnorderedList m={0}>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.length ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.length ? BsCheck2 : BsX} 
                    color={passwordRequirements.length ? 'green.500' : 'gray.700'} 
                  />
                  8 characters
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.uppercase ? BsCheck2 : BsX} 
                    color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'} 
                  />
                  One uppercase letter
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.lowercase ? BsCheck2 : BsX} 
                    color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'} 
                  />
                  One lowercase letter
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.number ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.number ? BsCheck2 : BsX} 
                    color={passwordRequirements.number ? 'green.500' : 'gray.700'} 
                  />
                  One number
                </ListItem>
                <ListItem 
                  display="flex" 
                  alignItems="center" 
                  gap="2"
                  color={passwordRequirements.special ? 'green.500' : 'gray.700'}
                >
                  <Icon 
                    as={passwordRequirements.special ? BsCheck2 : BsX} 
                    color={passwordRequirements.special ? 'green.500' : 'gray.700'} 
                  />
                  One special character
                </ListItem>
              </UnorderedList>
            </Text>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              New Password
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="password"
              placeholder="Your new password"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPasswordRequirements(e.target.value);
              }}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Confirm New Password
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="password"
              placeholder="Confirm your new password"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSubmit}
            >
              Reset Password
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
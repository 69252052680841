import { useState, useCallback } from 'react';
import { Textarea, TextProps } from '@chakra-ui/react';
import { debounce } from 'lodash';

interface IFieldsEditor extends TextProps {
  field: string;
  side?: any;
  updateFieldOptionsTab?: (side: any, fieldId: string, newOptions: string[]) => void;
  updateFieldOptionsTable?: (columnId: string, options: string[]) => void;
  type: 'tabs' | 'table';
  valId?: string;
}

const FieldsEditor: React.FC<IFieldsEditor> = ({
  type,
  field,
  valId = "",
  updateFieldOptionsTable = () => { }
}) => {
  const [value, setValue] = useState<string>(field);

  const debouncedUpdate = useCallback(
    debounce((newValue: string) => {
      const newOptions = newValue
        .split('\n')
        .map(option => option.trim())
        .filter(option => option !== '');

      if (type === "table") {
        updateFieldOptionsTable(valId, newOptions);
      }
    }, 300),
    [type, valId, updateFieldOptionsTable]
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedUpdate(newValue);
  };

  return (
    <Textarea
      minHeight="250px"
      value={value}
      onChange={handleChange}
      placeholder="Add one option per line"
      size="sm"
    />
  );
};

export default FieldsEditor;

import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Input, IconButton, Select, Flex, Button } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { format, isValid } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerFieldProps {
  field: any; // Replace 'any' with your Field type if available
  value: string | null;
  onChange: (date: string | null) => void;
  isDisabled?: boolean;
}

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  field,
  value,
  onChange,
  isDisabled = false
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value ? new Date(value) : null);
  const datePickerRef = useRef<DatePicker>(null);

  useEffect(() => {
    setSelectedDate(value ? new Date(value) : null);
  }, [value]);

  const parseDate = (dateStr: string | null): Date | null => {
    if (!dateStr) return null;
    
    try {
      // Handle dd/MM/yyyy format
      if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
        const [day, month, year] = dateStr.split('/');
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        return isValid(date) ? date : null;
      }
      
      // Handle yyyy-MM-dd format
      if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        const date = new Date(dateStr);
        return isValid(date) ? date : null;
      }
      
      return null;
    } catch (error) {
      console.error('Error parsing date:', error, 'value:', dateStr);
      return null;
    }
  };

  const onDateChange = (date: Date | null) => {
    setSelectedDate(date);
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
    onChange(formattedDate);
  };

  const handleTodayClick = () => {
    const today = new Date();
    onDateChange(today);
    datePickerRef.current?.setOpen(false);
  };

  const handleClearClick = () => {
    onDateChange(null);
    datePickerRef.current?.setOpen(false);
  };

  return (
    <DatePicker
      ref={datePickerRef}
      selected={parseDate(value)}
      onChange={(date: Date) => onChange(date ? format(date, 'yyyy-MM-dd') : null)}
      dateFormat="dd/MM/yyyy"
      customInput={<Input />}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      yearDropdownItemNumber={201}
      scrollableYearDropdown
      showPopperArrow={false}
      popperPlacement="bottom-start"
      calendarClassName="react-datepicker"
      disabled={isDisabled}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Flex justifyContent="space-between" alignItems="center" px={2} py={1}>
          <IconButton
            aria-label="Previous month"
            icon={<ChevronLeftIcon />}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            size="sm"
            variant="ghost"
          />
          <Select
            value={date.getMonth()}
            onChange={({ target: { value } }) => changeMonth(Number(value))}
            size="sm"
            width="auto"
          >
            {months.map((option, index) => (
              <option key={option} value={index}>
                {option}
              </option>
            ))}
          </Select>
          <Select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(Number(value))}
            size="sm"
            width="auto"
          >
            {Array.from({ length: 201 }, (_, i) => 1920 + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
          <IconButton
            aria-label="Next month"
            icon={<ChevronRightIcon />}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            size="sm"
            variant="ghost"
          />
        </Flex>
      )}
    >
      <div className="react-datepicker__today-button">
        <Button size="sm" onClick={handleTodayClick}>
          Today
        </Button>
        <Button size="sm" onClick={handleClearClick}>
          Clear
        </Button>
      </div>
    </DatePicker>
  );
};

export default DatePickerField;
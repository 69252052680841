import { Icon } from '@chakra-ui/react';
import {
  MdDashboard,
  MdHome,
  MdLock
} from 'react-icons/md';
import { SettingsIcon } from '@chakra-ui/icons'
import { FaChartLine } from "react-icons/fa";
// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';


// Zones Import
import Zones from 'views/admin/main/zones';
import ZoneRouter from 'views/admin/main/zones/ZoneRouter';
import ZoneListPage from 'views/admin/main/zones/ZoneListPage';
import ProtectedZones from 'views/admin/main/zones/ProtectedZones';

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';
import CompanyPage from 'views/admin/main/account/company';
import ProtectedCompaniesPage from 'views/admin/main/companies'; // Add this line

import UsersOverview from 'views/admin/main/users/overview';

import ProfileSettings from 'views/admin/main/profile/settings';


// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';

// Auth Imports
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import AcceptInvitation from 'views/auth/acceptInvitation/AcceptInvitation';
import ResetPassword from 'layouts/auth/resetPassword/ResetPassword';

// Reports Imports
import ReportsPage from 'views/admin/main/reports/ReportsPage';

import React from 'react';
import { RoutesType, FlexibleRoutesType } from 'types/routes';
export type { RoutesType, FlexibleRoutesType };

// Add this type extension
export interface ExtendedFlexibleRoutesType extends FlexibleRoutesType {
  role?: 'admin' | 'superAdmin' | string | string[];
}

export type RouteProps = {currentZoneName: string, setCurrentZoneName: (name: string) => void };

export const routes = ({ setCurrentZoneName }: RouteProps): ExtendedFlexibleRoutesType[] => [
  // --- Dashboards ---
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    icon: <Icon as={MdHome} width="24px" height="20px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Reports',
    layout: '/admin',
    path: '/reports',
    icon: <Icon as={FaChartLine} width="24px" height="20px" mt="-8px" color="inherit" />,
    component: <ReportsPage />,
  },
  {
    name: 'Zones',
    path: '/zones',
    layout: '/admin',
    icon: <Icon as={SettingsIcon} width="24px" height="20px" mt="-8px" color="inherit" />,
    component: <ProtectedZones setCurrentZoneName={setCurrentZoneName} />,
    role: ['admin', 'superAdmin'],
  },
    // Super Admin
    {
      name: 'Companies',
      layout: '/admin',
      path: '/main/companies',
      component: <ProtectedCompaniesPage />,
      icon: <Icon as={SettingsIcon} width="24px" height="20px" mt="-8px" color="inherit" />,
      role: 'superAdmin',
    },
/*   {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
     
      {
        name: 'RTL',
        layout: '/rtl',
        path: '/dashboards/rtl',
        component: <DashboardsRTLDefault />,
      },
    ],
  }, */
  // --- Zones ---
  {
    name: '',
    path: '/zones/:id/item/new',
    layout: '/admin',
    hidden: true,
    component: <ZoneRouter setCurrentZoneName={setCurrentZoneName} isEditable={false}/>,
  },
  {
    name: '',
    path: '/zones/:id/item/:itemId/edit',
    layout: '/admin',
    hidden: true,
    component: <ZoneRouter setCurrentZoneName={setCurrentZoneName} isEditable={false}/>,
  },
  {
    name: '',
    path: '/zones/:id',
    layout: '/admin',
    hidden: true,
    component: <ZoneRouter setCurrentZoneName={setCurrentZoneName} isEditable={true}/>,
  },
  {
    name: '',
    path: '/zones/:id/list',
    layout: '/admin',
    hidden: true,
    component: <ZoneListPage setCurrentZoneName={setCurrentZoneName} />,
  },
  
  // // --- Main pages ---
   {
    name: 'Main Pages',
    path: '/main',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    hidden: true,
    items: [
      {
        name: 'Account',
        path: '/main/account',
        collapse: true,
        items: [
          {
            name: 'Billing',
            layout: '/admin',
            path: '/main/account/billing',
            component: <AccountBilling />,
          },
          {
            name: 'Invoice',
            layout: '/admin',
            path: '/main/account/invoice',
            component: <AccountInvoice />,
          },
          {
            name: 'Settings',
            layout: '/admin',
            path: '/main/account/settings',
            component: <AccountSettings />,
          },
          {
            name: 'Company',
            layout: '/admin',
            path: '/main/account/company',
            component: <CompanyPage />,
          },
        ],
      },
      {
        name: 'Users',
        path: '/main/users',
        collapse: true,
        items: [
          {
            name: 'Users Overview',
            layout: '/admin',
            path: '/main/users/users-overview',
            component: <UsersOverview />,
          },
        ],
      },
      {
        name: 'Profile',
        path: '/main/profile',
        collapse: true,
        items: [
          {
            name: 'Profile Settings 4',
            layout: '/admin',
            path: '/main/profile/settings',
            component: <ProfileSettings />,
          },
        ],
      },
      {
        name: 'Others',
        path: '/main/others',
        collapse: true,
        items: [
          {
            name: 'Notifications',
            layout: '/admin',
            path: '/main/others/notifications',
            component: <OthersNotifications />,
          },
          {
            name: 'Pricing',
            layout: '/auth',
            path: '/main/others/pricing',
            component: <OthersPricing />,
          },
          {
            name: '404',
            layout: '/admin',
            path: '/main/others/404',
            component: <OthersError />,
          },
        ],
      },
    ],
  }, 
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    hidden: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in/default',
            component: <SignInDefault />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/default',
            component: <SignUpDefault />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
        ],
      },
      // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: <LockDefault />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: <ForgotPasswordDefault />,
          },
        ],
      },
      {
        name: 'Accept Invitation',
        layout: '/auth',
        path: '/accept-invitation/:token',
        component: <AcceptInvitation />,
      },
      {
        name: 'Reset Password',
        path: '/reset-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/reset-password/:token',
            component: <ResetPassword />,
          },
        ],
      },
    ],
  },
];
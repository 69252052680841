import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VStack,
  Checkbox,
  Icon,
  MenuDivider
} from '@chakra-ui/react';
import { EditIcon, CopyIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons';

interface FieldMenuProps {
    field: any; 
    side: 'left' | 'right';
    handleRename: (id: string) => void;
    handleDuplicate: (side: 'left' | 'right', field: any) => void;
    handleDeleteConfirmation: (side: 'left' | 'right', id: string) => void;
    handleEditOptions: (id: string, type: string) => void;
    setIsRelationsConfigOpen: (id: string) => void;
    setActivePopover: (popover: { id: string | null; type: string | null }) => void;
    handleSetCalculation: (fieldId: string) => void;
    handleFieldSettingChange: (
      side: 'left' | 'right', 
      id: string, 
      setting: 'appearInTable' | 'required' | 'repeater', 
      value: boolean
    ) => void;
  }
  

const FieldMenu: React.FC<FieldMenuProps> = ({
  field,
  side,
  handleRename,
  handleDuplicate,
  handleDeleteConfirmation,
  handleEditOptions,
  setIsRelationsConfigOpen,
  setActivePopover,
  handleFieldSettingChange,
}) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Options'
        icon={<MenuDots boxSize={6} />}
        variant='ghost'
        size='sm'
        _hover={{
          bg: "gray.200"
        }} 
      />
      <MenuList className='smallMenuDrop'>
        <MenuItem 
        icon={<Icon as={EditIcon} color="orange.500" />}
        onClick={() => handleRename(field.id)}
        >
        Rename</MenuItem>
        <MenuItem
        icon={<Icon as={CopyIcon} color="orange.500" />}
        onClick={() => handleDuplicate(side, field)}
        >
          Duplicate</MenuItem>
        <MenuItem  
        icon={<Icon as={DeleteIcon} color="orange.500" />}
        onClick={() => handleDeleteConfirmation(side, field.id)}
        >
          Delete</MenuItem>
        <MenuDivider />
        {(field.type === 'Select' || field.type === 'Checkbox List' || field.type === 'Label') && (
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEditOptions(field.id, field.type)}>
            Edit Options
          </MenuItem>
        )}
        {field.type === 'Relations' && (
          <MenuItem icon={<SettingsIcon />} onClick={() => {
            setIsRelationsConfigOpen(field.id);
            setActivePopover({ id: null, type: null });
          }}>
            Configure Relations
          </MenuItem>
        )}
        {field.type === 'Calculation' && (
          <MenuItem icon={<SettingsIcon />} onClick={() => {
            setActivePopover({ id: field.id, type: 'editCalculation' });
          }}>
            Set Calculation
          </MenuItem>
        )}
        <VStack align="stretch" ml={3}>
          <Checkbox
            colorScheme="orange"
            isChecked={field.appearInTable}
            onChange={(e) => handleFieldSettingChange(side, field.id, 'appearInTable', e.target.checked)}
          >
            Appear in table
          </Checkbox>
          <Checkbox
            colorScheme="orange"
            isChecked={field.required}
            onChange={(e) => handleFieldSettingChange(side, field.id, 'required', e.target.checked)}
          >
            Required
          </Checkbox>
          {field.type.toLowerCase() !== 'date' && (
            <Checkbox
              colorScheme="orange"
              isChecked={field.repeater}
              onChange={(e) => handleFieldSettingChange(side, field.id, 'repeater', e.target.checked)}
            >
              Repeater
            </Checkbox>
          )}
        </VStack>
      </MenuList>
    </Menu>
  );
};

export default FieldMenu;

import React, { useState, useEffect } from 'react';
import { useAxiosWithAuth } from '../../../../../../hooks/useAxiosWithAuth';
import { useToast, Box, Text, Avatar, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { UserProfile as ImportedUserProfile } from 'types/user';

interface UserProfile extends ImportedUserProfile {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  // Add other profile fields as needed
}

interface ProfileProps {
  userProfile: UserProfile | null;
}

interface IProfile {
  user: UserProfile
}
export default function Profile({ userProfile }: ProfileProps) {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const toast = useToast();
  const axiosInstance = useAxiosWithAuth();

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);

  const handleSaveChanges = async () => {
    try {
      const response = await axiosInstance.put('/users/profile', profile);
      setProfile(response.data);
      toast({
        title: 'Profile updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: 'Error updating profile',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!profile) {
    return <Card>Loading profile...</Card>;
  }

  return (
    <Card mb='20px'>
      <Flex align='center'>
        <Avatar size='xl' name={`${profile.firstName} ${profile.lastName}`} mr={4} />
        <Box>
          <Text fontSize='2xl' fontWeight='bold'>{`${profile.firstName} ${profile.lastName}`}</Text>
          <Text fontSize='md'>{profile.email}</Text>
        </Box>
      </Flex>
    </Card>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Button,
  VStack,
  Select,
  Input,
  HStack,
  Text,
  Box,
  useColorModeValue,
  CloseButton, useSteps, Flex, useToast
} from '@chakra-ui/react';
import { zoneService } from 'services/zoneService';
import { reportService } from 'services/reportService';
import CustomPopup from 'components/CustomPopup';
import DatePickerField from 'components/fields/DatePickerField';
import { useAuth } from 'contexts/AuthContext';

interface Report {
  zoneId: string;
  conditions: Array<{ field: string; condition: string; value: string | number, id: string, type: string, op: string, options: string[] }>;
  // ... other properties
}

interface ReportWizardProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (report: any) => void;
  onShow: (report: any) => void;
  initialReport: Report | null;
  editingReport?: { _id: string };
}

interface Zone {
  _id: string;
  name: string;
  type: string;
  availableUsers: string[];
  tabs: any[];
  columns: string[];
  columnOrder: string[];
  columnTypes: { [key: string]: string };
  columnWidths: { [key: string]: number };
  data: any[];
  selectOptions: { [key: string]: string[] };
  fields: {
    id: string; name: string, _id: string, appearInTable: boolean,
    label: string;
    options?: []
  }[];
}

const ReportWizard: React.FC<ReportWizardProps> = ({ isOpen, onClose, onSave, onShow, initialReport, editingReport }) => {
  const [step, setStep] = useState(initialReport ? 2 : 1);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState<Zone | null>(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [filters, setFilters] = useState([]);
  const [reportResults, setReportResults] = useState<any[]>([]);
  const [columns, setColumns] = useState<{ field: string; id: string, type: string, options: string[] }[]>([]);
  const [conditions, setConditions] = useState<Array<{ field: string; condition: string; value: string | number, id: string, type: string, op: string, options: string[] }>>([]);
  const [currFieldType, setCurrFieldType] = useState<string>("Number");
  const [error, setError] = useState<boolean>(false);
  const companyId = useAuth().user.companyId;
  const userType = useAuth().user.role;
  const userEmail = useAuth().user.email;
  const toast = useToast();

  const steps = [
    { title: 'Select a Zone', description: 'Choose a zone for your report' },
    { title: 'Set Conditions', description: 'Define conditions for your report' },
    { title: 'Save or Preview', description: 'Review or save your report' },
  ];


  const { activeStep, setActiveStep } = useSteps({
    index: step - 1,
    count: steps.length,
  });

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (initialReport) {
      setSelectedZone(zones.find(zone => zone._id === initialReport.zoneId) || null);
      setConditions(initialReport.conditions);
      // Set other relevant state variables based on initialReport
    }
  }, [initialReport, zones]);

  useEffect(() => {
    if (selectedZone) {
      if (selectedZone.fields) {
        const columnNames = selectedZone.fields.map((field: any) => ({ field: field.label as string, id: field.id as string, type: field.type as string, options: (field.options || []) }));
        setColumns(columnNames);
      } else {
        fetchZoneFields(selectedZone._id);
      }

      if (selectedZone.type === "table") {

        const col = selectedZone.columns.map((field: string, idx: number) => ({ field: field, id: field.split(" ").join("").toLocaleLowerCase(), type: selectedZone.columnTypes[field], options: [] }));
        setColumns(col)
      }
    }
  }, [selectedZone]);

  useEffect(() => {
  }, [columns]);

  useEffect(() => {
  }, [columns]);

  const fetchZoneFields = async (zoneId: string) => {
    try {
      const fields = await zoneService.getZoneFields(zoneId);
      setSelectedFields([]);
      if (Array.isArray(fields) && fields.length > 0) {
        const columnNames = fields.map((field: any) => ({ field: field.label as string, id: field.id as string, type: field.type as string, options: (field.options || []) }));
        setColumns(columnNames);
      } else {
        setColumns([]);
      }
    } catch (error) {
      console.error('Error fetching zone fields:', error);
      setColumns([]);
    }
  };

  const fetchZones = async () => {
    try {
      const fetchedZones = await zoneService.getAllZones(companyId , userType , userEmail);
      if (Array.isArray(fetchedZones) && fetchedZones.length > 0) {
        setZones(fetchedZones);
      } else {
        console.error('Fetched zones is not an array or is empty');
        setZones([]);
      }
    } catch (error) {
      console.error('Error fetching zones:', error);
      setZones([]);
    }
  };

  const fetchReportResults = async () => {
    try {
      if (!selectedZone?._id) {
        console.error('No zone selected');
        return [];
      }
      if (selectedFields.length === 0) {
        console.error('No fields selected');
        return [];
      }


      const results = await reportService.getReportResults(selectedZone._id, selectedFields, conditions);
      if (Array.isArray(results) && results.length === 0) {
      } else if (!Array.isArray(results)) {
        console.error('Results is not an array:', results);
        return [];
      }
      setReportResults(results);
      return results;
    } catch (error) {
      console.error('Error fetching report results:', error);
      setReportResults([]);
      return [];
    }
  };

  const handleNext = async () => {
    if (step === 1 && !selectedZone) {
      toast({
        title: "Error",
        description: "Please select a zone to continue",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (step === 2 && conditions.length === 0) {
      toast({
        title: "Error",
        description: "Please add at least one condition to continue",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (step === 2) {
      handleSave("show");
    }
    setStep(prevStep => prevStep + 1);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };


  const handleSave = async (conditionType: "show" | "save") => {
    if (!selectedZone) {
      console.error('No zone selected');
      return;
    }

    const reportQueryBody = {
      type: selectedZone.type,
      zoneId: selectedZone._id,
      conditions: conditions,
      zoneName: selectedZone.name,
      zone: selectedZone.name,
      zoneType: selectedZone.type,
      conditionType,
      fields: columns.map(col => col.field)
    }

    try {
      if (conditionType === "save") {
        if (editingReport) {
          await onSave({ ...reportQueryBody, _id: editingReport._id , conditionType: 'editing'});
        } else {
          await onSave(reportQueryBody);
        }
        onClose();
      } else {
        await onShow(reportQueryBody);
        if (step === 3) {
          onClose();
        }
      }
    } catch (error) {
      setError(true);
      console.error('Error saving report:', error);
      toast({
        title: "Error saving report",
        description: "There was an error saving your report. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFieldSelection = (field: string) => {
    setSelectedFields(prevFields =>
      prevFields.includes(field)
        ? prevFields.filter(f => f !== field)
        : [...prevFields, field]
    );
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <VStack spacing={4} align="stretch">
            <Select
              placeholder="Choose a zone"
              onChange={(e) => {
                const selectedId = e.target.value;
                const selected = zones.find(zone => zone._id === selectedId);
                if (selected) {
                  setSelectedZone(selected);
                } else {
                  console.error('Selected zone not found');
                }
              }}
              value={selectedZone?._id || ''}
            >
              {zones.map((zone) => (
                <option key={zone._id} value={zone._id}>
                  {zone.name}
                </option>
              ))}
            </Select>
          </VStack>
        );
      case 2:
        return (
          <VStack spacing={4} align="stretch">
            <Text>Set Conditions:</Text>
            {conditions.map((condition, index) => (
              <HStack key={index} spacing={4}>
                {(condition.field && index > 0) &&

                  <Select
                    onChange={(e) => {
                      const newConditions = [...conditions];
                      const op = e.target.value;
                      newConditions[index].op = op;

                      setConditions(newConditions);
                    }}
                  >
                    <option value="And">And</option>
                    <option value="Or">Or</option>
                  </Select>
                }
                <Select
                  value={condition.id}
                  defaultValue={condition.id}
                  onChange={(e) => {
                    const newConditions = [...conditions];
                    const fieldId = e.target.value;
                    const fieldName = columns.find((col) => col.id === fieldId)
                    newConditions[index].id = fieldId;
                    newConditions[index].field = fieldName?.field || '';
                    newConditions[index].type = fieldName?.type || '';
                    newConditions[index].options = (fieldName?.options || [])
                    if (fieldName?.type === "number") {
                      newConditions[index].condition = "equals"
                    }
                    setCurrFieldType(fieldName?.type || '')
                    setConditions(newConditions);
                  }}
                >
                  <option value="">Select a field</option>
                  {columns.map((column) => (
                    <option key={column.id} value={column.id}>
                      {column.field}
                    </option>
                  ))}
                </Select>
                {(condition.type && condition.type.toLowerCase() === "number" || condition.type && condition.type.toLowerCase() === "date") &&
                  <Select
                    value={condition.condition}
                    onChange={(e) => {
                      const newConditions = [...conditions];
                      newConditions[index].condition = e.target.value;
                      setConditions(newConditions);
                    }}
                  >
                    <option value="">Select a condition</option>
                    <option value="equals">Equals</option>
                    <option value="notEquals">Not Equals</option>
                    <option value="greaterThan">Greater Than</option>
                    <option value="lessThan">Less Than</option>
                  </Select>}

                {(condition.type && condition.type.toLowerCase() === "text") &&

                  <Select
                    value={condition.condition}
                    onChange={(e) => {
                      const newConditions = [...conditions];
                      newConditions[index].condition = e.target.value;
                      setConditions(newConditions);
                    }}
                  >
                    <option value="">Select a condition</option>
                    <option value="equals">Equals</option>
                    <option value="notEquals">Not Equals</option>
                    <option value="contains">Contains</option>
                    <option value="notContains">Not Contains</option>
                  </Select>}

                {(condition.type && condition.type.toLowerCase() === "number" || condition.type && condition.type.toLowerCase() === "text") &&
                  <Input
                    value={condition.value}
                    onChange={(e) => {
                      const newConditions = [...conditions];
                      if (condition.type.toLocaleLowerCase() === "number") {
                        newConditions[index].value = parseFloat(e.target.value);
                      } else {
                        //  newConditions[index].condition = "string"
                        newConditions[index].value = e.target.value;
                      }

                      setConditions(newConditions);
                    }}
                    placeholder="Value"
                    type={condition.type.toLocaleLowerCase() === "number" ? "number" : "text"}
                  />
                }

                {condition.type === "date" &&
                  <DatePickerField
                  field={condition}
                  value={(new Date).toString()}
                    onChange={(formattedDate) => {
                      const newConditions = [...conditions];
                      newConditions[index].value = formattedDate;
                      setConditions(newConditions);
                    }}
                    isDisabled={false}
                  />
                }
                {(condition.type && condition.type.toLowerCase() === "select") &&

                  <Select
                    value={condition.condition}
                    onChange={(e) => {
                      const newConditions = [...conditions];
                      newConditions[index].condition = e.target.value;
                      setConditions(newConditions);
                    }}
                  >
                    <option value="">Select a condition</option>
                    <option value="equals">Equals</option>
                    <option value="notEquals">Not Equals</option>
                  </Select>
                }
                {(condition.type && condition.type.toLowerCase() === "select") &&

                  <Select
                    value={condition.value || ''}
                    onChange={(e) => {
                      const newConditions = [...conditions];
                      newConditions[index].value = e.target.value;
                      setConditions(newConditions);
                    }}
                  >
                    <option value="">Select a option</option>
                    {((selectedZone?.type === "tabs" ? condition?.options : selectedZone?.selectOptions?.[condition?.field]) || []).map((option, index) => (
                      <option key={option + "=" + index}>
                        {option}
                      </option>
                    ))}
                  </Select>
                }


              </HStack>
            ))}
            <Button
              onClick={() => setConditions([...conditions, { field: '', condition: 'equals', value: '', id: "", type: "", op: "And", options: [] }])}
              variant="outline"
              fontWeight="normal"
              colorScheme="brand">
              Add Condition
            </Button>
            {conditions.length === 0 && (
              <Text color="red.500" fontSize="sm" mt={2}>
                Please add at least one condition to continue
              </Text>
            )}
          </VStack>
        );
      case 3:
        return (
          <VStack spacing={4} align="stretch">
            <Text>Review your report conditions:</Text>
            {conditions.map((condition, index) => (
              <HStack key={index} spacing={4}>
                <Text>
                  {index > 0 && condition.op} {condition.field} {condition.condition} {condition.value}
                </Text>
              </HStack>
            ))}
          </VStack>
        );
      default:
        return null;
    }
  };


  const textColor = useColorModeValue('gray.700', 'white');
  const activeColor = useColorModeValue('orange.500', 'orange.200');
  const inactiveColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <CustomPopup isOpen={isOpen} onClose={onClose} hideCloseButton>
      <VStack width="600px" maxWidth="600px" spacing={0}>
        <Box width="100%" bg="gray.200" position="relative">
          <Flex justifyContent="center" alignItems="center" height="50px">
            <Text fontSize="2xl" align="center" fontWeight="normal">Create New Report</Text>
            {/*  <Image src={wizardImage} alt="Wizard" height="33px" objectFit="contain" /> */}
          </Flex>
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={onClose}
          />
        </Box>
        <Box width="100%" padding="10">
          <Box display="flex" justifyContent="center" width="100%">
            <Flex align="center" mb="8" width="300px">
              {steps.map((s, i) => (
                <React.Fragment key={i}>
                  <Flex direction="column" align="center">
                    <Box
                      w="15px"
                      h="15px"
                      borderRadius="full"
                      bg={i < step ? activeColor : inactiveColor}
                    />
                  </Flex>
                  {i < steps.length - 1 && (
                    <Box
                      flex="1"
                      h="2px"
                      bg={i < step - 1 ? activeColor : inactiveColor}
                      mx="2"
                    />
                  )}
                </React.Fragment>
              ))}
            </Flex>
          </Box>
          <Text fontSize="lg" align="center" color="orange.500" mb={0}>{steps[step - 1].title}</Text>
          <Text fontSize="ms" align="center" mb={10}>{steps[step - 1].description}</Text>

          {renderStepContent()}

          <HStack mt="4" justifyContent="space-between">
            {step > 1 && (
              <Button
                onClick={handleBack}
                variant="outline"
                fontWeight="normal"
                colorScheme="black"
                color="gray.700"
                _hover={{ bg: 'gray.200' }}
              >
                Previous
              </Button>
            )}
            {step === 1 && (
              <Button 
                colorScheme="orange" 
                onClick={handleNext}
                isDisabled={!selectedZone}
              >
                Next
              </Button>
            )}
            {step === 2 && (
              <Button 
                colorScheme="orange" 
                onClick={handleNext}
                isDisabled={!conditions.length || !conditions.every(condition => condition.field)}
              >
                Next
              </Button>
            )}
            {step === 3 && (
              <Flex gap="10px">
                <Button
                  colorScheme="orange"
                  fontWeight="normal"
                  onClick={() => handleSave("show")}
                >
                  Show Report
                </Button>
                <Button
                  colorScheme="orange"
                  fontWeight="normal"
                  onClick={() => handleSave("save")}
                >
                  Save And Show Report
                </Button>
              </Flex>
            )}
          </HStack>
        </Box>
      </VStack>
    </CustomPopup>
  );
};


export default ReportWizard;
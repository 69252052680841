import {
    Box,
    Th,
    Button,
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    VStack,
    Text,
    Icon,
    useDisclosure,
} from '@chakra-ui/react';
import { AddIcon, CalendarIcon } from '@chakra-ui/icons';
import { FaFont, FaHashtag, FaList, FaLink, FaFlag, FaCalculator } from 'react-icons/fa';


const fieldTypes = [
    { name: 'Text', icon: FaFont, color: 'orange.500' },
    { name: 'Number', icon: FaHashtag, color: 'brand.500' },
    { name: 'Select', icon: FaList, color: 'horizonYellow.500' },
 /*    { name: 'Relations', icon: FaLink, color: 'horizonYellow.600' }, */
    { name: 'Date', icon: CalendarIcon, color: 'red.500' },
    { name: 'Label', icon: FaFlag , color: 'orange.400' },
    { name: 'Calculation', icon: FaCalculator, color: 'purple.500' },
];

const AddColumnHeader = ({ onAddColumn }: { onAddColumn: (type: string) => void}) => {
    const { isOpen, onToggle, onClose } = useDisclosure();

    return (
        // <th
            // padding="0"
            // borderColor={borderColor}
            // borderWidth="1px"
            // borderStyle="solid"
            // width="50px"
            // overflow={"visible"}
        // >
            <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start">
                <PopoverTrigger>
                    <Button
                        leftIcon={<AddIcon />}
                        size="sm"
                        variant="ghost"
                        color="gray.500"
                        onClick={onToggle}
                    >
                        Add new column
                    </Button>
                </PopoverTrigger>
                <PopoverContent width="200px" p={0} border="1px" borderColor="gray.200" boxShadow="md">
                    <Box bg="white" borderRadius="md" overflow="hidden">
                        <Text p="10px 0 0 23px" fontWeight="normal" color="gray.500" fontSize="sm">
                            Select column type
                        </Text>
                        <VStack align="stretch" spacing={0} p={2}>
                            {fieldTypes.map((type) => (
                                <Button
                                    key={type.name}
                                    onClick={() => {
                                        onAddColumn(type.name);
                                        onClose();
                                    }}
                                    justifyContent="flex-start"
                                    variant="ghost"
                                    h="33px"
                                    fontSize="sm"
                                    color="gray.700"
                                    borderRadius="0"
                                    _hover={{ bg: "gray.200" }}
                                >
                                    <Box
                                        bg={type.color}
                                        w="24px"
                                        h="24px"
                                        borderRadius="sm"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        mr={3}
                                    >
                                        <Icon as={type.icon} color="white" boxSize={4} />
                                    </Box>
                                    {type.name}
                                </Button>
                            ))}
                        </VStack>
                    </Box>
                </PopoverContent>
            </Popover>
        // </th>
    );
};

export default AddColumnHeader;
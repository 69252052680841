import React, { useEffect, useState } from 'react';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { zoneService } from 'services/zoneService';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Box } from '@chakra-ui/react';

// Custom tooltip component
const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ 
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color:'white',
        padding: '8px',
        border: '0px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <p style={{ 
          margin: '0',
          fontWeight: 'bold',
          fontSize: '12px'
        }}>{`${label}`}</p>
        <p style={{ 
          margin: '0',
          fontSize: '12px'
        }}>{`Value: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

interface BarChartProps {
  zoneId: string;
  xAxis: string;
  yAxis: string;
}

const BarChart: React.FC<BarChartProps> = ({ zoneId, xAxis, yAxis }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const zoneData = await zoneService.getZoneById(zoneId);

        if (zoneData.data.type === 'table') {
          // For table-type zones, use the data directly from zoneData
          const tableData = zoneData.data.data || [];

          if (!Array.isArray(tableData) || tableData.length === 0) {
            throw new Error('No items found for the given zoneId');
          }

          const chartData = tableData.map((item: any) => {
            const xValue = item[xAxis] || 'Unknown';
            const yValue = parseFloat(item[yAxis]) || 0;
            return {
              name: xValue,
              value: yValue
            };
          });

          setData(chartData);
        } else {
          // Existing logic for non-table zones
          const zoneItems = await zoneService.getZoneItems(zoneId);

          if (!Array.isArray(zoneItems) || zoneItems.length === 0) {
            throw new Error('No items found for the given zoneId');
          }

          const chartData = zoneItems.map((item: any) => {
            const xValue = item.data[xAxis]?.value || item.data[xAxis] || 'Unknown';
            const yValue = parseFloat(item.data[yAxis]?.value || item.data[yAxis]) || 0;
            return {
              name: xValue,
              value: yValue
            };
          });

          setData(chartData);
        }
      } catch (error) {
        console.error('Error fetching zone items:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };


      fetchData()

  }, [zoneId, xAxis, yAxis]);

  if (isLoading) {
    return <div>Loading chart data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (data.length === 0) {
    return <div>No data available for the selected axes</div>;
  }

  interface CustomXAxisTickProps {
    x: number;
    y: number;
    payload: {
      value: string;
    };
  }

  const CustomXAxisTick: React.FC<CustomXAxisTickProps> = ({ x, y, payload }) => {
    const fullText = payload.value;
    const displayText = fullText.length > 15 ? 
      fullText.substring(0, 15) + '...' : 
      fullText;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={10}
          dx={10}
          textAnchor="start"
          fill="#666"
          fontSize="12px"
          style={{ cursor: 'default' }}
          transform="rotate(45)"
          onMouseEnter={(e) => {
            setTooltipContent(fullText);
            setTooltipPosition({ x: e.clientX, y: e.clientY - 40 });
            setTooltipVisible(true);
          }}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          {displayText}
        </text>
      </g>
    );
  };

  return (
    <Box>
      <Box 
        overflowX="auto" 
        css={{
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        <Box minWidth={Math.max(600, data.length * 50)} position="relative">
          <ResponsiveContainer width="100%" height={300}>
            <RechartsBarChart 
              data={data}
              margin={{
                top: 10,
                right: 15,
                left: 15,
                bottom: 40
              }}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis 
                dataKey="name"
                tick={(props) => <CustomXAxisTick {...props} />}
                interval={0}
                height={60}
              />
              <YAxis
                tick={{ fontSize: 12 }}
                width={30}
                tickFormatter={(value) => {
                  if (typeof value === 'number') {
                    return value.toLocaleString();
                  }
                  return value;
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar 
                dataKey="value" 
                fill="#861B81"
                activeBar={{ fill: '#ff7300' }}
              />
            </RechartsBarChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      
    </Box>
  );
};

export default BarChart;
import React from 'react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

interface StyledFormControlLabelProps {
  control: React.ReactNode;
  label: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledFormControlLabel: React.FC<StyledFormControlLabelProps> = ({ control, label, checked, onChange }) => {
  // Extract the Switch component from the control prop
  const switchProps = React.isValidElement(control) ? control.props : {};
  
  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel
        htmlFor="multi-select-switch"
        mb="0"
        color="#000000"
        fontSize="0.875rem"
      >
        {label}
      </FormLabel>
      <Switch
        id="multi-select-switch"
        isChecked={checked}
        onChange={onChange}
        {...switchProps}
      />
    </FormControl>
  );
};

export default StyledFormControlLabel; 
import api from "./api";
import { AxiosError } from "axios";

export const reportService = {
  getAllReports: async (companyId: string,userRole:string,userEmail:string): Promise<{result: any[]}> => {
    try {
      const response = await api.get(`/api/zones/report/get-allreports/${companyId}/${userRole}/${userEmail}`);
      return response.data;
    } catch (error) {
      throw error;
    }

  },
  getReportResults: async (
    zoneId: string,
    selectedFields: string[],
    filters: any[]
  ): Promise<any[]> => {
    try {
      console.log("Sending request to /zones/reports/results with:", {
        zoneId,
        selectedFields,
        filters,
      });
      const response = await api.post("/api/zones/reports/results", {
        zoneId,
        selectedFields,
        filters,
      });
      console.log("Response from server:", response);
      return response.data;
    } catch (error) {
      console.error("Error fetching report results:", error);
      if ((error as AxiosError).response) {
        console.error("Response data:", (error as AxiosError).response?.data);
        console.error(
          "Response status:",
          (error as AxiosError).response?.status
        );
        console.error(
          "Response headers:",
          (error as AxiosError).response?.headers
        );
      } else if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
      }
      if (error instanceof Error && "isAxiosError" in error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.error("Response data:", axiosError.response.data);
          console.error("Response status:", axiosError.response.status);
        } else if (axiosError.request) {
          console.error("No response received:", axiosError.request);
        } else {
          console.error("Error setting up request:", axiosError.message);
        }
      }
      throw error;
    }
  },

  queryReport: async (reportQueryBody: any) => {
    try {
      // Add debug information about price field conditions
      if (reportQueryBody.conditions) {
        const priceConditions = reportQueryBody.conditions.filter((c: any) => 
          (c.type && c.type.toLowerCase() === "price") || 
          (c.field && (c.field.toLowerCase().includes("price") || c.field.toLowerCase().includes("cost")))
        );
        
        if (priceConditions.length > 0) {
          console.log("Price field conditions found:", priceConditions);
        }
      }
      
      console.log("Sending query to server:", JSON.stringify(reportQueryBody, null, 2));
      const response = await api.post(`/api/zones/report/query-zones`, {
        reportQueryBody,
      });
      console.log("Server response success:", response.status);
      return response.data;
    } catch (error: any) {
      console.error("Error querying report:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        
        // Try to get more detailed error information
        if (error.response.data && error.response.data.error) {
          console.error("Server error message:", error.response.data.error);
        }
        
        if (error.response.data && error.response.data.stack) {
          console.error("Server error stack:", error.response.data.stack);
        }
      }
      throw error;
    }
  },
  duplicateReport: async (reportId: string) => {
    try {
      const response = await api.post(`/api/zones/duplicate/${reportId}`);
      return response.data;
    } catch (error) {
      console.error("Error duplicating report:", error);
      throw error;
    }
  },
  updateReportsOrder: async (reports: { _id: string; order: number }[]) => {
    try {
      const response = await api.put('/api/zones/reports/update-order', { reports });
      return response.data;
    } catch (error) {
      console.error("Error updating reports order:", error);
      throw error;
    }
  },
  deleteReport: async (reportId: string) => {
    try {
      const response = await api.delete(`/api/zones/report/${reportId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting report:", error);
      throw error;
    }
  },
  updateReport: async (reportId: string, reportData: any) => {
    try {
      const response = await api.put(`/api/zones/report/${reportId}`, reportData);
      return response.data;
    } catch (error) {
      console.error("Error updating report:", error);
      throw error;
    }
  },
  createReport: async (report: any) => {
    try {
      if (!report.zone) {
        throw new Error('Zone is required');
      }
      console.log('Sending report data to server:', JSON.stringify(report, null, 2));
      const response = await api.post('/api/zones/report/create', report);
      console.log('Server response:', response.data);
      return response.data;
    } catch (error: unknown) {
      console.error('Error creating report:', error);
      if (error instanceof Error && 'response' in error) {
        const axiosError = error as { response?: { data: unknown } };
        if (axiosError.response) {
          console.error('Server error response:', axiosError.response.data);
        }
      }
      throw error;
    }
  },
};

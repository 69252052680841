import React, { memo, useState, useEffect } from 'react';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';

interface SearchBarProps {
  variant?: string;
  background?: string;
  children?: any;
  placeholder?: string;
  borderRadius?: string | number;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  debounce?: number;
  [x: string]: any;
}

export const SearchBar = memo(({
  variant,
  background,
  children,
  onChange,
  onClear,
  value,
  placeholder,
  borderRadius,
  debounce = 300,
  ...rest
}: SearchBarProps) => {
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  const [inputValue, setInputValue] = useState(value?.toString() || '');

  // Handle immediate input updates
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  // Debounced search effect
  useEffect(() => {
    const handler = setTimeout(() => {
      onChange({ target: { value: inputValue } } as React.ChangeEvent<HTMLInputElement>);
    }, debounce);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, debounce, onChange]);

  const handleClear = () => {
    setInputValue('');
    if (onClear) {
      onClear();
    } else {
      onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
      <InputLeftElement>
        <IconButton
          aria-label="search"
          bg="inherit"
          borderRadius="inherit"
          _active={{
            bg: 'inherit',
            transform: 'none',
            borderColor: 'transparent',
          }}
          _hover={{
            background: 'none',
          }}
          _focus={{
            background: 'none',
            boxShadow: 'none',
          }}
          icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
        />
      </InputLeftElement>
      <Input
        variant="search"
        fontSize="sm"
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight="500"
        _placeholder={{ color: 'gray.400', fontSize: '14px' }}
        borderRadius={borderRadius ? borderRadius : '30px'}
        placeholder={placeholder ? placeholder : 'Search...'}
        value={inputValue}
        onChange={handleChange}
        autoComplete="off"
        spellCheck="false"
      />
      {inputValue && (
        <InputRightElement>
          <IconButton
            aria-label="Clear search"
            icon={<CloseIcon />}
            size="sm"
            variant="ghost"
            onClick={handleClear}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
});

SearchBar.displayName = 'SearchBar';

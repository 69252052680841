/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, useColorModeValue, Text, Link } from '@chakra-ui/react';
import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';

// Custom components
import DefaultAuth from 'layouts/auth/variants/Default';

// Assets
import illustration from 'assets/img/auth/auth.png';

function ForgotPassword() {
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState('');
	const toast = useToast();

	// Chakra color mode
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorSecondary = 'gray.400';
	const brandStars = useColorModeValue('brand.500', 'brand.400');

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const API_BASE_URL = process.env.REACT_APP_API_URL;
			
			await axios.post(`${API_BASE_URL}/api/auth/forgot-password`, { email });
			setMessage('Password reset link sent to your email.');
			toast({
				title: 'Email sent',
				description: 'Check your inbox for the password reset link.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error('Forgot password error:', error);
			let errorMessage = 'Failed to send reset link. Please try again.';
			if (axios.isAxiosError(error) && error.response?.status === 404) {
				errorMessage = 'This email address is not registered in our system.';
			}
			setMessage(errorMessage);
			toast({
				title: 'Error',
				description: errorMessage,
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<DefaultAuth illustrationBackground={illustration} image={illustration}>
			<Flex
				w='100%'
				maxW='max-content'
				mx={{ base: 'auto', lg: '0px' }}
				me='auto'
				h='100%'
				alignItems='start'
				justifyContent='center'
				mb={{ base: '30px', md: '60px', lg: '100px', xl: '60px' }}
				px={{ base: '25px', md: '0px' }}
				mt={{ base: '40px', lg: '16vh', xl: '22vh' }}
				flexDirection='column'>
				<Box me='auto' mb='34px'>
					<Heading color={textColor} fontSize={{ base: '3xl', md: '36px' }} mb='16px'>
						Forgot your password?
					</Heading>
					<Text color={textColor} fontSize='md' w={{ base: '100%', lg: '456px' }} maxW='100%'>
						Enter your email, and we'll send you instructions to reset your password
					</Text>
				</Box>
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', lg: '456px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}
					align='start'>
					<FormControl>
						<FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
							Email<Text color={brandStars}>*</Text>
						</FormLabel>
						<Input
							isRequired={true}
							variant='auth'
							fontSize='sm'
							type='email'
							//placeholder='mail@simmmple.com'
							mb='24px'
							size='lg'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Button
							fontSize='sm'
							variant='brand'
							fontWeight='500'
							w='100%'
							h='50'
							mb='24px'
							onClick={handleSubmit}
							isLoading={isLoading}
						>
							Send reset link
						</Button>
					</FormControl>
					{message && (
						<Text color={message.includes('sent') ? 'green.500' : 'red.500'} textAlign='center' mb='24px'>
							{message}
						</Text>
					)}
					<Link
						href="/auth/sign-in/default"
						color={textColor}
						fontSize="sm"
						textAlign="center"
						width="100%"
						_hover={{ color: brandStars }}
					>
						Back to Login
					</Link>
				</Flex>
			</Flex>
		</DefaultAuth>
	);
}

export default ForgotPassword;
//* react
import React from "react";

//* material-ui
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

//* types
import {
  GridColumnMenu,
  GridColumnMenuItemProps,
  GridColumnMenuProps,
} from "@mui/x-data-grid-pro";

//* icons
import MuiSettingsIcon from "@mui/icons-material/Settings";
import MuiEditIcon from "@mui/icons-material/Edit";
import MuiDeleteIcon from "@mui/icons-material/Delete";

function CustomMenuItem(props: GridColumnMenuItemProps) {
  const { handler, value, icon } = props;
  return (
    <MenuItem onClick={handler}>
      <ListItemIcon>
        {icon ? React.createElement(icon) : <MuiSettingsIcon />}
      </ListItemIcon>
      <ListItemText>{value}</ListItemText>
    </MenuItem>
  );
}
function CustomColumnMenu({
  props,
  setActivePopover,
  columnTypes,
  setIsEditingLabel,
  deleteColumn,
  setEditingColumn,
  selectOptions,
}: {
  props: GridColumnMenuProps;
  setActivePopover: React.Dispatch<
    React.SetStateAction<{
      id: string | null;
      type:
        | "columnOptions"
        | "editSelectOptions"
        | "editCalculation"
        | "editLabel"
        | null;
    }>
  >;
  columnTypes: { [key: string]: string };
  setIsEditingLabel: React.Dispatch<React.SetStateAction<boolean>>;
  deleteColumn: (columnName: string) => void;
  setEditingColumn: React.Dispatch<React.SetStateAction<string>>;
  selectOptions: { [key: string]: any };
}) {
  const type = props.colDef.type;
  const field = props.colDef.field;
  const originalColumnType = columnTypes[field];

  return (
    <GridColumnMenu
      {...props}
      slots={{
        editColumnNameItem: CustomMenuItem,
        // Add new item
        manageSelectOptionsItem:
          (type === "singleSelect" || (originalColumnType === "select" && typeof selectOptions[field] === "object" && !Array.isArray(selectOptions[field]) && (selectOptions[field] as any)?.isMultiSelect === true)) && originalColumnType !== "label"
            ? CustomMenuItem
            : null,
        manageLabelSelectOptionsItem:
          originalColumnType === "label" ? CustomMenuItem : null,
        manageCalculationsItem:
          originalColumnType === "calculation" ? CustomMenuItem : null,
        deleteColumnItem: CustomMenuItem,
      }}
      slotProps={{
        editColumnNameItem: {
          displayOrder: 98,
          value: "Edit name",
          handler: () => {
            setEditingColumn(field);
            props.hideMenu(undefined);
          },
          icon: MuiEditIcon,
          iconProps: { sx: { color: "#FE7053" } },
        },
        manageSelectOptionsItem: {
          displayOrder: 90,
          value: "Edit options",
          handler: () => {
            setActivePopover({
              id: field,
              type: "editSelectOptions",
            });
            props.hideMenu(undefined);
          },
          iconProps: { sx: { color: "#FE7053" } },
        },
        manageLabelSelectOptionsItem: {
          displayOrder: 90,
          value: "Edit options",
          handler: () => {
            setActivePopover({
              id: field,
              type: "editLabel",
            });
            setIsEditingLabel(true);
            props.hideMenu(undefined);
          },
          iconProps: { sx: { color: "#FE7053" } },
        },
        manageCalculationsItem: {
          // set `displayOrder` for new item
          displayOrder: 91,
          // pass additional props
          value: "Set calculation",
          handler: () => {
            setActivePopover({
              id: field,
              type: "editCalculation",
            });
            props.hideMenu(undefined);
          },
        },
        deleteColumnItem: {
          // set `displayOrder` for new item
          displayOrder: 99,
          // pass additional props
          value: "Delete",
          handler: () => {
            deleteColumn(field);
            props.hideMenu(undefined);
          },
          icon: MuiDeleteIcon,
          iconProps: { sx: { color: "#FE7053" } },
        },
      }}
      onWheel={(e) => e.stopPropagation()}
    />
  );
}

export default CustomColumnMenu;

import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { zoneService } from "services/zoneService";
import { exportToExcel } from "../../../../services/exportService";
import { ErrorBoundary } from "react-error-boundary";
import { isValid } from "date-fns";
import { useDispatch } from "react-redux";
import { setActiveZone } from "store/slices/zoneSlice";
import type {} from "@mui/x-data-grid/themeAugmentation";
import {
  CalculationOption,
  RowObj,
  ColumnSizing,
  SelectOption,
  ColumnType,
  AddColumnType,
  LabelOption,
  MultiSelectOption,
} from "types/zone-datagrid";
import ZoneDataGrid from "components/ZoneDataGrid";

interface ZonePageProps {
  setCurrentZoneName: (name: string) => void;
  isEditable?: boolean;
  reportResult?: null | any;
}
const DEFAULT_COLUMN_WIDTH = 150;
const DEFAULT_COLUMNS = ["Name", "Email", "Address", "Company"];

const ErrorFallback = ({ error }: { error: Error }) => {
  console.error("Error in ZonePage:", error);
  return (
    <Box
      p={4}
      color="red.500"
    >
      <Text>
        An error occurred while rendering the table. Please try refreshing the
        page.
      </Text>
    </Box>
  );
};

const ZonePage: React.FC<ZonePageProps> = ({
  setCurrentZoneName,
  isEditable = true,
  reportResult = null,
}) => {
  const [columns, setColumns] = useState<string[]>([]);
  const [data, setData] = useState<RowObj[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id: zoneId } = useParams<{ id: string }>();
  const [selectOptions, setSelectOptions] = useState<{
    [key: string]: SelectOption;
  }>({});

  const [zoneName, setZoneName] = useState<string>("");
  const [columnSizing, setColumnSizing] = useState<ColumnSizing>({});
  const [columnOrder, setColumnOrder] = useState<string[]>(columns);
  const [columnTypes, setColumnTypes] = useState<{ [key: string]: ColumnType }>(
    {},
  );
  const toast = useToast();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const saveDataToDb = useCallback(
    (
      newData: RowObj[],
      newSelectOptions: { [key: string]: SelectOption },
      newColumnOrder: string[],
      newColumnTypes: { [key: string]: string },
      currZoneName?: string,
      newColumns?: string[],
    ) => {
      zoneService.saveDataToDb(
        newData,
        newSelectOptions as { [key: string]: string[] },
        newColumnOrder,
        newColumnTypes,
        zoneId as string,
        currZoneName || zoneName,
        newColumns || columns,
      );
    },
    [zoneId, zoneName, columns],
  );

  const debouncedSaveDataToDb = useMemo(
    () =>
      debounce(
        (
          newData: RowObj[],
          newSelectOptions: { [key: string]: SelectOption },
          newColumnOrder: string[],
          newColumnTypes: { [key: string]: string },
          newColumns?: string[],
        ) => {
          saveDataToDb(
            newData,
            newSelectOptions,
            newColumnOrder,
            newColumnTypes,
            undefined,
            newColumns,
          );
        },
        1000,
      ),
    [saveDataToDb],
  );

  const updateData = useCallback(
    (rowIndex: number, columnId: string, value: any) => {
      console.log(rowIndex, columnId, value);
      setData((prevData) => {
        console.log(prevData);
        const newData = [...prevData];
        if (columnTypes[columnId] === "date") {
          if (value && isValid(new Date(value))) {
            newData[rowIndex][columnId] = value;
          } else {
            newData[rowIndex][columnId] = null;
          }
        } else {
          newData[rowIndex][columnId] = value;
        }
        console.log(newData);
        debouncedSaveDataToDb(newData, selectOptions, columnOrder, columnTypes);
        return newData;
      });
    },
    [debouncedSaveDataToDb, selectOptions, columnOrder, columnTypes],
  );
  const renameColumn = useCallback(
    (oldName: string, newName: string) => {
      const updates = {
        columnTypes: { ...columnTypes },
        selectOptions: { ...selectOptions },
        order: columnOrder.map((item) => (oldName === item ? newName : item)),
      };

      if (oldName in updates.columnTypes) {
        updates.columnTypes[newName] = updates.columnTypes[oldName];
        delete updates.columnTypes[oldName];
      }

      if (oldName in updates.selectOptions) {
        updates.selectOptions[newName] = updates.selectOptions[oldName];
        delete updates.selectOptions[oldName];
      }

      setColumnOrder(updates.order);
      setColumns(updates.order);
      setSelectOptions(updates.selectOptions);
      setColumnTypes(updates.columnTypes);

      const newData = data.map((row) => {
        const newRow = { ...row };
        if (oldName in newRow) {
          newRow[newName] = newRow[oldName];
          delete newRow[oldName];
        }
        return newRow;
      });

      setData(newData);
      saveDataToDb(
        newData,
        updates.selectOptions,
        updates.order,
        updates.columnTypes,
        null,
        updates.order,
      );
    },
    [saveDataToDb, selectOptions, columnOrder, columnTypes, data],
  );
  const deleteColumn = useCallback(
    (columnName: string) => {
      setColumns((prevColumns) =>
        prevColumns.filter((col) => col !== columnName),
      );
      setColumnOrder((prevOrder) =>
        prevOrder.filter((col) => col !== columnName),
      );
      setData((prevData) => {
        const newData = prevData.map((row) => {
          const { [columnName]: _, ...rest } = row;
          return rest;
        });

        setColumnTypes((p) => {
          delete p[columnName];

          return p;
        });
        saveDataToDb(
          newData,
          selectOptions,
          columnOrder.filter((col) => col !== columnName),
          { ...columnTypes, [columnName]: undefined },
          zoneName,
          columns.filter((col) => col !== columnName),
        );
        return newData;
      });
    },
    [saveDataToDb, selectOptions, columnOrder, columnTypes, zoneName, columns],
  );

  const saveColumnWidths = useCallback(
    (newColumnSizing: Record<string, number>) =>
      zoneService.saveColumnWidths(newColumnSizing, zoneId),
    [zoneId],
  );
  const debouncedSaveColumnWidths = useMemo(
    () =>
      debounce((newColumnSizing: Record<string, number>) => {
        saveColumnWidths(newColumnSizing);
      }, 1500),
    [saveColumnWidths],
  );
  const resizeColumn = useCallback(
    (columnId: string, width: number) => {
      setColumnSizing((prev) => ({
        ...prev,
        [columnId]: width,
      }));
      debouncedSaveColumnWidths({ ...columnSizing, [columnId]: width });
    },
    [columnSizing, debouncedSaveColumnWidths],
  );

  useEffect(() => {
    const fetchZoneData = async () => {
      if (!zoneId) return;
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/zones/${zoneId}`,
        );
        if (!response.ok) throw new Error("Failed to fetch zone data");
        const zoneData = await response.json();

        if (zoneData.name) {
          //console.log("zoneData.name", zoneData.name)
          setZoneName(zoneData.name);
          dispatch(setActiveZone(zoneData.name));
          setCurrentZoneName(zoneData.name);
        }

        let columnsToUse: string[] = zoneData.columns || [];
        if (
          columnsToUse.length === 0 &&
          zoneData.columnOrder &&
          zoneData.columnOrder.length > 0
        ) {
          columnsToUse = zoneData.columnOrder;
        }
        let dataToUse: RowObj[] = zoneData.data || [];

        if (columnsToUse.length === 0 && dataToUse.length > 0) {
          columnsToUse = Object.keys(dataToUse[0]);
        }

        if (columnsToUse.length === 0) {
          columnsToUse = DEFAULT_COLUMNS;
        }
        setColumns(columnsToUse);
        setData(Array.isArray(dataToUse) ? dataToUse : []);
        setColumnOrder(zoneData.columnOrder || columnsToUse);

        if (zoneData.selectOptions) {
          setSelectOptions(zoneData.selectOptions);
        }

        if (zoneData.columnWidths) {
          setColumnSizing(zoneData.columnWidths);
        } else {
          const defaultColumnSizing = Object.fromEntries(
            columnsToUse.map((column) => [column, DEFAULT_COLUMN_WIDTH]),
          );
          setColumnSizing(defaultColumnSizing);
          saveColumnWidths(defaultColumnSizing);
        }
        if (Object.keys(zoneData.columnTypes).length) {
          setColumnTypes({
            ...Object.fromEntries(
              columnsToUse.map((column) => [column, "text"]),
            ),
            ...zoneData.columnTypes,
          });
        } else {
          const defaultColumnTypes = Object.fromEntries(
            columnsToUse.map((column) => [column, "text" as ColumnType]),
          );
          setColumnTypes(defaultColumnTypes);
          // Save the default column types to the server
          saveDataToDb(
            dataToUse,
            zoneData.selectOptions || {},
            columnsToUse,
            defaultColumnTypes,
            zoneData.name,
          );
        }
      } catch (error) {
        console.error("Error fetching zone data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reportResult) {
      setIsLoading(true);
      const [zoneData] = reportResult;

      if (zoneData.name) {
        setZoneName(zoneData.name);
        dispatch(setActiveZone(zoneData.name));
        setCurrentZoneName(zoneData.name);
      }

      let columnsToUse: string[] = zoneData.columns || [];
      if (
        columnsToUse.length === 0 &&
        zoneData.columnOrder &&
        zoneData.columnOrder.length > 0
      ) {
        columnsToUse = zoneData.columnOrder;
      }
      let dataToUse: RowObj[] = zoneData.data || [];

      if (columnsToUse.length === 0 && dataToUse.length > 0) {
        columnsToUse = Object.keys(dataToUse[0]);
      }

      if (columnsToUse.length === 0) {
        columnsToUse = DEFAULT_COLUMNS;
      }
      setColumns(columnsToUse);
      setData(Array.isArray(dataToUse) ? dataToUse : []);
      setColumnOrder(zoneData.columnOrder || columnsToUse);

      if (zoneData.selectOptions) {
        setSelectOptions(zoneData.selectOptions);
      }

      if (zoneData.columnWidths) {
        setColumnSizing(zoneData.columnWidths);
      } else {
        const defaultColumnSizing = Object.fromEntries(
          columnsToUse.map((column) => [column, DEFAULT_COLUMN_WIDTH]),
        );
        setColumnSizing(defaultColumnSizing);
        saveColumnWidths(defaultColumnSizing);
      }

      if (Object.keys(zoneData.columnTypes || {}).length) {
        setColumnTypes({
          ...Object.fromEntries(columnsToUse.map((column) => [column, "text"])),
          ...zoneData.columnTypes,
        });
      } else {
        const defaultColumnTypes = Object.fromEntries(
          columnsToUse.map((column) => [column, "text" as ColumnType]),
        );

        setColumnTypes(defaultColumnTypes);
        // Save the default column types to the server
        saveDataToDb(
          dataToUse,
          zoneData.selectOptions || {},
          columnsToUse,
          defaultColumnTypes,
          zoneData.name,
        );
      }
      setIsLoading(false);
    } else {
      fetchZoneData();
    }
  }, [zoneId, setCurrentZoneName, saveColumnWidths, reportResult]);

  const removeDuplicates = (arr: string[]): string[] => {
    return Array.from(new Set(arr));
  };

  const addColumn = useCallback(
    (type: AddColumnType) => {
      const newColumnName = `${type} ${columns.length + 1}`;

      // Batch state updates
      const updates = {
        columns: removeDuplicates([...columns, newColumnName]),
        columnOrder: removeDuplicates([...columnOrder, newColumnName]),
        columnTypes: {
          ...columnTypes,
          [newColumnName]: type.toLowerCase() as ColumnType,
        },
        data: data.map((row) => ({
          ...row,
          [newColumnName]: type === "Label" ? "" : "",
        })),
      };

      console.log("addColumn", newColumnName, updates);

      // If it's a label column, prepare the select options
      const newSelectOptions =
        type === "Label"
          ? {
              ...selectOptions,
              [newColumnName]: [{ label: "Option 1", color: "#dbdbdb" }],
            }
          : selectOptions;

      // Batch update all states
      setColumns(updates.columns);
      setColumnOrder(updates.columnOrder);
      setColumnTypes(updates.columnTypes);
      setData(updates.data);
      if (type === "Label") {
        setSelectOptions(newSelectOptions);
      }

      // Debounce the save operation
      debouncedSaveDataToDb(
        updates.data,
        newSelectOptions,
        updates.columnOrder,
        updates.columnTypes,
        updates.columns,
      );
    },
    [
      columns,
      columnOrder,
      columnTypes,
      data,
      selectOptions,
      debouncedSaveDataToDb,
    ],
  );
  const addRow = React.useCallback(() => {
    const container = containerRef.current;
    const scrollPosition = container?.scrollTop;

    setData((prevData) => {
      const newRow = columns.reduce(
        (acc, column) => ({ ...acc, [column]: "" }),
        {},
      );
      const newData = [...prevData, newRow];
      saveDataToDb(newData, selectOptions, columnOrder, columnTypes);

      // Restore scroll position after state update
      requestAnimationFrame(() => {
        if (container && scrollPosition) {
          container.scrollTop = scrollPosition;
        }
      });

      return newData;
    });
  }, [
    columns,
    saveDataToDb,
    selectOptions,
    columnOrder,
    columnTypes,
    containerRef,
  ]);

  const manageSelectOptions = useCallback(
    (columnId: string, options: string[] | MultiSelectOption) => {
      setSelectOptions((prev) => {
        // Handle both string[] and MultiSelectOption types
        const newSelectOptions = { ...prev, [columnId]: options };
        setData((prevData) => {
          const newData = prevData.map((row) => {
            // For string[] type
            if (Array.isArray(options)) {
              return {
                ...row,
                [columnId]:
                  row[columnId] && !options.includes(row[columnId])
                    ? ""
                    : row[columnId],
              };
            }
            // For MultiSelectOption type
            else if (options.isMultiSelect && Array.isArray(options.options)) {
              // For multi-select, we leave the value as is if it's an array
              // or convert single value to array if needed
              const currentValue = row[columnId];
              if (Array.isArray(currentValue)) {
                // Filter out values that aren't in options anymore
                return {
                  ...row,
                  [columnId]: currentValue.filter(val => 
                    options.options.includes(val)
                  ),
                };
              } else if (currentValue && options.options.includes(currentValue)) {
                // Convert single value to array for multi-select
                return {
                  ...row,
                  [columnId]: [currentValue],
                };
              } else {
                return {
                  ...row,
                  [columnId]: [],
                };
              }
            }
            // Fallback
            return row;
          });
          saveDataToDb(newData, newSelectOptions, columnOrder, columnTypes);
          return newData;
        });
        return newSelectOptions;
      });
    },
    [saveDataToDb, columnOrder, columnTypes],
  );
  const manageLabelOptions = useCallback(
    (columnId: string, options: LabelOption[]) => {
      setSelectOptions((prev) => {
        const newSelectOptions = {
          ...prev,
          [columnId]: options,
        };
        setData((prevData) => {
          // Instead of resetting values that don't match, keep the existing values
          // This preserves the selected values when editing option labels
          const newData = prevData.map((row) => {
            // If the cell has a value and it's not in the new options, keep it
            const currentValue = row[columnId];
            // Only reset if the current value doesn't exist at all
            if (!currentValue || currentValue === "") {
              return {
                ...row,
                [columnId]: options.length > 0 ? options[0].label : "",
              };
            }
            return row;
          });
          saveDataToDb(newData, newSelectOptions, columnOrder, columnTypes);
          return newData;
        });
        return newSelectOptions;
      });
    },
    [saveDataToDb, columnOrder, columnTypes],
  );
  const updateCalculationOptions = useCallback(
    (columnId: string, options: CalculationOption) => {
      setSelectOptions((prev) => ({
        ...prev,
        [columnId]: options,
      }));
      setColumnTypes((prev) => ({
        ...prev,
        [columnId]: "calculation",
      }));
      saveDataToDb(
        data,
        { ...selectOptions, [columnId]: options },
        columnOrder,
        { ...columnTypes, [columnId]: "calculation" },
      );
    },
    [columnOrder, columnTypes, data, saveDataToDb, selectOptions],
  );

  const saveColumnOrder = useCallback(
    (
      newOrder: string[],
      columnTypes: { [key: string]: string },
      zoneId: string,
    ) => {
      if (!zoneId) return;
      zoneService.saveColumnOrder(newOrder, columnTypes, zoneId);
    },
    [],
  );

  const deleteSelectedRows = (newData: any[]) => {
    setData(newData);
    saveDataToDb(newData, selectOptions, columnOrder, columnTypes);
  };
  const duplicateSelectedRows = (newData: any[]) => {
    setData(newData);
    saveDataToDb(newData, selectOptions, columnOrder, columnTypes);
  };
  const exportSelectedRows = (selectedData: any[]) => {
    if (selectedData.length > 0) {
      const fileName = `${zoneName}_export_${new Date().toISOString()}.xlsx`;
      exportToExcel(selectedData, fileName);

      toast({
        title: "Export Successful",
        description: `${selectedData.length} rows exported to ${fileName}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Export Failed",
        description: "No rows selected for export",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleImportData = (
    cleanCurrentData: any[],
    processedImportedData: any[],
    newColumns: {
      name: string;
      type: string;
    }[],
    updatedColumns: string[],
  ) => {
    const resultData = [...cleanCurrentData, ...processedImportedData];
    setData(resultData);

    const updatedSelectOptions = { ...selectOptions };
    const updatedColumnTypes = { ...columnTypes };
    newColumns.forEach((col) => {
      updatedColumnTypes[col.name] = col.type.toLowerCase() as ColumnType;
      if (col.type.toLowerCase() === "select") {
        updatedSelectOptions[col.name] = Array.from(
          new Set(processedImportedData.map((row) => row[col.name])),
        ).filter(Boolean);
      }
    });

    setSelectOptions(updatedSelectOptions);
    setColumnTypes(updatedColumnTypes);
    setColumns(updatedColumns);
    setColumnOrder(updatedColumns);

    saveDataToDb(
      [...cleanCurrentData, ...processedImportedData],
      updatedSelectOptions,
      updatedColumns,
      updatedColumnTypes,
      undefined,
      updatedColumns,
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ZoneDataGrid
        reportResult={reportResult}
        zoneId={zoneId}
        rows={data}
        columns={columns}
        columnTypes={columnTypes}
        columnOrder={columnOrder}
        columnWidths={columnSizing}
        selectOptions={selectOptions}
        onRenameColumn={renameColumn}
        onProcessRowUpdateData={updateData}
        onColumnOrderChange={(newOrder) =>
          saveColumnOrder(newOrder, columnTypes, zoneId)
        }
        onColumnResize={resizeColumn}
        onAddColumn={addColumn}
        onRowAdd={addRow}
        onDeleteColumn={deleteColumn}
        manageSelectOptions={manageSelectOptions}
        manageLabelsOptions={manageLabelOptions}
        onUpdateCalculationOptions={updateCalculationOptions}
        onImportData={handleImportData}
        onDeleteSelectedRows={deleteSelectedRows}
        onDuplicateSelectedRows={duplicateSelectedRows}
        onExportSelectedRows={exportSelectedRows}
      />
    </ErrorBoundary>
  );
};

export default ZonePage;

declare module "@mui/material/styles" {
  interface Components {
    MuiDataGridPro: {
      styleOverrides?: {
        root?: {
          [key: string]: any; // This allows for CSS selector strings
        };
      };
    };
  }
}

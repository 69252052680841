import { CopyIcon, DeleteIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { FaFileExcel } from "react-icons/fa";

interface CardHeaderViewProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  filteredRows: any[];
  rowSelectionModel: GridRowSelectionModel;
  handleDeleteSelectedRows: () => void;
  handleDuplicateSelectedRows: () => void;
  handleExportSelectedRows: () => void;
  setIsImportWizardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isImportExcel: boolean;
}

const CardHeaderView: React.FC<CardHeaderViewProps> = ({
  searchTerm,
  setSearchTerm,
  filteredRows,
  rowSelectionModel,
  handleDeleteSelectedRows,
  handleDuplicateSelectedRows,
  handleExportSelectedRows,
  setIsImportWizardOpen,
  isImportExcel,
}) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      mb={4}
    >
      <Flex
        alignItems="center"
        gap={4}
      >
        <SearchBar
          placeholder="Search..."
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
          onClear={() => setSearchTerm("")}
          width="300px"
        />
        <Text
          fontSize="sm"
          color="gray.500"
          fontWeight="normal"
        >
          {filteredRows.length}{" "}
          {filteredRows.length === 1 ? "result" : "results"} for this zone
        </Text>
      </Flex>
      <Flex>
        {/* {Object.keys(rowSelection).length > 0 && ( */}
        {rowSelectionModel.length > 0 && (
          <>
            <Text
              fontSize="18px"
              color="brand.500"
              fontWeight="normal"
              alignSelf="center"
              mr={4}
            >
              {/* {Object.keys(rowSelection).length} row
                                  {Object.keys(rowSelection).length === 1 ? '' : 's'} selected */}
              {rowSelectionModel.length} row
              {rowSelectionModel.length === 1 ? "" : "s"} selected
            </Text>
            <Button
              colorScheme="brand"
              variant="outline"
              fontWeight="normal"
              size="sm"
              fontSize="14px"
              leftIcon={<DeleteIcon />}
              onClick={handleDeleteSelectedRows}
              mr={2}
            >
              Delete
            </Button>
            <Button
              colorScheme="brand"
              variant="outline"
              fontWeight="normal"
              size="sm"
              fontSize="14px"
              leftIcon={<CopyIcon />}
              onClick={handleDuplicateSelectedRows}
              mr={2}
            >
              Duplicate
            </Button>
            {isImportExcel && (
              <Button
                colorScheme="brand"
                variant="outline"
                fontWeight="normal"
                size="sm"
                fontSize="14px"
                leftIcon={<ExternalLinkIcon />}
                onClick={handleExportSelectedRows}
                mr={2}
                isDisabled={rowSelectionModel.length === 0}
              >
                Export to Excel
              </Button>
            )}
          </>
        )}
      </Flex>
      {isImportExcel && (
        <Button
          leftIcon={<Icon as={FaFileExcel} />}
          onClick={() => setIsImportWizardOpen(true)}
          width={200}
          fontWeight="normal"
          colorScheme="brand"
          size="sm"
        >
          Import data from Excel
        </Button>
      )}
    </Flex>
  );
};

export default CardHeaderView;

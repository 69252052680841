import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { Zone } from '../types/zone';
import { TabData } from '../types/tab'; 

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL+'/api',
});

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const createUnauthorizedInterceptor = (navigate: NavigateFunction) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        navigate('/auth/sign-in/default');
      }
      return Promise.reject(error);
    }
  );
};

interface ZoneData {
  name: string;
  id?: string;
  description?: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  radius?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

interface RowObj {
  [key: string]: string;
}

export const zoneService = {
  createZone: async (zoneData: Omit<ZoneData, '_id'>): Promise<ZoneData> => {
    try {
      const response = await api.post('/zones', zoneData);
      return response.data;
    } catch (error) {
      console.error('Error creating zone:', error);
      throw error;
    }
  },  

  getAllZones: async (companyId: string , userType: string ,  userEmail: string) => {
  
    const response = await api.get(`/zones/${companyId}/get-company-zone/${userType}/${userEmail}`);
    return response.data;
  },

  getAllUsers: async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/users', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getSelectUsers: async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in.');
      }
      const response = await axios.get(`${API_BASE_URL}/api/company/users`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const users = response.data; 
      return users; 
    } catch (error) {
      console.error('Error fetching users and invitations:', error);
    }
  },
  

  deleteZone: async (id: string): Promise<Zone | null> => {
    try {
      const response = await api.delete(`/zones/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting zone:', error);
      throw error;
    }
  },

  saveColumnWidths: async (newColumnWidths: { [key: string]: number }, zoneId: string) => {
    try {
      if (!zoneId) {
        return;
      }

      // First get the current zone data to preserve column types
      const currentZone = await api.get(`/zones/${zoneId}`);
      const currentColumnTypes = currentZone.data.columnTypes || {};

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/zones/${zoneId}/columnWidths`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          columnWidths: newColumnWidths,
          columnTypes: currentColumnTypes // Include current column types
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to save column widths: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error saving column widths:', error);
    }
  },
  saveDataToDb: async (
    data: RowObj[],
    selectOptions: { [key: string]: string[] },
    columnOrder: string[],
    columnTypes: { [key: string]: string },
    zoneId: string,
    zoneName: string,
    columns: string[]
  ) => {
    try {
      if (!zoneId) {
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/zones/${zoneId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: data,
          selectOptions: selectOptions,
          columnOrder: columnOrder,
          name: zoneName,
          columns: columns,
          columnTypes: columnTypes,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to save data: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  },
  saveColumnOrder: async (newColumnOrder: string[], columnTypes: {[key: string]: string} ,zoneId: string) => {
    //console.log('Saving column order:', newColumnOrder);
   // console.log('Zone ID:', zoneId);
    try {
      if (!zoneId) {
       // console.error('Zone ID is missing');
        return;
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/zones/${zoneId}/columnOrder`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ columnOrder: newColumnOrder , columnTypes: columnTypes }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
       // console.error(`Failed to save column order. Status: ${response.status}, Response: ${errorText}`);
        return;
      }
  
      const responseData = await response.json();
     //console.log('Server response:', responseData);
  
     // console.log('Column order saved successfully');
    } catch (error) {
     // console.error('Error saving column order:', error);
    }
  },
   getZoneById: (id: string): Promise<any> => {
    return api.get(`/zones/${id}`);
  }, 
  /* getZoneById: async (zoneId: string) => {
    const response = await axios.get(`http://localhost:3002/api/zones/${zoneId}`);
    return response.data;
  }, */
  saveTabData: async (zoneId: string, tabs: TabData[]): Promise<void> => {
    try {
      await api.put(`/zones/${zoneId}/tabs`, { tabs });
     // console.log('Tab data saved successfully');
    } catch (error) {
     // console.error('Error saving tab data:', error);
    }
  },
  getZoneFields: async (zoneId: string) => {
    try {
      const response = await api.get(`/zones/${zoneId}/fields`);
      return response.data.fields;
    } catch (error) {
      console.error('Error fetching zone fields:', error);
      throw error;
    }
  },
  updateZoneFields: async (zoneId: string, fields: any[]) => {
    try {
      const response = await api.put(`/zones/${zoneId}/fields`, { fields });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getZoneItems: async (zoneId: string) => {
    try {
      const response = await api.get(`/zones/${zoneId}/items`);
      return response.data;
    } catch (error) {
      console.error('Error fetching zone items:', error);
      throw error;
    }
  },

  getZoneItem: async (itemId: string) => {
    try {
      const response = await api.get(`/zones/${itemId}/item`);
      return response.data;
    } catch (error) {
      console.error('Error fetching zone items:', error);
      throw error;
    }
  },

  createZoneItem: async (zoneId: string, itemData: any , companyId: string) => {
    try {
      const response = await api.post(`/zones/${zoneId}/items`, {itemData , companyId});
      return response.data;
    } catch (error) {
      console.error('Error creating zone item:', error);
      throw error;
    }
  },

  updateZoneItem: async (zoneId: string, itemId: string, itemData: any) => {
    try {
      const response = await api.put(`/zones/${zoneId}/items/${itemId}`, itemData);
      return response.data;
    } catch (error) {
      console.error('Error updating zone item:', error);
      throw error;
    }
  },

  duplicateZoneItem: async (zoneId: string, itemId: string) => {
    try {
      const response = await api.post(`/zones/${zoneId}/items/${itemId}/duplicate`);
      return response.data;
    } catch (error) {
      console.error('Error duplicating zone item:', error);
      throw error;
    }
  },

  deleteZoneItem: async (zoneId: string, itemId: string): Promise<boolean> => {
    try {
      const response = await api.delete(`/zones/${zoneId}/items/${itemId}`);
      return response.status === 200;
    } catch (error) {
      console.error('Error deleting zone item:', error);
      throw error;
    }
  },

  getZoneItemById: async (zoneId: string, itemId: string, includeAllFields: boolean = false) => {
    try {
      const response = await api.get(`/zones/${zoneId}/items/${itemId}`, {
        params: { includeAllFields }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching zone item:', error);
      throw error;
    }
  },

  renameZone: async (id: string, newName: string) => {
    try {
      const response = await api.put(`/zones/${id}/rename`, { name: newName });
      return response.data;
    } catch (error) {
      console.error('Error renaming zone:', error);
      throw error;
    }
  },

  updateZonePermissions: async (zoneId: string, availableUsers: string[]) => {
    try {
      const response = await api.put(`/zones/${zoneId}/permissions`, {
        availableUsers
      });
      return response.data;
    } catch (error) {
      console.error('Error updating zone permissions:', error);
      throw error;
    }
  },

  fetchMoreData: async (zoneId: string, skip: number, take: number) => {
    const response = await fetch(`/api/zones/${zoneId}/data?skip=${skip}&take=${take}`);
    if (!response.ok) throw new Error('Failed to fetch more data');
    return response.json();
  }
};
import { AddIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { GridSlotsComponentsProps } from "@mui/x-data-grid-pro";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    addRow: () => void;
  }
}
function CustomFooter(props: NonNullable<GridSlotsComponentsProps["footer"]>) {
  return (
    <Box sx={{ p: 1, display: "flex" }}>
      <Button
        leftIcon={<AddIcon />}
        onClick={() => props.addRow()}
        size="sm"
        variant="simple"
        color="gray.500"
        px={2.5}
        py={2}
      >
        Add Row
      </Button>
    </Box>
  );
}

export default CustomFooter;

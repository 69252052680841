import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Button,
  IconButton,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  Input,
  Text,
  Icon,
  usePopper,
  Checkbox,
  useToast,
  Image
} from '@chakra-ui/react';
import { AddIcon, EditIcon, SettingsIcon, DeleteIcon, CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons';
import { FaTrash, FaSort, FaFileExcel } from 'react-icons/fa';
import loadingGif from 'assets/img/loading.svg';
import Card from 'components/card/Card';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { DndContext, DragEndEvent, DragStartEvent, useSensor, useSensors, PointerSensor, DragOverlay } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import SortableHeader from 'components/SortableHeader';
import DraggedColumnHeader from 'components/DraggedColumnHeader';
import useTableColumns from 'hooks/useTableColumns';
import { zoneService } from 'services/zoneService';
import ImportWizard from './ImportWizard';
import FieldsEditor from 'components/FieldsEditor';
import { exportToExcel } from '../../../../services/exportService';
import { css } from '@emotion/react';
import { ErrorBoundary } from 'react-error-boundary';
import { isValid } from 'date-fns';
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import CalculationEditor from 'components/CalculationEditor';
import LabelOptionEditor from 'components/fields/LabelOptionEditor';
import { useDispatch } from 'react-redux';
import { setActiveZone } from 'store/slices/zoneSlice';
import CenteredPopover from 'components/CenteredPopover';
import { useVirtualizer } from '@tanstack/react-virtual';

interface RowObj {
  [key: string]: string;
}
interface ColumnSizing {
  [key: string]: number;
}


interface ZonePageProps {
  setCurrentZoneName: (name: string) => void;
  isEditable?: boolean,
  reportResult?: null | any
}
const DEFAULT_COLUMN_WIDTH = 150;
const DEFAULT_COLUMNS = ['Name', 'Email', 'Address', 'Company'];

/* const safeToString = (value: any): string => {
  if (value === null || value === undefined) return '';
  if (typeof value === 'object') return JSON.stringify(value);
  return String(value);
}; */

const ErrorFallback = ({ error }: { error: Error }) => {
  console.error('Error in ZonePage:', error);
  return (
    <Box p={4} color="red.500">
      <Text>An error occurred while rendering the table. Please try refreshing the page.</Text>
    </Box>
  );
};

// Add this type definition
type SelectOption = string[] | { label: string; color: string; }[] | { formula: string; fields: any[]; };

const ROWS_PER_PAGE = 50;
const BUFFER_SIZE = 10;

const ZonePage: React.FC<ZonePageProps> = ({ setCurrentZoneName, isEditable = true, reportResult = null }) => {
  const [columns, setColumns] = useState<string[]>([]);
  const [data, setData] = useState<RowObj[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [activePopover, setActivePopover] = useState<{ id: string | null; type: 'columnOptions' | 'editSelectOptions' | 'editCalculation' | 'editLabel' | null }>({ id: null, type: null });
  const [isLoading, setIsLoading] = useState(true);
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const { id: zoneId } = useParams<{ id: string }>();
  const [editingHeader, setEditingHeader] = useState<string | null>(null);
  const [selectOptions, setSelectOptions] = useState<{ [key: string]: SelectOption }>({});
  const [selectOptionsAnchorEl, setSelectOptionsAnchorEl] = useState<HTMLElement | null>(null);
  const [rowSelection, setRowSelection] = React.useState({})
  const { referenceRef } = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  });

  const [zoneName, setZoneName] = useState<string>("")
  const [columnSizing, setColumnSizing] = useState<ColumnSizing>({});
  const [columnOrder, setColumnOrder] = useState<string[]>(columns);
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(PointerSensor));
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [isImportWizardOpen, setIsImportWizardOpen] = useState(false);
  const [columnTypes, setColumnTypes] = useState<{ [key: string]: string }>({});
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isEditingLabel, setIsEditingLabel] = useState<boolean>(false);
  const [isPopoverActive, setIsPopoverActive] = useState<boolean>(false)
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectOptionsAnchorEl) {
      referenceRef(selectOptionsAnchorEl);
    }
  }, [selectOptionsAnchorEl, referenceRef]);

  const columnHelper = createColumnHelper<RowObj>();

  const saveDataToDb = useCallback(
    (newData: RowObj[], newSelectOptions: { [key: string]: SelectOption }, newColumnOrder: string[], newColumnTypes: { [key: string]: string }, currZoneName?: string, newColumns?: string[]) => {
      zoneService.saveDataToDb(
        newData,
        newSelectOptions as { [key: string]: string[] },
        newColumnOrder,
        newColumnTypes,
        zoneId as string,
        (currZoneName || zoneName),
        (newColumns || columns)
      )

    },
    [zoneId, zoneName, columns, columnTypes]
  );


  const debouncedSaveDataToDb = useMemo(
    () => debounce((newData: RowObj[], newSelectOptions: { [key: string]: SelectOption }, newColumnOrder: string[], newColumnTypes: { [key: string]: string }) => saveDataToDb(newData, newSelectOptions, newColumnOrder, newColumnTypes), 500),
    [saveDataToDb]
  );


  const freezeHeaderStyle = css`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--chakra-colors-white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

  const updateData = useCallback((rowIndex: number, columnId: string, value: any) => {
    setData((prevData) => {
      const newData = [...prevData];
      if (columnTypes[columnId] === 'date') {
        if (value && isValid(new Date(value))) {
          newData[rowIndex][columnId] = value;
        } else {
          newData[rowIndex][columnId] = null;
        }
      } else {
        newData[rowIndex][columnId] = value;
      }
      debouncedSaveDataToDb(newData, selectOptions, columnOrder, columnTypes);
      return newData;
    });
  }, [debouncedSaveDataToDb, selectOptions, columnOrder, columnTypes]);


  const renameColumn = useCallback((oldName: string, newName: string) => {
    const updates = {
      columnTypes: { ...columnTypes },
      selectOptions: { ...selectOptions },
      order: columnOrder.map(item => oldName === item ? newName : item)
    };

    if (oldName in updates.columnTypes) {
      updates.columnTypes[newName] = updates.columnTypes[oldName];
      delete updates.columnTypes[oldName];
    }

    if (oldName in updates.selectOptions) {
      updates.selectOptions[newName] = updates.selectOptions[oldName];
      delete updates.selectOptions[oldName];
    }

    setColumnOrder(updates.order);
    setColumns(updates.order);
    setSelectOptions(updates.selectOptions);
    setColumnTypes(updates.columnTypes);

    const newData = data.map(row => {
      const newRow = { ...row };
      if (oldName in newRow) {
        newRow[newName] = newRow[oldName];
        delete newRow[oldName];
      }
      return newRow;
    });

    setData(newData);
    saveDataToDb(newData, updates.selectOptions, updates.order, updates.columnTypes);
    setEditingHeader(null);
  }, [saveDataToDb, selectOptions, columnOrder, columnTypes, data]);

  const deleteColumn = useCallback((columnName: string) => {
    setColumns(prevColumns => prevColumns.filter(col => col !== columnName));
    setColumnOrder(prevOrder => prevOrder.filter(col => col !== columnName));
    setData(prevData => {
      const newData = prevData.map(row => {
        const { [columnName]: _, ...rest } = row;
        return rest;
      });

      setColumnTypes(p => {
        delete p[columnName];

        return p;
      })
      saveDataToDb(newData, selectOptions, columnOrder.filter(col => col !== columnName), { ...columnTypes, [columnName]: undefined }, zoneName, columns.filter(col => col !== columnName));
      return newData;
    });
  }, [saveDataToDb, selectOptions, columnOrder, columnTypes, zoneName, columns]);

  const saveColumnWidths = useCallback(zoneService.saveColumnWidths, [zoneId]);

  useEffect(() => {
    const fetchZoneData = async () => {
      if (!zoneId) return;
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/zones/${zoneId}`);
        if (!response.ok) throw new Error('Failed to fetch zone data');
        const zoneData = await response.json();

        if (zoneData.name) {
          console.log("zoneData.name", zoneData.name)
          setZoneName(zoneData.name);
          dispatch(setActiveZone(zoneData.name));
          setCurrentZoneName(zoneData.name);
        }

        let columnsToUse: string[] = zoneData.columns || [];
        if (columnsToUse.length === 0 && zoneData.columnOrder && zoneData.columnOrder.length > 0) {
          columnsToUse = zoneData.columnOrder;
        }
        let dataToUse: RowObj[] = zoneData.data || [];

        if (columnsToUse.length === 0 && dataToUse.length > 0) {
          columnsToUse = Object.keys(dataToUse[0]);
        }

        if (columnsToUse.length === 0) {
          columnsToUse = DEFAULT_COLUMNS;
        }
        setColumns(columnsToUse);
        setData(Array.isArray(dataToUse) ? dataToUse : []);
        setColumnOrder(zoneData.columnOrder || columnsToUse);

        if (zoneData.selectOptions) {
          setSelectOptions(zoneData.selectOptions);
        }

        if (zoneData.columnWidths) {
          setColumnSizing(zoneData.columnWidths);
        } else {
          const defaultColumnSizing = Object.fromEntries(
            columnsToUse.map(column => [column, DEFAULT_COLUMN_WIDTH])
          );
          setColumnSizing(defaultColumnSizing);
          saveColumnWidths(defaultColumnSizing, zoneId);
        }
        if (Object.keys(zoneData.columnTypes).length) {
          setColumnTypes({
            ...Object.fromEntries(
              columnsToUse.map(column => [column, 'text'])
            ), ...zoneData.columnTypes
          });
        } else {
          const defaultColumnTypes = Object.fromEntries(
            columnsToUse.map(column => [column, 'text'])
          );
          setColumnTypes(defaultColumnTypes);
          // Save the default column types to the server
          saveDataToDb(dataToUse, zoneData.selectOptions || {}, columnsToUse, defaultColumnTypes, zoneData.name);
        }
      } catch (error) {
        console.error('Error fetching zone data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reportResult) {
      setIsLoading(true);
      const [zoneData] = reportResult;

      if (zoneData.name) {
        setZoneName(zoneData.name);
        dispatch(setActiveZone(zoneData.name));
        setCurrentZoneName(zoneData.name);
      }

      let columnsToUse: string[] = zoneData.columns || [];
      if (columnsToUse.length === 0 && zoneData.columnOrder && zoneData.columnOrder.length > 0) {
        columnsToUse = zoneData.columnOrder;
      }
      let dataToUse: RowObj[] = zoneData.data || [];

      if (columnsToUse.length === 0 && dataToUse.length > 0) {
        columnsToUse = Object.keys(dataToUse[0]);
      }

      if (columnsToUse.length === 0) {
        columnsToUse = DEFAULT_COLUMNS;
      }
      setColumns(columnsToUse);
      setData(Array.isArray(dataToUse) ? dataToUse : []);
      setColumnOrder(zoneData.columnOrder || columnsToUse);

      if (zoneData.selectOptions) {
        setSelectOptions(zoneData.selectOptions);
      }

      if (zoneData.columnWidths) {
        setColumnSizing(zoneData.columnWidths);
      } else {
        const defaultColumnSizing = Object.fromEntries(
          columnsToUse.map(column => [column, DEFAULT_COLUMN_WIDTH])
        );
        setColumnSizing(defaultColumnSizing);
        saveColumnWidths(defaultColumnSizing, zoneId);
      }

      if (Object.keys(zoneData.columnTypes || {}).length) {
        setColumnTypes({
          ...Object.fromEntries(
            columnsToUse.map(column => [column, 'text'])
          ), ...zoneData.columnTypes
        });
      } else {
        const defaultColumnTypes = Object.fromEntries(
          columnsToUse.map(column => [column, 'text'])
        );

        setColumnTypes(defaultColumnTypes);
        // Save the default column types to the server
        saveDataToDb(dataToUse, zoneData.selectOptions || {}, columnsToUse, defaultColumnTypes, zoneData.name);
      }
      setIsLoading(false);
    } else {
      fetchZoneData();
    }
  }, [zoneId, setCurrentZoneName, saveColumnWidths, reportResult]);

  const addColumn = useCallback((type: string) => {
    const newColumnName = `${type} ${columns.length + 1}`;
    setColumns(prevColumns => [...prevColumns, newColumnName]);
    setColumnOrder(prevOrder => [...prevOrder, newColumnName]);
    setColumnTypes(prevTypes => ({ ...prevTypes, [newColumnName]: type.toLowerCase() }));
    setData(prevData => {
      const newData = prevData.map(row => ({
        ...row,
        [newColumnName]: type === 'Select' ? 'Option 1' :
          type === 'Date' ? '' :
            type === 'Number' ? '' :
              type === 'Calculation' ? '' : ''
      }));

      saveDataToDb(newData, selectOptions, [...columnOrder, newColumnName], { ...columnTypes, [newColumnName]: type.toLowerCase() }, undefined, [...columns, newColumnName]);
      return newData;
    });
    if (type === 'Select') {
      setSelectOptions(prev => ({
        ...prev,
        [newColumnName]: ['Option 1', 'Option 2', 'Option 3']
      }));
    }
    if (type === 'Label') {
      setSelectOptions(prev => ({
        ...prev,
        [newColumnName]: [{ label: 'Option 1', color: '#000000' }]
      }));
    }
    if (type === 'Calculation') {
      setSelectOptions(prev => ({
        ...prev,
        [newColumnName]: { formula: '', fields: [] }
      }));
    }
  }, [columns, columnOrder, saveDataToDb, selectOptions, columnTypes, zoneName]);

  const manageSelectOptions = useCallback((columnId: string, options: string[]) => {
    setSelectOptions(prev => {
      const newSelectOptions = { ...prev, [columnId]: options };
      setData(prevData => {
        const newData = prevData.map(row => ({
          ...row,
          [columnId]: options.includes(row[columnId]) ? row[columnId] : options[0] || ''
        }));
        saveDataToDb(newData, newSelectOptions, columnOrder, columnTypes);
        return newData;
      });
      return newSelectOptions;
    });
  }, [saveDataToDb, columnOrder, columnTypes]);

  const saveColumnOrder = useCallback((newOrder: string[], columnTypes: { [key: string]: string }, zoneId: string) => {
    if (!zoneId) return;
    zoneService.saveColumnOrder(newOrder, columnTypes, zoneId);
  }, []);

  const deleteSelectedRows = () => {
    const selectedCount = Object.keys(table.getState().rowSelection).length;
    const newData = data.filter((_, index) => !table.getState().rowSelection[index]);
    setData(newData);
    saveDataToDb(newData, selectOptions, columnOrder, columnTypes);
    setRowSelection({});
    toast({
      title: "Rows Deleted",
      description: `${selectedCount} row(s) have been deleted`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const duplicateSelectedRows = () => {
    const newData = [...data];
    const selectedIndices = Object.keys(table.getState().rowSelection)
      .map(Number)
      .sort((a, b) => a - b);

    let offset = 0;
    selectedIndices.forEach((index) => {
      const adjustedIndex = index + offset;
      const rowToDuplicate = newData[adjustedIndex];
      newData.splice(adjustedIndex + 1, 0, { ...rowToDuplicate });
      offset++;
    });

    setData(newData);
    saveDataToDb(newData, selectOptions, columnOrder, columnTypes);
    setRowSelection({});
    toast({
      title: "Rows Duplicated",
      description: `${selectedIndices.length} row(s) have been duplicated`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const exportSelectedRows = () => {
    const selectedData = data.filter((_, index) => table.getState().rowSelection[index]);
    if (selectedData.length > 0) {
      const fileName = `${zoneName}_export_${new Date().toISOString()}.xlsx`;
      exportToExcel(selectedData, fileName);
      toast({
        title: "Export Successful",
        description: `${selectedData.length} rows exported to ${fileName}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Export Failed",
        description: "No rows selected for export",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const selectionColumn = columnHelper.display({
    id: 'selection',
    header: ({ table }) => (
      <Flex justify="center" align="center" height="100%">
        <Checkbox
          colorScheme="orange"
          isChecked={table.getIsAllRowsSelected()}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flex>
    ),
    cell: ({ row }) => (
      <Flex justify="center" align="center" height="100%">
        <Checkbox
          colorScheme="orange"
          isChecked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </Flex>
    ),
    enableSorting: false,
    enableResizing: false,
    size: 40,
    minSize: 40,
    maxSize: 40,
  });

  const tableColumns = useTableColumns({
    columns,
    columnHelper,
    borderColor,
    hoveredColumn,
    setHoveredColumn,
    editingHeader,
    renameColumn,
    activePopover,
    setActivePopover: setActivePopover as React.Dispatch<React.SetStateAction<{ id: string | null; type: 'columnOptions' | 'editSelectOptions' | 'editCalculation' | null }>>,
    deleteColumn,
    setEditingHeader,
    selectOptions: selectOptions as Record<string, string[]>,
    manageSelectOptions,
    updateData,
    columnSizing,
    addColumn,
    columnTypes,
  })

  const addRow = () => {
    const container = containerRef.current;
    const scrollPosition = container?.scrollTop;

    setData(prevData => {
      const newRow = columns.reduce((acc, column) => ({ ...acc, [column]: '' }), {});
      const newData = [...prevData, newRow];
      saveDataToDb(newData, selectOptions, columnOrder, columnTypes);

      // Restore scroll position after state update
      requestAnimationFrame(() => {
        if (container && scrollPosition) {
          container.scrollTop = scrollPosition;
        }
      });

      return newData;
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(String(active.id));
      const newIndex = columnOrder.indexOf(String(over.id));

      if (oldIndex !== -1 && newIndex !== -1) {
        const newOrder = [...columnOrder];
        newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, String(active.id));

        setData(prevData => {
          const newData = prevData.map(row => {
            const newRow: { [key: string]: any } = {};
            newOrder.forEach(columnId => {
              newRow[columnId] = row[columnId];
            });
            return newRow;
          });
          return newData;
        });

        setColumnOrder(newOrder);
        setColumns(newOrder);
        saveColumnOrder(newOrder, columnTypes, zoneId);
      }
    }
    setActiveId(null);
  };

  const filteredData = useMemo(() => {
    if (!searchTerm || !Array.isArray(data)) return data;

    const searchTerms = searchTerm.toLowerCase().split(' ').filter(Boolean);
    if (searchTerms.length === 0) return data;

    return data.filter((row) => {
      return Object.values(row).some(value =>
        value && searchTerms.every(term =>
          String(value).toLowerCase().includes(term)
        )
      );
    });
  }, [data, searchTerm]);

  const memoizedColumns = useMemo(
    () => [selectionColumn, ...tableColumns.regularColumns, tableColumns.addColumnHeader],
    [selectionColumn, tableColumns.regularColumns, tableColumns.addColumnHeader]
  );

  const virtualizer = useVirtualizer({
    count: filteredData.length,
    getScrollElement: () => containerRef.current,
    estimateSize: React.useCallback(() => 39, []),
    overscan: 5,
    paddingStart: 0,
    paddingEnd: 0,
  });

  useEffect(() => {
    virtualizer.scrollToIndex(0);
    virtualizer.measure();
  }, [filteredData.length, searchTerm, virtualizer]);

  const debouncedSaveColumnWidths = useMemo(() =>
    debounce((newColumnSizing: Record<string, number>, zoneId: string) => {
      saveColumnWidths(newColumnSizing, zoneId);
    }, 50), [saveColumnWidths]
  );

  const table = useReactTable({
    data: filteredData,
    columns: memoizedColumns,
    state: {
      sorting,
      columnSizing,
      columnOrder: ['selection', ...columnOrder],
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnSizingChange: (updatedSizing) => {
      const newSizing = typeof updatedSizing === 'function'
        ? updatedSizing(columnSizing)
        : updatedSizing;

      setColumnSizing(newSizing);
      requestAnimationFrame(() => {
        debouncedSaveColumnWidths(newSizing, zoneId);
      });
    },
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    debugTable: true,
    defaultColumn: {
      minSize: 30,
      size: DEFAULT_COLUMN_WIDTH,
      maxSize: 1000,
    },
    enableColumnResizing: true,
  });

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Image src={loadingGif} alt="Loading..." />
      </Flex>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box pt={{ base: reportResult ? '20px' : '130px', md: reportResult ? '20px' : '80px', xl: reportResult ? '20px' : '80px' }} height="calc(100vh - 50px)">
        <Card height="100%" display="flex" flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Flex alignItems="center" gap={4}>
              <SearchBar
                placeholder="Search..."
                value={searchTerm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                onClear={() => setSearchTerm('')}
                width="300px"
              />
              <Text
                fontSize='sm'
                color='gray.500'
                fontWeight='normal'
              >
                {filteredData.length} {filteredData.length === 1 ? 'result' : 'results'} for this zone
              </Text>
            </Flex>
            <Flex>
              {Object.keys(rowSelection).length > 0 && (
                <>
                  <Text
                    fontSize="18px"
                    color="brand.500"
                    fontWeight="normal"
                    alignSelf="center"
                    mr={4}
                  >
                    {Object.keys(rowSelection).length} row{Object.keys(rowSelection).length === 1 ? '' : 's'} selected
                  </Text>
                  <Button
                    colorScheme="brand"
                    variant="outline"
                    fontWeight="normal"
                    fontSize="14px"
                    leftIcon={<DeleteIcon />}
                    onClick={deleteSelectedRows}
                    mr={2}
                  >
                    Delete
                  </Button>
                  <Button
                    colorScheme="brand"
                    variant="outline"
                    fontWeight="normal"
                    fontSize="14px"
                    leftIcon={<CopyIcon />}
                    onClick={duplicateSelectedRows}
                    mr={2}
                  >
                    Duplicate
                  </Button>
                  <Button
                    colorScheme="brand"
                    variant="outline"
                    fontWeight="normal"
                    fontSize="14px"
                    leftIcon={<ExternalLinkIcon />}
                    onClick={exportSelectedRows}
                    mr={2}
                    isDisabled={Object.keys(rowSelection).length === 0}
                  >
                    Export to Excel
                  </Button>
                </>
              )}
            </Flex>
            <Button
              leftIcon={<Icon as={FaFileExcel} />}
              onClick={() => setIsImportWizardOpen(true)}
              width={200}
              fontWeight="normal"
              colorScheme="brand"
              size="sm"
            >
              Import data from Excel
            </Button>
          </Flex>
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Box ref={containerRef} overflow="auto" flexGrow={1}>
              <Table variant="simple" color="gray.500" mb="24px" layout="fixed" className="zoneTable" style={{ width: table.getTotalSize() }}>
                <Thead css={freezeHeaderStyle}>
                  <Tr>
                    <Th width="40px"
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                      padding="3px"
                      backgroundColor="white">
                      {flexRender(table.getHeaderGroups()[0].headers[0].column.columnDef.header,
                        table.getHeaderGroups()[0].headers[0].getContext())}
                    </Th>
                    <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                      {table.getHeaderGroups()[0].headers.slice(1, -1).map((header) => {
                        return (
                          <SortableHeader
                            key={header.id}
                            header={header}
                            borderColor={borderColor}
                            isHovered={hoveredColumn === header.id}
                            onMouseEnter={() => setHoveredColumn(header.id)}
                            onMouseLeave={() => setHoveredColumn(null)}
                          >
                            <Flex align="center" justify="space-between" width="100%" height="100%">
                              {editingHeader === header.id ? (
                                <Input
                                  size="sm"
                                  defaultValue={header.id}
                                  onBlur={(e) => renameColumn(header.id, e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      renameColumn(header.id, e.currentTarget.value);
                                    }
                                  }}
                                  autoFocus
                                  id={`input-id-${Math.random() * 9989}`}
                                />
                              ) : (
                                <>
                                  <span>{header.id}</span>
                                  <Popover
                                    isOpen={activePopover.type === 'columnOptions' && activePopover.id === header.id}
                                    onClose={() => {
                                      setActivePopover({ id: null, type: null })
                                      setIsPopoverActive(false);
                                    }}
                                    placement="bottom"
                                    strategy="fixed"
                                    gutter={5}
                                  >
                                    <PopoverTrigger>
                                      <IconButton
                                        aria-label="Column options"
                                        icon={<Icon as={MenuDots} boxSize={6} />}
                                        size="sm"
                                        variant="ghost"
                                        colorScheme="gray.500"
                                        ml="auto"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setActivePopover(prev =>
                                            prev.id === header.id && prev.type === 'columnOptions'
                                              ? { id: null, type: null }
                                              : { id: header.id, type: 'columnOptions' }
                                          );
                                          setIsPopoverActive(true);
                                        }}
                                        _hover={{
                                          bg: "gray.200"
                                        }}
                                        opacity={hoveredColumn === header.id ? 1 : 0}
                                        transition="opacity 0.2s"
                                      />
                                    </PopoverTrigger>
                                    <PopoverContent
                                      width="200px"
                                      borderRadius="16px"
                                      boxShadow="0px 3px 27px -20px rgba(112, 144, 176, 0.51)"
                                      zIndex={1500}
                                      position="relative"
                                      mt={2}
                                    >
                                      <PopoverBody>
                                        <VStack align="stretch" spacing={0}>
                                          <Button
                                            leftIcon={<Icon as={FaSort} color="orange.500" />}
                                            size="sm"
                                            variant="ghost"
                                            justifyContent="flex-start"
                                            onClick={() => header.column.toggleSorting()}
                                            borderRadius="0"
                                            _hover={{
                                              bg: "gray.200"
                                            }}
                                          >
                                            Sort
                                          </Button>
                                          <Button
                                            leftIcon={<Icon as={EditIcon} color="orange.500" />}
                                            size="sm"
                                            variant="ghost"
                                            justifyContent="flex-start"
                                            onClick={() => setEditingHeader(header.id)}
                                            borderRadius="0"
                                            _hover={{
                                              bg: "gray.200"
                                            }}
                                          >
                                            Rename
                                          </Button>
                                          <Button
                                            leftIcon={<Icon as={FaTrash} color="orange.500" />}
                                            size="sm"
                                            variant="ghost"
                                            justifyContent="flex-start"
                                            onClick={() => deleteColumn(header.id)}
                                            borderRadius="0"
                                            _hover={{
                                              bg: "gray.200"
                                            }}
                                          >
                                            Delete
                                          </Button>
                                          {columnTypes?.[header.id] === "select" && (
                                            <Button
                                              leftIcon={<Icon as={SettingsIcon} color="orange.500" />}
                                              size="sm"
                                              variant="ghost"
                                              justifyContent="flex-start"
                                              onClick={() => {
                                                setActivePopover({ id: header.id, type: 'editSelectOptions' });
                                              }}
                                              borderRadius="0"
                                              _hover={{
                                                bg: "gray.200"
                                              }}
                                            >
                                              Edit Options
                                            </Button>
                                          )}
                                          {columnTypes?.[header.id] === "label" && (
                                            <Button
                                              leftIcon={<Icon as={SettingsIcon} color="orange.500" />}
                                              size="sm"
                                              variant="ghost"
                                              justifyContent="flex-start"
                                              onClick={() => {
                                                setActivePopover({ id: header.id, type: "editLabel" });
                                                setIsEditingLabel(true)
                                              }}
                                              borderRadius="0"
                                              _hover={{
                                                bg: "gray.200"
                                              }}
                                            >
                                              Edit Options
                                            </Button>
                                          )}
                                          {columnTypes?.[header.id] === 'calculation' && (
                                            <Button
                                              leftIcon={<Icon as={SettingsIcon} color="orange.500" />}
                                              size="sm"
                                              variant="ghost"
                                              justifyContent="flex-start"
                                              onClick={() => {
                                                setActivePopover({ id: header.id, type: 'editCalculation' });
                                              }}
                                              borderRadius="0"
                                              _hover={{
                                                bg: "gray.200"
                                              }}
                                            >
                                              Set Calculation
                                            </Button>
                                          )}
                                        </VStack>
                                      </PopoverBody>
                                    </PopoverContent>
                                  </Popover>

                                  <CenteredPopover
                                    isOpen={activePopover.type === 'editSelectOptions' && activePopover.id === header.id}
                                    onClose={() => setActivePopover({ id: null, type: null })}
                                    title="Edit Select Options"
                                  >
                                    <VStack align="stretch" spacing={3}>
                                      <FieldsEditor
                                        field={(() => {
                                          const options = selectOptions[header.id];
                                          if (Array.isArray(options)) {
                                            return options.join('\n');
                                          } else if (typeof options === 'object' && options !== null) {
                                            if ('fields' in options && Array.isArray(options.fields)) {
                                              return options.fields.join('\n');
                                            } else if ('formula' in options) {
                                              return options.formula || '';
                                            }
                                          }
                                          return '';
                                        })()}
                                        updateFieldOptionsTable={manageSelectOptions}
                                        valId={header.id}
                                        size="sm"
                                        id={`text-area-${Math.random() * 9999}`}
                                        type='table'
                                      />
                                      <Button
                                        onClick={() => setActivePopover({ id: null, type: null })}
                                        size="sm"
                                        variant="brand"
                                        fontWeight="normal"
                                      >
                                        Save and close
                                      </Button>
                                    </VStack>
                                  </CenteredPopover>

                                  <CenteredPopover
                                    isOpen={activePopover.type === 'editCalculation' && activePopover.id === header.id}
                                    onClose={() => setActivePopover({ id: null, type: null })}
                                    title="Set Calculation"
                                  >
                                    <CalculationEditor
                                      field={{ id: header.id, options: columnTypes[header.id] === 'calculation' ? (selectOptions[header.id] as any) || { formula: '', fields: [] } : { formula: '', fields: [] } }}
                                      allFields={columns.map(col => ({ id: col, label: col }))}
                                      updateFieldOptions={(_, __, newOptions) => {
                                        setSelectOptions(prev => ({
                                          ...prev,
                                          [header.id]: newOptions
                                        }));
                                        setColumnTypes(prev => ({
                                          ...prev,
                                          [header.id]: 'calculation'
                                        }));
                                        saveDataToDb(data, { ...selectOptions, [header.id]: newOptions }, columnOrder, { ...columnTypes, [header.id]: 'calculation' });
                                      }}
                                      side="left"
                                      onClose={() => setActivePopover({ id: null, type: null })}
                                    />
                                  </CenteredPopover>
                                  <CenteredPopover
                                    isOpen={isEditingLabel && activePopover.id === header.id}
                                    onClose={() => setIsEditingLabel(false)}
                                    title="Edit Label Options"
                                  >
                                    <LabelOptionEditor
                                      options={selectOptions[activePopover.id] as unknown as { label: string; color: string }[]}
                                      onChange={(options: any) => {
                                        setSelectOptions(prev => {
                                          const newSelectOptions = { ...prev, [header.id]: options };
                                          setData(prevData => {
                                            const newData = prevData.map(row => ({
                                              ...row,
                                              [header.id]: options.includes(row[header.id]) ? row[header.id] : options[0] || ''
                                            }));
                                            saveDataToDb(newData, newSelectOptions, columnOrder, columnTypes);
                                            return newData;
                                          });
                                          return newSelectOptions;
                                        });
                                      }}
                                    />
                                    <Button onClick={() => setIsEditingLabel(false)} size="sm" mt={4}>
                                      Close
                                    </Button>
                                  </CenteredPopover>
                                </>
                              )}
                            </Flex>
                            <Box
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                              position="absolute"
                              right={0}
                              top={0}
                              height="100%"
                              width="5px"
                              cursor="col-resize"
                              userSelect="none"
                              sx={{
                                touchAction: "none"
                              }}
                              _hover={{
                                backgroundColor: "gray.200"
                              }}
                            />
                          </SortableHeader>
                        )
                      })}
                    </SortableContext>
                    {flexRender(tableColumns.addColumnHeader.header, table.getHeaderGroups()[0].headers.slice(-1)[0].getContext())}
                  </Tr>
                </Thead>
                <Tbody>
                  <tr style={{ height: `${virtualizer.getVirtualItems()[0]?.start || 0}px` }} />
                  {virtualizer.getVirtualItems().map(virtualRow => {
                    const row = table.getRowModel().rows[virtualRow.index];
                    if (!row) return null;

                    return (
                      <Tr
                        key={`row-${row.id}-${virtualRow.index}-${searchTerm}`}
                        className="table-row-fade"
                      >
                        {row.getVisibleCells().map(cell => (
                          <Td
                            key={`cell-${cell.id}-${searchTerm}`}
                            borderColor={borderColor}
                            borderWidth="1px"
                            borderStyle="solid"
                            padding="3px 6px"
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Td>
                        ))}
                      </Tr>
                    );
                  })}
                  <tr style={{
                    height: `${virtualizer.getTotalSize() - (virtualizer.getVirtualItems()[virtualizer.getVirtualItems().length - 1]?.end || 0)}px`
                  }} />
                  <Tr>
                    <Td colSpan={columns.length + 2} borderWidth="1px" borderColor={borderColor} borderStyle="solid" padding="3px">
                      <Button leftIcon={<AddIcon />} onClick={addRow} size="sm" variant="simple" color="gray.500">
                        Add Row
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <DragOverlay>
              {activeId ? (
                <DraggedColumnHeader id={activeId} borderColor={borderColor} />
              ) : null}
            </DragOverlay>
          </DndContext>
          <ImportWizard
            isOpen={isImportWizardOpen}
            onClose={() => setIsImportWizardOpen(false)}
            columns={columns}
            onImport={(importedData, newColumns) => {
              setData((prevData) => prevData.filter((row) => Object.values(row).some((value) => value !== '')));

              // Update columns and column order
              setColumns((prevColumns) => {
                const updatedColumns = [...new Set([...prevColumns, ...newColumns.map(col => col.name)])];
                return updatedColumns;
              });
              setColumnOrder((prevOrder) => {
                const updatedOrder = [...new Set([...prevOrder, ...newColumns.map(col => col.name)])];
                return updatedOrder;
              });


              // Update the table data with the imported data
              setData((prevData) => {
                const updatedData = [...prevData, ...importedData.map(row => {
                  const newRow = { ...row };
                  Object.keys(newRow).forEach(key => {
                    if (columnTypes[key] === 'date' && typeof newRow[key] === 'string') {
                      const [day, month, year] = newRow[key].split('/');
                      if (day && month && year) {
                        // Create a date string in ISO format (YYYY-MM-DD)
                        const isoDateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                        newRow[key] = isoDateString;
                      } else {
                        console.warn(`Invalid date format for ${key}: ${newRow[key]}`);
                        newRow[key] = null; // Set to null if the date is invalid
                      }
                    }
                  });
                  return newRow;
                })];
                console.log('Updated data after processing:', updatedData);
                return updatedData;
              });


              // Update column types and select options
              const updatedSelectOptions = { ...selectOptions };
              const updatedColumnTypes = { ...columnTypes };
              newColumns.forEach((col) => {
                updatedColumnTypes[col.name] = col.type.toLowerCase();
                if (col.type.toLowerCase() === 'select') {
                  updatedSelectOptions[col.name] = Array.from(new Set(importedData.map(row => row[col.name])));
                }
              });
              setSelectOptions(updatedSelectOptions);
              setColumnTypes(updatedColumnTypes);

              // Save the updated data to the database
              const updatedData = [...data, ...importedData];
              const updatedColumns = [...new Set([...columns, ...newColumns.map(col => col.name)])];
              const updatedColumnOrder = [...new Set([...columnOrder, ...newColumns.map(col => col.name)])];
              saveDataToDb(updatedData, updatedSelectOptions, updatedColumnOrder, updatedColumnTypes, undefined, updatedColumns);
            }}
          />
        </Card>
      </Box>
    </ErrorBoundary>
  );
};



export default ZonePage;
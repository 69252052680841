import React from "react";
import { TabData } from "types/zone";
import ZoneDataGrid from "./ZoneDataGrid";
import { generateIncrementalId, removeDuplicates } from "utils/helpers";
import { CalculationOption, ColumnOrder, ColumnNames, ColumnTypes, LabelOption, MultiSelectOption } from "types/zone-datagrid";
import { debounce } from "lodash";
import { isValid } from "date-fns";

interface TabTableContentSectionProps {
  zoneId: string;
  tabData: TabData;
  updateSingleTab: (updatedTab: TabData) => void;
  currentTabId: string;
  setCurrentTabId: (tabId: string) => void;
  itemData: any;
  setItemData: React.Dispatch<React.SetStateAction<any>>;
  isEditable: boolean;
  updateItemDataWithParams?: (params: Record<string, any>) => Promise<boolean>;
}

const TabTableContentSection: React.FC<TabTableContentSectionProps> =
  React.memo(
    ({
      zoneId,
      tabData,
      updateSingleTab,
      currentTabId,
      setCurrentTabId,
      itemData,
      setItemData,
      isEditable,
      updateItemDataWithParams,
    }) => {
      // useEffect(() => {
      //   console.log(
      //     'zoneId, currentTabId, setCurrentTabId, itemData, setItemData',
      //     zoneId,
      //     currentTabId,
      //     setCurrentTabId,
      //     itemData,
      //     setItemData
      //   );
      // }, [zoneId, currentTabId, setCurrentTabId, itemData, setItemData]);

      const getValuePlaceholder = React.useCallback((type: string) => {
        switch (type) {
          case "select":
          case "label": {
            return "Please select";
          }
          default: {
            return `Enter ${type.toLowerCase()}`;
          }
        }
      }, []);

      //* columns
      const {
        columns,
        columnNames,
        columnOrder,
        columnTypes,
        columnSizing,
        selectOptions,
      } = React.useMemo(
        () => ({
          columns: tabData.table?.columns || [],
          columnNames: tabData.table?.columnNames || {},
          columnOrder: tabData.table?.columnOrder || [],
          columnTypes: tabData.table?.columnTypes || {},
          columnSizing: tabData.table?.columnWidths || {},
          selectOptions: tabData.table?.selectOptions || {},
        }),
        [tabData],
      );

      //* rows
      const rows: Array<{
        [key: string]: any;
      }> = React.useMemo(() => {
        if (isEditable) {
          const newRow = columns.reduce((acc: any, column) => {
            acc[column] = getValuePlaceholder(columnTypes[column]);
            return acc;
          }, {});
          return [newRow];
        } else {
          return itemData[currentTabId]?.value || [];
        }
      }, [
        columnTypes,
        columns,
        currentTabId,
        getValuePlaceholder,
        isEditable,
        itemData,
      ]);
      const handleUpdateRows = React.useCallback(
        (newRows: any[]) => {
          updateItemDataWithParams?.({
            [currentTabId]: {
              ...itemData[currentTabId],
              value: newRows,
            },
          });
        },
        [currentTabId, itemData, updateItemDataWithParams],
      );
      const deleteSelectedRows = React.useCallback(
        (newData: any[]) => {
          handleUpdateRows(newData);
        },
        [handleUpdateRows],
      );
      const duplicateSelectedRows = React.useCallback(
        (newData: any[]) => {
          console.log(newData);
          handleUpdateRows(newData);
        },
        [handleUpdateRows],
      );

      const addRow = React.useCallback(() => {
        const newRow = columns.reduce(
          (acc, column) => ({ ...acc, [column]: "" }),
          {},
        );
        const newRows = [...rows, newRow];
        // return console.log(currentTabId, newRows);
        handleUpdateRows(newRows);
      }, [columns, rows, handleUpdateRows]);

      const updateData = React.useCallback(
        (rowIndex: number, columnId: string, value: any) => {
          console.log("updateData");
          const newData = [...rows];
          if (columnTypes[columnId] === "date") {
            if (value && isValid(new Date(value))) {
              newData[rowIndex][columnId] = value;
            } else {
              newData[rowIndex][columnId] = null;
            }
          } else {
            newData[rowIndex][columnId] = value;
          }
          handleUpdateRows(newData);
        },
        [columnTypes, handleUpdateRows, rows],
      );

      //* handlers
      //* update params
      const handleUpdateTabWithParams = React.useCallback(
        (params: Partial<TabData>) => {
          const updatedTab = {
            ...tabData,
            ...params,
          };
          updateSingleTab(updatedTab);
        },
        [tabData, updateSingleTab],
      );

      const debouncedUpdateTabWithParams = React.useMemo(
        () =>
          debounce((params: Partial<TabData>) => {
            handleUpdateTabWithParams(params);
          }, 500),
        [handleUpdateTabWithParams],
      );
      const handleUpdateTableParams = React.useCallback(
        (params: Partial<TabData["table"]>) => {
          debouncedUpdateTabWithParams({
            table: {
              ...tabData.table,
              ...params,
            },
          });
        },
        [debouncedUpdateTabWithParams, tabData.table],
      );

      //* manage columns
      const addColumn = React.useCallback(
        (type: string) => {
          // const newColumnId = generateIncrementalId(
          //   columns.map((col) => ({ id: parseInt(col) })),
          // ).toString();
          const newColumnId = crypto.randomUUID();
          const newColumnName = `${type} ${columns.length + 1}`;

          // Batch state updates
          const updates = {
            // columns: removeDuplicates([...columns, newColumnName]),
            // columnOrder: removeDuplicates([...columnOrder, newColumnName]),
            columns: [...columns, newColumnId],
            columnOrder: [...columnOrder, newColumnId],
            columnNames: { ...columnNames, [newColumnId]: newColumnName },
            columnTypes: {
              ...columnTypes,
              [newColumnId]: type.toLowerCase() as "text" | "label" | "select" | "number" | "date" | "calculation",
            },
          };

          // If it's a label column, prepare the select options
          const newSelectOptions =
            type === "Label"
              ? {
                  ...selectOptions,
                  [newColumnId]: [{ label: "Option 1", color: "#dbdbdb" }],
                }
              : selectOptions;

          handleUpdateTableParams({
            ...tabData.table,
            columns: updates.columns,
            columnNames: updates.columnNames,
            columnOrder: updates.columnOrder,
            columnTypes: updates.columnTypes,
            selectOptions: newSelectOptions,
          });
        },
        [
          columns,
          columnOrder,
          columnNames,
          columnTypes,
          selectOptions,
          handleUpdateTableParams,
          tabData.table,
        ],
      );
      const renameColumn = React.useCallback(
        (columnId: string, newName: string) => {
          const newColNames = { ...columnNames, [columnId]: newName };
          handleUpdateTableParams({
            columnNames: newColNames,
          });
        },
        [columnNames, handleUpdateTableParams],
      );
      const saveColumnOrder = React.useCallback(
        (newOrder: string[]) => {
          const newColumnOrder = newOrder;
          handleUpdateTableParams({
            columnOrder: newColumnOrder,
          });
        },
        [handleUpdateTableParams],
      );
      const resizeColumn = React.useCallback(
        (columnId: string, width: number) => {
          const newColumnSizing = { ...columnSizing, [columnId]: width };
          handleUpdateTableParams({
            columnWidths: newColumnSizing,
          });
        },
        [columnSizing, handleUpdateTableParams],
      );
      const deleteColumn = React.useCallback(
        (columnName: string) => {
          const newColumns = columns.filter((col) => col !== columnName);
          const newColumnOrder = columnOrder.filter(
            (col) => col !== columnName,
          );
          const newColumnNames = { ...columnNames };
          delete newColumnNames[columnName];
          const newColumnTypes = { ...columnTypes };
          delete newColumnTypes[columnName];
          const newColumnSizing = { ...columnSizing };
          delete newColumnSizing[columnName];
          const newSelectOptions = { ...selectOptions };
          delete newSelectOptions[columnName];

          handleUpdateTableParams({
            columns: newColumns,
            columnOrder: newColumnOrder,
            columnNames: newColumnNames,
            columnTypes: newColumnTypes,
            columnWidths: newColumnSizing,
            selectOptions: newSelectOptions,
          });
        },
        [
          columnNames,
          columnOrder,
          columnSizing,
          columnTypes,
          columns,
          handleUpdateTableParams,
          selectOptions,
        ],
      );

      const manageSelectOptions = React.useCallback(
        (columnId: string, options: string[] | MultiSelectOption) => {
          const newSelectOptions = { ...selectOptions, [columnId]: options };
          handleUpdateTableParams({
            selectOptions: newSelectOptions,
          });
        },
        [selectOptions, handleUpdateTableParams],
      );
      const manageLabelOptions = React.useCallback(
        (columnId: string, options: LabelOption[]) => {
          const newSelectOptions = { ...selectOptions, [columnId]: options };
          handleUpdateTableParams({
            selectOptions: newSelectOptions,
          });
        },
        [selectOptions, handleUpdateTableParams],
      );
      const updateCalculationOptions = React.useCallback(
        (columnId: string, options: CalculationOption) => {
          const newSelectOptions = { ...selectOptions, [columnId]: options };
          const newColumnTypes = { ...columnTypes, [columnId]: "calculation" as "calculation" };
          handleUpdateTableParams({
            selectOptions: newSelectOptions,
            columnTypes: newColumnTypes,
          });
        },
        [columnTypes, handleUpdateTableParams, selectOptions],
      );

      return (
        <React.Fragment>
          <ZoneDataGrid
            zoneId={zoneId}
            reportResult={true}
            rows={rows}
            columns={columns}
            columnNames={columnNames}
            columnTypes={columnTypes}
            columnOrder={columnOrder}
            columnWidths={columnSizing}
            selectOptions={selectOptions}
            onRenameColumn={renameColumn}
            onProcessRowUpdateData={updateData}
            onColumnOrderChange={(newOrder) => saveColumnOrder(newOrder)}
            onColumnResize={resizeColumn}
            onAddColumn={addColumn}
            onRowAdd={addRow}
            onDeleteColumn={deleteColumn}
            manageSelectOptions={manageSelectOptions}
            manageLabelsOptions={manageLabelOptions}
            onUpdateCalculationOptions={updateCalculationOptions}
            // onImportData={handleImportData}
            onDeleteSelectedRows={deleteSelectedRows}
            onDuplicateSelectedRows={duplicateSelectedRows}
            // onExportSelectedRows={exportSelectedRows}
            isAddNewRow={!isEditable}
            isAddNewColumn={isEditable}
            columnsEditable={!isEditable}
            isImportExcel={false}
          />
        </React.Fragment>
      );
    },
  );

export default TabTableContentSection;

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../../../../contexts/AuthContext';
import { Box, Flex, SimpleGrid, Text, Button } from '@chakra-ui/react';
import Information from './components/Information';
import Password from './components/Password';
import Profile from './components/Profile';
import { useAxiosWithAuth } from '../../../../../hooks/useAxiosWithAuth';
import axios from 'axios';

interface UserProfile {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
}

export default function Settings() {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const axiosInstance = useAxiosWithAuth();
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchUserProfile = async () => {
        setIsLoading(true);
        setError(null)
        // abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        try {
          const response = await axiosInstance.get('api/users/profile', {
            signal: abortControllerRef.current.signal
          });
          setUserProfile(response.data.user);
        } catch (error) {
          if (axios.isCancel(error)) {
            //console.log('Request canceled:', error.message);
          } else {
            console.error('Error fetching user profile:', error);
            setError('Failed to fetch user profile. Server error occurred. Please try again later.');
          }
        } finally {
          setIsLoading(false);
        }
      };

      fetchUserProfile();
    }

    return () => {
    //  abortControllerRef.current?.abort();
    };
  }, [isAuthenticated]);

  if (error) {
    return <Box pt={{ base: '130px', md: '80px', xl: '80px' }}><Box>{error}</Box></Box>
  }

  if (isLoading) {
    return <Box>Loading user profile...</Box>;
  }

  return (
    <Box 
      //pt={{ base: '130px', md: '80px', xl: '80px' }}
      mt={{ base: '130px', md: '80px', xl: '80px' }}
      height="calc(100vh - 80px)"
      overflowY="auto"
    >
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        <Flex direction='column'>
          <Profile userProfile={userProfile} />
          <Information userProfile={userProfile} />
        </Flex>
        <Flex direction='column'>
          <Password userProfile={userProfile} mb='20px' />
        </Flex>
      </SimpleGrid>
    </Box>
  );
}

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/variants/Default';
import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import BackgroundLogin from 'assets/img/auth/BackgroundLogin.png';
import { useNavigate } from 'react-router-dom';
import { BsCheck2, BsX } from 'react-icons/bs';

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // New state variables for form fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });

  const checkPasswordRequirements = (pass: string) => {
    setPasswordRequirements({
      length: pass.length >= 8,
      uppercase: /[A-Z]/.test(pass),
      lowercase: /[a-z]/.test(pass),
      number: /[0-9]/.test(pass),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(pass)
    });
  };

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];
    
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter');
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must contain at least one number');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Password must contain at least one special character');
    }
    
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate all required fields
    const validationErrors = [];
    
    if (!firstName.trim()) {
      validationErrors.push('First name is required');
    }
    
    if (!lastName.trim()) {
      validationErrors.push('Last name is required');
    }
    
    if (!email.trim()) {
      validationErrors.push('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.push('Please enter a valid email address');
    }
    
    if (!password.trim()) {
      validationErrors.push('Password is required');
    } else {
      const passwordErrors = validatePassword(password);
      validationErrors.push(...passwordErrors);
    }
    
    if (!company.trim()) {
      validationErrors.push('Company name is required');
    }

    // Check if terms checkbox is checked
    const termsCheckbox = document.getElementById('remember-login') as HTMLInputElement;
    if (!termsCheckbox.checked) {
      validationErrors.push('You must agree to the Terms and Conditions');
    }

    // If there are validation errors, show them and return
    if (validationErrors.length > 0) {
      validationErrors.forEach(error => {
        toast({
          title: 'Validation Error',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
      return;
    }

    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${API_BASE_URL}/api/auth/register`, {
        email,
        firstName,
        lastName,
        password,
        company,
      }, {
        withCredentials: true
      });
      
      // Store the session ID if it's in the response
      if (response.data.sessionId) {
        localStorage.setItem('verificationSessionId', response.data.sessionId);
      }
      
      toast({
        title: 'Verification email sent!',
        description: 'Please check your email for the verification code.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      // Redirect to verification page
      navigate('/auth/verification/default');
    } catch (error) {
      console.error('Registration error:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        toast({
          title: 'Registration failed',
          description: error.response.data.error || 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'An unexpected error occurred',
          description: 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <DefaultAuth 
      illustrationBackground={BackgroundLogin} 
      image={illustration}
    >
      <Box maxH="100vh" overflowY="auto">
        <Flex
          w="100%"
          maxW="max-content"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '8vh' }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading
              color={textColor}
              fontSize={{ base: '34px', lg: '36px' }}
              mb="10px"
            >
              Sign Up
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="400"
              fontSize="md"
            >
              Please fill in all the field to sign up!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            {/* <Button
              fontSize="sm"
              me="0px"
              mb="26px"
              py="15px"
              h="50px"
              borderRadius="16px"
              bg={googleBg}
              color={googleText}
              fontWeight="500"
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
            >
              <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
              Sign up with Google
            </Button>
            <Flex align="center" mb="25px">
              <HSeparator />
              <Text color={textColorSecondary} mx="14px">
                or
              </Text>
              <HSeparator />
            </Flex> */}
            <FormControl>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                gap={{ sm: '10px', md: '26px' }}
              >
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    First name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    ms={{ base: '0px', md: '4px' }}
                    //placeholder="First name"
                    variant="auth"
                    mb="24px"
                    size="lg"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Last name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    //placeholder="Last name"
                    mb="24px"
                    size="lg"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Flex>
              </SimpleGrid>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                type="email"
                //placeholder="mail@simmmple.com"
                mb="24px"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '4px' }}
                  //placeholder="Min. 8 characters"
                  mb="5px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    checkPasswordRequirements(e.target.value);
                  }}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Text fontSize="xs" color="gray.700" mb="4" ml="2">
                Password must contain at least:
                <UnorderedList m={0}>
                  <ListItem 
                    display="flex" 
                    alignItems="center" 
                    gap="2"
                    color={passwordRequirements.length ? 'green.500' : 'gray.700'}
                  >
                    <Icon 
                      as={passwordRequirements.length ? BsCheck2 : BsX} 
                      color={passwordRequirements.length ? 'green.500' : 'gray.700'} 
                    />
                    8 characters
                  </ListItem>
                  <ListItem 
                    display="flex" 
                    alignItems="center" 
                    gap="2"
                    color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'}
                  >
                    <Icon 
                      as={passwordRequirements.uppercase ? BsCheck2 : BsX} 
                      color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'} 
                    />
                    One uppercase letter
                  </ListItem>
                  <ListItem 
                    display="flex" 
                    alignItems="center" 
                    gap="2"
                    color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'}
                  >
                    <Icon 
                      as={passwordRequirements.lowercase ? BsCheck2 : BsX} 
                      color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'} 
                    />
                    One lowercase letter
                  </ListItem>
                  <ListItem 
                    display="flex" 
                    alignItems="center" 
                    gap="2"
                    color={passwordRequirements.number ? 'green.500' : 'gray.700'}
                  >
                    <Icon 
                      as={passwordRequirements.number ? BsCheck2 : BsX} 
                      color={passwordRequirements.number ? 'green.500' : 'gray.700'} 
                    />
                    One number
                  </ListItem>
                  <ListItem 
                    display="flex" 
                    alignItems="center" 
                    gap="2"
                    color={passwordRequirements.special ? 'green.500' : 'gray.700'}
                  >
                    <Icon 
                      as={passwordRequirements.special ? BsCheck2 : BsX} 
                      color={passwordRequirements.special ? 'green.500' : 'gray.700'} 
                    />
                    One special character
                  </ListItem>
                </UnorderedList>
              </Text>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Company<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                //placeholder="Your Company Name"
                mb="24px"
                size="lg"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="start">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brand"
                    me="10px"
                    mt="3px"
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    By creating an account means you agree to the{' '}
                    <Link
                      href="https://simmmple.com/terms-of-service"
                      fontWeight="500"
                    >
                      Terms and Conditions,
                    </Link>{' '}
                    and our{' '}
                    <Link
                      href="https://simmmple.com/privacy-policy"
                      fontWeight="500"
                    >
                      Privacy Policy
                    </Link>
                  </FormLabel>
                </FormControl>
              </Flex>
              <Button
                variant="brand"
                fontSize="14px"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={handleSubmit}
              >
                Create my account
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="sm">
                Already a member?
                <NavLink to="/auth/sign-in/default">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    Sign in
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default SignUp;
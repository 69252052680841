import React, { useState } from 'react';
import { 
  Button, 
  Flex, 
  FormControl, 
  Text, 
  useColorModeValue, 
  useToast,
  Icon,
  UnorderedList,
  ListItem 
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useAxiosWithAuth } from '../../../../../../hooks/useAxiosWithAuth';
import { UserProfile } from 'types/user';
import { BsCheck2, BsX } from 'react-icons/bs';
import axios from 'axios';

interface PasswordProps {
  userProfile: UserProfile | null;
  mb?: string;
}

export default function Password({ userProfile, mb }: PasswordProps) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const toast = useToast();
  const axiosInstance = useAxiosWithAuth();

  const checkPasswordRequirements = (pass: string) => {
    setPasswordRequirements({
      length: pass.length >= 8,
      uppercase: /[A-Z]/.test(pass),
      lowercase: /[a-z]/.test(pass),
      number: /[0-9]/.test(pass),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(pass),
    });
  };

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];
    
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter');
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must contain at least one number');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Password must contain at least one special character');
    }
    
    return errors;
  };

  const handleChangePassword = async () => {
    // Validate password requirements
    const passwordErrors = validatePassword(newPassword);
    if (passwordErrors.length > 0) {
      passwordErrors.forEach(error => {
        toast({
          title: 'Validation Error',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast({
        title: 'Passwords do not match',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await axiosInstance.put('/api/users/change-password', {
        oldPassword,
        newPassword,
      });
      
      toast({
        title: 'Password changed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      let errorMessage = 'Please try again later';
      
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          errorMessage = 'Invalid old password';
        } else if (error.response?.status === 404) {
          errorMessage = 'User not found';
        } else if (error.response?.status === 401) {
          errorMessage = 'Unauthorized. Please log in again.';
        }
      }
      
      toast({
        title: 'Error changing password',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!userProfile) {
    return <Card mb={mb}>Loading...</Card>;
  }

  return (
    <FormControl>
      <Card mb={mb}>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Change password
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can set your new password
          </Text>
        </Flex>
        <FormControl>
          <Flex flexDirection='column'>
            <InputField
              mb='25px'
              id='old'
              label='Old Password'
              placeholder='Enter old password'
              type='password'
              value={oldPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)}
            />
            <InputField
              mb='25px'
              id='new'
              label='New Password'
              placeholder='Enter new password'
              type='password'
              value={newPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(e.target.value);
                checkPasswordRequirements(e.target.value);
              }}
            />
            <InputField
              mb='25px'
              id='confirm'
              label='New Password Confirmation'
              placeholder='Confirm new password'
              type='password'
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
            />
          </Flex>
          <Text fontSize="xs" color="gray.700" mb="4" ml="2">
            Password must contain at least:
            <UnorderedList m={0}>
              <ListItem 
                display="flex" 
                alignItems="center" 
                gap="2"
                color={passwordRequirements.length ? 'green.500' : 'gray.700'}
              >
                <Icon 
                  as={passwordRequirements.length ? BsCheck2 : BsX} 
                  color={passwordRequirements.length ? 'green.500' : 'gray.700'} 
                />
                8 characters
              </ListItem>
              <ListItem 
                display="flex" 
                alignItems="center" 
                gap="2"
                color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'}
              >
                <Icon 
                  as={passwordRequirements.uppercase ? BsCheck2 : BsX} 
                  color={passwordRequirements.uppercase ? 'green.500' : 'gray.700'} 
                />
                One uppercase letter
              </ListItem>
              <ListItem 
                display="flex" 
                alignItems="center" 
                gap="2"
                color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'}
              >
                <Icon 
                  as={passwordRequirements.lowercase ? BsCheck2 : BsX} 
                  color={passwordRequirements.lowercase ? 'green.500' : 'gray.700'} 
                />
                One lowercase letter
              </ListItem>
              <ListItem 
                display="flex" 
                alignItems="center" 
                gap="2"
                color={passwordRequirements.number ? 'green.500' : 'gray.700'}
              >
                <Icon 
                  as={passwordRequirements.number ? BsCheck2 : BsX} 
                  color={passwordRequirements.number ? 'green.500' : 'gray.700'} 
                />
                One number
              </ListItem>
              <ListItem 
                display="flex" 
                alignItems="center" 
                gap="2"
                color={passwordRequirements.special ? 'green.500' : 'gray.700'}
              >
                <Icon 
                  as={passwordRequirements.special ? BsCheck2 : BsX} 
                  color={passwordRequirements.special ? 'green.500' : 'gray.700'} 
                />
                One special character
              </ListItem>
            </UnorderedList>
          </Text>
        </FormControl>
        <Button
          borderRadius='16px'
          minW='183px'
          h='44px'
          ms='auto'
          mt='33px'
          variant='brand'
          color='white'
          fontSize='sm'
          fontWeight='500'
          _hover={{ bg: 'brand.600' }}
          _active={{ bg: 'brand.500' }}
          _focus={{ bg: 'brand.500' }}
          onClick={handleChangePassword}
        >
          Change Password
        </Button>
      </Card>
    </FormControl>
  );
}

// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RouteProps } from "../../routes";
import { routes } from "../../routes";
import { FlexibleRoutesType, CustomRouteProps } from "../../types/types";
import React from "react";

// type CustomRouteProps = RouteProps & { setCurrentZoneName: (name: string) => void };
// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [currentZoneName, setCurrentZoneName] = useState("");

  const getActiveRoute = (
    routes: ((props: RouteProps) => RoutesType[]) | RoutesType[],
  ): string => {
    const routesArray =
      typeof routes === "function"
        ? routes({ currentZoneName, setCurrentZoneName })
        : routes;
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routesArray.length; i++) {
      if (routesArray[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routesArray[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(
            routesArray[i].layout + routesArray[i].path,
          ) !== -1
        ) {
          return routesArray[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (
    routes: ((props: RouteProps) => RoutesType[]) | RoutesType[],
  ): boolean => {
    const routesArray =
      typeof routes === "function"
        ? routes({ currentZoneName, setCurrentZoneName })
        : routes;
    let activeNavbar = false;
    for (let i = 0; i < routesArray.length; i++) {
      if (routesArray[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routesArray[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(
            routesArray[i].layout + routesArray[i].path,
          ) !== -1
        ) {
          return routesArray[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routesArray: RoutesType[]): React.ReactNode => {
    return routesArray.map((route: RoutesType, key: number) => {
      if (route.layout === "/admin") {
        return (
          <Route
            path={`${route.path}`}
            element={React.cloneElement(route.component as React.ReactElement, {
              setCurrentZoneName,
            })}
            key={key}
          />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      }
      return null;
    });
  };

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue("background.100", "background.900");
  return (
    <Box
      bg={bg}
      h="100vh"
      w="100vw"
    >
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          setHovered={setHovered}
          mini={mini}
          routes={
            routes as
              | FlexibleRoutesType[]
              | ((props: CustomRouteProps) => FlexibleRoutesType[])
          }
          setCurrentZoneName={setCurrentZoneName}
          display="none"
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="hidden"
          position="relative"
          maxHeight="100%"
          w={
            mini === false
              ? { base: "100%", xl: "calc( 100% - 290px )" }
              : mini === true && hovered === true
              ? { base: "100%", xl: "calc( 100% - 290px )" }
              : { base: "100%", xl: "calc( 100% - 120px )" }
          }
          maxWidth={
            mini === false
              ? { base: "100%", xl: "calc( 100% - 290px )" }
              : mini === true && hovered === true
              ? { base: "100%", xl: "calc( 100% - 290px )" }
              : { base: "100%", xl: "calc( 100% - 120px )" }
          }
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                logoText={"Horizon UI Dashboard PRO"}
                brandText={getActiveRoute(
                  routes({
                    currentZoneName,
                    setCurrentZoneName,
                  }) as RoutesType[],
                )}
                secondary={getActiveNavbar(
                  routes({
                    currentZoneName,
                    setCurrentZoneName,
                  }) as RoutesType[],
                )}
                fixed={fixed}
                currentZoneName={currentZoneName}
                {...rest}
              />
            </Box>
          </Portal>
          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              h={"100%"}
              pt="50px"
            >
              <Routes>
                {getRoutes(
                  routes({
                    currentZoneName,
                    setCurrentZoneName,
                  }) as RoutesType[],
                )}
                <Route
                  path="/"
                  element={
                    <Navigate
                      to="/admin/dashboards"
                      replace
                    />
                  }
                />
              </Routes>
            </Box>
          ) : null}
          {/*   <Box>
            <Footer />
          </Box> */}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}

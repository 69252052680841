import React from "react";
import { LabelOption } from "./LabelOptionEditor";
import { Chip, lighten, useTheme } from "@mui/material";

// Helper function to determine if a color needs white or black text
const getTextColor = (backgroundColor: string) => {
  // Convert hex to RGB
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  
  // Calculate luminance - a more accurate way to determine contrast
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
  // Return black for light colors, white for dark colors
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const LabelCell = React.memo(
  ({ value, options }: { value: string; options: LabelOption[] }) => {
    console.log(value, options)
    const theme = useTheme();
    const option = React.useMemo(
      () =>
        options.find((opt) => opt.label === value) || {
          label: 'Please select',
          color: theme.palette.grey[300],
        },
      [options, theme.palette.grey, value]
    );

    // Get appropriate text color based on background
    const textColor = getTextColor(option.color);

    return (
      <Chip
        label={option.label} 
        sx={{
        //   bgcolor: option.color,
        //   color: getContrastYIQ(option.color),
        //bgcolor: lighten(option.color, 0.75),
        //color: option.color,
        bgcolor: option.color,
        color: textColor,
          height: '24px',
          '& .MuiChip-label': {
            padding: '0 12px',
          }
        }} 
      />
    );
  }
);

export default LabelCell;
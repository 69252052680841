import React, { useState, useEffect } from 'react';
import {
  Button,
  VStack,
  Text,
  Select,
  FormControl,
  FormLabel,
  useSteps,
  Flex,
  Box,
  Input,
  CloseButton,
  useToast,
} from '@chakra-ui/react';
import { zoneService } from 'services/zoneService';
import BarChart from './BarChart';
import PieChart from './PieChart';
import { DashboardPanel } from '../../../../../types/dashboard';
import CustomPopup from 'components/CustomPopup';
import { useColorModeValue } from '@chakra-ui/react';
import { dashboardService } from '../../../../../services/dashboardService';
import NumberPanel from './NumberPanel';
import { useAuth } from 'contexts/AuthContext';

type PanelType = 'bar' | 'pie' | 'number';

interface DashboardPanelWizardProps {
  user: { companyId: string };
  isOpen: boolean;
  onClose: () => void;
  onSave: (panelData: any) => void;
  editPanel?: DashboardPanel | null;
}

const DashboardPanelWizard: React.FC<DashboardPanelWizardProps> = ({ 
  user, 
  isOpen, 
  onClose, 
  onSave,
  editPanel 
}) => {
  const [step, setStep] = useState(0);
  const [panelType, setPanelType] = useState<PanelType>('bar');
  const [selectedZone, setSelectedZone] = useState('');
  const [zones, setZones] = useState([]);
  const [zoneFields, setZoneFields] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [panelTitle, setPanelTitle] = useState('');
  const [numberFields, setNumberFields] = useState([]);
  const [currZoneType, setCurrZoneType] = useState("");
  const [currZoneData, setCurrZoneData] = useState<Array<{
    [key: string]: string
  }>>([])
  const userType = useAuth().user.role;
  const companyId = useAuth().user.companyId;
  const userEmail = useAuth().user.email;
  const steps = [
    { title: 'Select Panel Type', description: 'Choose the type of panel to add' },
    { title: 'Select Zone', description: 'Choose the zone to get data from' },
    { title: 'Configure Panel', description: 'Set up the panel details' },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: step,
    count: steps.length,
  });

  const toast = useToast();

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
  }, [zoneFields]);

  useEffect(() => {
    if (editPanel) {
      setPanelType(editPanel.type);
      setSelectedZone(editPanel.zoneId);
      setPanelTitle(editPanel.title);
      setSelectedField(editPanel.field || '');
      setSelectedXAxis(editPanel.xAxis || '');
      setSelectedYAxis(editPanel.yAxis || '');
      fetchZoneFields(editPanel.zoneId);
    }
  }, [editPanel]);

  useEffect(() => {
    if (isOpen) {
      // Reset all state when wizard opens
      setStep(0);
      if (!editPanel) {
        setPanelType('bar');
        setSelectedZone('');
        setPanelTitle('');
        setSelectedField('');
        setSelectedXAxis('');
        setSelectedYAxis('');
      }
    }
  }, [isOpen, editPanel]);

  const fetchZones = async () => {
    try {
    
      const fetchedZones = await zoneService.getAllZones(companyId , userType , userEmail);
      setZones(fetchedZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchZoneFields = async (zoneId: string) => {
    try {
      const zoneData = await zoneService.getZoneById(zoneId);

      let fields = [];
      let numberFields = [];
      setCurrZoneType(zoneData.data.type)
      if (zoneData.data.type === 'table') {

        fields = Object.entries(zoneData.data.columnTypes || {}).map(([key, type]) => ({
          _id: key,
          name: key,
          type: type
        }));

        setCurrZoneData(zoneData.data.selectOptions)
      } else {
        if (zoneData.data && zoneData.data.fields) {
          fields = zoneData.data.fields.map((field: { id?: string; _id?: string; label?: string; name?: string; type?: string; options?: any }) => ({
            _id: field.id || field._id || '',
            name: field.label || field.name || '',
            type: field.type || 'text',
            options: field.options
          }));
        }
      }

      numberFields = fields.filter((field: { type: string }) => field.type.toLowerCase() === 'number');

      setZoneFields(fields);
      setNumberFields(numberFields);
    } catch (error) {
      console.error('Error fetching zone fields:', error);
    }
  };

  const handleNextStep = () => {
    if (step === 0) {
      if (!panelTitle.trim()) {
        toast({
          title: 'Error',
          description: 'Panel title is required',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }
    
    if (step === 1) {
      fetchZoneFields(selectedZone);
    }
    setStep(step + 1);
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
    setActiveStep(activeStep - 1);
  };

  const handleSave = async () => {
    if (!panelTitle.trim()) {
      toast({
        title: 'Error',
        description: 'Panel title is required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!panelType) {
      toast({
        title: 'Error',
        description: 'Please select a panel type',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const panelData = {
      _id: editPanel?._id,
      title: panelTitle.trim(),
      type: panelType,
      zoneId: selectedZone,
      field: selectedField,
      xAxis: selectedXAxis,
      yAxis: selectedYAxis,
      companyId: user.companyId
    };

    try {
      onSave(panelData);
      onClose();
    } catch (error) {
      console.error('Error saving panel:', error);
      toast({
        title: 'Error',
        description: 'Failed to save the panel. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('gray.700', 'white');
  const activeColor = useColorModeValue('orange.500', 'orange.200');
  const inactiveColor = useColorModeValue('gray.200', 'gray.600');

  const handlePanelTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as PanelType;
    setPanelType(value);
  };

  return (
    <CustomPopup isOpen={isOpen} onClose={onClose} hideCloseButton>
      <VStack width="600px" maxWidth="600px" spacing={0}>
        <Box width="100%" bg="gray.200" position="relative">
          <Flex justifyContent="center" alignItems="center" height="50px">
            <Text fontSize="2xl" fontWeight="normal">Add Dashboard Panel</Text>
          </Flex>
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={onClose}
          />
        </Box>
        <Box width="100%" padding="10">
          <Box display="flex" justifyContent="center" width="100%">
            <Flex align="center" mb="8" width="300px">
              {steps.map((s, i) => (
                <React.Fragment key={i}>
                  <Flex direction="column" align="center">
                    <Box
                      w="15px"
                      h="15px"
                      borderRadius="full"
                      bg={i <= step ? activeColor : inactiveColor}
                    />
                  </Flex>
                  {i < steps.length - 1 && (
                    <Box
                      flex="1"
                      h="2px"
                      bg={i < step ? activeColor : inactiveColor}
                      mx="2"
                    />
                  )}
                </React.Fragment>
              ))}
            </Flex>
          </Box>
          <Text fontSize="lg" align="center" color="orange.500" mb={0}>{steps[step].title}</Text>
          <Text fontSize="ms" align="center" mb={10}>{steps[step].description}</Text>

          <VStack spacing={4} align="stretch">
            {step === 0 && (
              <>
                <FormControl isRequired>
                  <FormLabel>Panel Title</FormLabel>
                  <Input
                    placeholder="Enter panel title"
                    value={panelTitle}
                    onChange={(e) => setPanelTitle(e.target.value)}
                    isInvalid={step === 0 && panelTitle.trim() === ''}
                  />
                  {step === 0 && panelTitle.trim() === '' && (
                    <Text color="red.500" fontSize="sm" mt={1}>
                      Panel title is required
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Panel Type</FormLabel>
                  <Select
                    placeholder="Select panel type"
                    value={panelType}
                    onChange={handlePanelTypeChange}
                  >
                    <option value="bar">Bar Chart</option>
                    <option value="pie">Pie Chart</option>
                    <option value="number">Numbers</option>
                  </Select>
                </FormControl>
              </>
            )}

            {step === 1 && (
              <FormControl>
                <FormLabel>Zone</FormLabel>
                <Select
                  placeholder="Select zone"
                  value={selectedZone}
                  onChange={(e) => {
                    setSelectedZone(e.target.value);
                    fetchZoneFields(e.target.value);
                  }}
                >
                  {zones.map((zone) => (
                    <option key={zone._id} value={zone._id}>
                      {zone.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            {step === 2 && (
              <>
                {panelType === 'number' && (
                  <>
                    <FormControl>
                      <FormLabel>Number Field</FormLabel>
                      <Select
                        placeholder="Select number field"
                        value={selectedField}
                        onChange={(e) => {
                          setSelectedField(e.target.value);
                        }}
                      >
                        {numberFields.map((field) => (
                          <option key={field._id} value={field._id}>
                            {field.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {selectedZone && selectedField && (
                      <Box mt={4} width="100%" height="300px">
                        <NumberPanel zoneId={selectedZone} field={selectedField} />
                      </Box>
                    )}
                  </>
                )}
                {panelType === 'pie' && (
                  <>
                    <FormControl>
                      <FormLabel>Field</FormLabel>
                      <Select
                        placeholder="Select field"
                        value={selectedField}
                        onChange={(e) => {
                          setSelectedField(e.target.value);
                        }}
                      >
                        {zoneFields.filter(field =>
                          ['select', 'label', 'text'].includes(field.type.toLowerCase()) && (currZoneType === "table" ? currZoneData[field?.name] : field.options)
                        ).map((field) => (
                          <option key={field._id} value={field._id}>
                            {field.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {selectedZone && selectedField && (
                      <Box mt={4} width="100%" height="300px">
                        <PieChart zoneId={selectedZone} field={selectedField} />
                      </Box>
                    )}
                  </>
                )}
                {panelType === 'bar' && (
                  <>
                    <FormControl>
                      <FormLabel>X-Axis Field</FormLabel>
                      <Select
                        placeholder="Select X-Axis field"
                        value={selectedXAxis}
                        onChange={(e) => setSelectedXAxis(e.target.value)}
                      >
                        {zoneFields.map((field) => (
                          <option key={field._id} value={field._id}>
                            {field.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Y-Axis Field</FormLabel>
                      <Select
                        placeholder="Select Y-Axis field"
                        value={selectedYAxis}
                        onChange={(e) => setSelectedYAxis(e.target.value)}
                      >
                        {zoneFields.filter(field => {
                          return field.type.toLowerCase() === 'number' ||
                            field.type.toLowerCase() === 'integer' ||
                            (!isNaN(parseFloat(field.name)) && isFinite(parseFloat(field.name)));
                        }).map((field) => (
                          <option key={field._id} value={field._id}>
                            {field.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {selectedZone && selectedXAxis && selectedYAxis && (
                      <Box mt={4}>
                        <BarChart zoneId={selectedZone} xAxis={selectedXAxis} yAxis={selectedYAxis} />
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </VStack>
        </Box>
        <Box width="100%" bg="gray.200" padding="10">
          <Flex justifyContent="flex-end">
            {step > 0 && (
              <Button variant="outline" colorScheme="black" onClick={handlePreviousStep} mr={3}>
                Previous
              </Button>
            )}
            {step < steps.length - 1 ? (
              <Button colorScheme="orange" onClick={handleNextStep}>
                Next
              </Button>
            ) : (
              <Button colorScheme="brand" onClick={handleSave}>
                Save
              </Button>
            )}
          </Flex>
        </Box>
      </VStack>
    </CustomPopup>
  );
};

export default DashboardPanelWizard;
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Table,
  Thead,
  Flex,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
  Spinner,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Icon,
  Checkbox,
  Tooltip
} from '@chakra-ui/react';
import { AddIcon, EditIcon, CopyIcon, DeleteIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons';
import Card from 'components/card/Card';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { zoneService } from 'services/zoneService';
// import ZonePage from './ZonePage';
// import ZonePage from './ZonePageNew';
import ZonePage from './ZonePageNew_V2';
import { css } from '@emotion/react';
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { MdImage, MdPictureAsPdf, MdInsertDriveFile } from 'react-icons/md';
import { SiMicrosoftword, SiMicrosoftexcel } from 'react-icons/si';
import { FaFileExcel } from 'react-icons/fa';
import { exportToExcel } from '../../../../services/exportService';
import { useDispatch } from 'react-redux';
import { setActiveZone } from 'store/slices/zoneSlice';
import ImportWizardTabs from './ImportWizardTabs';

// Define proper field content types to fix TypeScript errors
interface FieldContent {
  value: any;
  options?: {
    currency?: string;
    [key: string]: any;
  };
  currency?: string;
  [key: string]: any;
}

interface ZoneItem {
  id: string;
  _id: string;
  data: {
    [key: string]: FieldContent;
  };
  [key: string]: any;
}

interface LabelOption {
  label: string;
  color: string;
}

interface Field {
  id: string;
  _id: string;
  type: string;
  label: string;
  name: string;
  appearInTable: boolean;
  options?: LabelOption[] | string[] | { zoneId: string, options: string[] };
  required: boolean;
  tabId: string;
  order: number;
  position: string;
}

interface Zone {
  _id: string;
  name: string;
  type: string;
  availableUsers: string[];
  tabs: any[];
  columns: string[];
  columnOrder: string[];
  columnTypes: { [key: string]: string };
  columnWidths: { [key: string]: number };
  data: any[];
  selectOptions: { [key: string]: string[] };
  fields: {
    id: string; name: string, _id: string, appearInTable: boolean,
    label: string;
  }[];
}

interface ZoneListPageProps {
  setCurrentZoneName?: (name: string) => void;
  type?: string,
  zone?: Zone,
  reportResult?: any
  reportZoneId?: string | undefined
  noPadding?: boolean
}

const freezeHeaderStyle = css`
  /* position: sticky; */
  top: 0;
  zIndex: 10;
  background-color: var(--chakra-colors-white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ZoneListPage: React.FC<ZoneListPageProps> = ({ 
  setCurrentZoneName = (name: string) => { }, 
  type = "", 
  zone = undefined, 
  reportResult = undefined, 
  reportZoneId = undefined,
  noPadding = false
}) => {
  const [zoneItems, setZoneItems] = useState<ZoneItem[]>([]);
  const [zoneFields, setZoneFields] = useState<Field[]>([]);
  const [zoneFieldsLabel, setZoneFieldsLabel] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true);
  const [zoneType, setZoneType] = useState<string>("");
  const { id: zoneId } = useParams<{ id: string }>();
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dispatch = useDispatch();
  const [isImportWizardOpen, setIsImportWizardOpen] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    return () => {
      if (location.pathname !== `/admin/zones/${zoneId}`) {
        setRowSelection({});
        setItemsToDelete([]);
      }
    };
  }, [location, zoneId]);

  const filteredZoneItems = useMemo(() => {
    return zoneItems.filter((item) =>
      Object.values(item.data || {}).some((field) =>
        String((field as any).value ?? '').toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [zoneItems, searchTerm]);

  useEffect(() => {
    // if (zoneId && !type) {
      fetchZoneData(reportZoneId ? reportZoneId : zoneId);

      if (reportResult) {
        setZoneItems(reportResult)
      }
  }, [zoneId, reportResult]);

  const handleRowSelection = (itemId: string) => {
    setRowSelection(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  const exportSelectedItems = async () => {
    const selectedItems = zoneItems.filter(item => rowSelection[item._id]);
    if (selectedItems.length > 0) {
      try {
        const zoneFields = await zoneService.getZoneFields(zoneId);
        const fullItemData = await Promise.all(selectedItems.map(async item => {
          try {
            return await zoneService.getZoneItemById(zoneId, item._id, true);
          } catch (error) {
            console.error(`Error fetching item ${item._id}:`, error);
            return null;
          }
        }));

        const validItemData = fullItemData.filter(item => item !== null);

        if (validItemData.length === 0) {
          throw new Error("No valid items to export");
        }

        const exportData = validItemData.map(item => {
          const rowData: { [key: string]: any } = {};
          zoneFields.forEach((field: { id: string; label: string }) => {
            const fieldValue = item.data[field.id];
            rowData[field.label] = fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue
              ? fieldValue.value
              : fieldValue;
          });
          return rowData;
        });

        const fileName = `${zoneType}_export_${new Date().toISOString()}.xlsx`;
        exportToExcel(exportData, fileName);
        toast({
          title: "Export Successful",
          description: `${validItemData.length} items exported to ${fileName}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error exporting items:", error);
        toast({
          title: "Export Failed",
          description: "An error occurred while exporting items",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Export Failed",
        description: "No items selected for export",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchZoneData = async (currZoneId: string) => {
    setIsLoading(true);
    try {
      const zoneData = await zoneService.getZoneById(currZoneId);
      dispatch(setActiveZone(zoneData.data.name));

      if (zoneData && zoneData?.data) {
        const tableFields = zoneData?.data?.fields?.filter((field: Field) => field?.appearInTable);
        if (tableFields) {
          setZoneFieldsLabel(tableFields?.map((field: Field) => field?.id));
          const fieldsWithOptions = tableFields.map((field: Field) => {
            if (field.type === 'Label' && zoneData.data.selectOptions && zoneData.data.selectOptions[field.id]) {
              return {
                ...field,
                options: zoneData.data.selectOptions[field.id].map((option: string) => ({
                  label: option,
                  color: zoneData.data.labelColors?.[field.id]?.[option] || 'gray.300'
                }))
              };
            }
            return field;
          });
          setZoneFields(fieldsWithOptions);
        }
        setZoneType(zoneData.data.type);
      }
      
      if (!type) {
        // Get all the zone items
        const zoneItems = await zoneService.getZoneItems(currZoneId);
        
        // Process items with correct typing
        const processedItems = await Promise.all(
          zoneItems.map(async (item: any) => {
            const fullItem = await zoneService.getZoneItemById(currZoneId, item._id, false);
            
            // Create a properly structured item
            return {
              id: fullItem._id,
              _id: fullItem._id,
              // Process the data field by field
              data: Object.entries(fullItem.data).reduce((acc: {[key: string]: any}, [key, content]: [string, any]) => {
                // Skip processing if not an object
                if (typeof content !== 'object' || content === null) {
                  acc[key] = { value: content };
                  return acc;
                }
                
                // For objects containing a 'value' property
                if ('value' in content) {
                  // Special handling for price fields with currency
                  if (content.options && typeof content.options === 'object') {
                    // Store with explicit currency property
                    acc[key] = { 
                      value: content.value, 
                      currency: content.options.currency || 'USD'
                    };
                  } else {
                    // Regular field - just store the value
                    acc[key] = { value: content.value };
                  }
                } else {
                  // For nested objects, flatten into fields
                  Object.entries(content).forEach(([fieldId, fieldValue]) => {
                    acc[fieldId] = { value: fieldValue };
                  });
                }
                
                return acc;
              }, {})
            };
          })
        );
        
        setZoneItems(processedItems);
      }
    } catch (error: unknown) {
      console.error('Error fetching zone data:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      }
      if (typeof error === 'object' && error !== null) {
        const axiosError = error as { response?: { data?: any; status?: number }; request?: any };
        if ('response' in axiosError && axiosError.response) {
          console.error('Response data:', axiosError.response.data);
          console.error('Response status:', axiosError.response.status);
        } else if ('request' in axiosError) {
          console.error('No response received:', axiosError.request);
        }
      }
      // Optionally, you can set an error state here to display to the user
      // setError('Failed to fetch zone data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateItem = () => {
    navigate(`/admin/zones/${zoneId}/item/new`);
  };

  const handleEditItem = (itemId: string) => {
    navigate(`/admin/zones/${zoneId}/item/${itemId}/edit`);
  };

  const handleDuplicateItem = async (itemId: string) => {
    try {
      const originalItem = await zoneService.getZoneItemById(zoneId, itemId);
      const duplicatedItem = await zoneService.duplicateZoneItem(zoneId, itemId);
      
      // Create a complete new item with all the necessary data
      const newItem = {
        id: duplicatedItem._id,
        _id: duplicatedItem._id,
        data: { ...originalItem.data },
        createdAt: duplicatedItem.createdAt,
        updatedAt: duplicatedItem.updatedAt,
        zoneId: duplicatedItem.zoneId
      };
      
      // Update the UI with the complete item
      setZoneItems(prevItems => [...prevItems, newItem]);

      toast({
        title: "Item duplicated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error duplicating item:', error);
      toast({
        title: "Error",
        description: "Failed to duplicate the item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteItem = (itemId: string | string[]) => {
    const ids = Array.isArray(itemId) ? itemId : [itemId];
    setItemsToDelete(ids);
    onOpen();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDeleteConfirm = async () => {
    try {
      await Promise.all(itemsToDelete.map(id => zoneService.deleteZoneItem(zoneId, id)));
      setZoneItems(prevItems => prevItems.filter(item => !itemsToDelete.includes(item._id)));
      setRowSelection({});
      toast({
        title: "Items deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error deleting items:', error);
      toast({
        title: "Error deleting items",
        description: "Please try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
  };

  const getFileIcon = (fileName: any) => {
    if (typeof fileName !== 'string') {
      //console.warn('Invalid fileName:', fileName);
      return MdInsertDriveFile;
    }
    const parts = fileName.split('.');
    const extension = parts[parts.length - 1].toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return MdImage;
      case 'pdf':
        return MdPictureAsPdf;
      case 'doc':
      case 'docx':
        return SiMicrosoftword;
      case 'xls':
      case 'xlsx':
        return SiMicrosoftexcel;
      default:
        return MdInsertDriveFile;
    }
  };

  const handleImportItems = async (importedItems: any[]) => {
    try {
      setZoneItems(importedItems.map(item => ({
        id: item._id,
        _id: item._id,
        data: Object.entries(item).reduce<{ [key: string]: any }>((acc, [key, value]) => {
          if (key !== '_id') {
            // Check if value is an object with a 'value' property
            if (typeof value === 'object' && value !== null) {
              if ('value' in value) {
                // Use type assertion to access properties TypeScript doesn't know about
                const fieldValue = value as {
                  value: any;
                  options?: { currency?: string; [key: string]: any };
                };
                
                // Check for price fields with currency
                if (fieldValue.options && fieldValue.options.currency) {
                  // Store the currency directly on the object
                  acc[key] = { 
                    value: fieldValue.value,
                    currency: fieldValue.options.currency
                  };
                } else {
                  // Regular field - just store the value
                  acc[key] = { value: fieldValue.value };
                }
              } else {
                acc[key] = value;
              }
            } else {
              acc[key] = { value };
            }
          }
          return acc;
        }, {})
      })));
      
      toast({
        title: "Import Successful",
        description: `${importedItems.length} items have been imported`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error handling imported items:', error);
      toast({
        title: "Error",
        description: "Failed to process imported items",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFileDownload = async (fileName: string) => {
    try {
      // Extract companyId from the file path
      const pathParts = fileName.split('/');
      const companyId = pathParts[2];
      const actualFileName = pathParts[pathParts.length - 1];

      // Check both localStorage and sessionStorage for the token
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');

      if (!token) {
        toast({
          title: 'Authentication Error',
          description: 'Please log in again to download the file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const downloadUrl = `${process.env.REACT_APP_API_URL}/api/company/download-file/${companyId}/${actualFileName}`;

      const response = await fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': '*/*',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          toast({
            title: 'Authentication Error',
            description: 'Please log in again to download the file.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        throw new Error(`Download failed: ${response.status} ${response.statusText}`);
      }

      // Check content type to ensure we're getting a file and not HTML
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('text/html')) {
        throw new Error('Invalid response type received');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = actualFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download error:', error);
      toast({
        title: 'Download Error',
        description: 'Failed to download the file. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getCellContent = (item: any, field: string) => {
    if (!item.data) return '';
    
    const fieldData = item.data[field];
    if (!fieldData) return '';
    
    // If it's an object with a value property, return the value
    if (typeof fieldData === 'object' && 'value' in fieldData) {
      return fieldData.value;
    }
    
    // If it's a primitive value, return it directly
    if (typeof fieldData !== 'object' || fieldData === null) {
      return fieldData;
    }
    
    return '';
  };

  // Function to get the field type by its ID
  const getFieldType = (fieldId: string) => {
    const field = zoneFields.find(f => f.id === fieldId);
    return field?.type || '';
  };

  // Simplified currency formatter that works with our data structure
  const formatPriceWithCurrency = (item: any, field: string): string => {
    if (!item.data || !item.data[field]) return '';
    
    // Get the field data
    const fieldData = item.data[field];
    
    // Get the value 
    const value = fieldData.value || '';
    
    // Get the currency (now stored directly in the field)
    const currency = fieldData.currency || 'USD';
    
    // Currency symbols
    const currencySymbols: {[key: string]: string} = {
      'USD': '$', 'EUR': '€', 'GBP': '£', 'JPY': '¥', 'CAD': 'C$', 'AUD': 'A$'
    };
    
    const symbol = currencySymbols[currency] || currency;
    return `${symbol}${value}`;
  };

  if (zoneType === "table") {
    return <ZonePage setCurrentZoneName={setCurrentZoneName} isEditable={false} reportResult={reportResult}/>
  }

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Card 
        flexGrow={1} 
        display="flex" 
        flexDirection="column" 
        overflow="hidden" 
        height="100%"
        p={noPadding ? "0" : "20px"}
        mt={noPadding ? "0" : "70px"}
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Flex alignItems="center" gap={4}>
            <SearchBar
              placeholder="Search..."
              value={searchTerm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm('')}
              width="300px"
            />
            <Text color="gray.600" fontSize="sm">
              {filteredZoneItems.length} {filteredZoneItems.length === 1 ? 'result' : 'results'} for this zone
            </Text>
            {Object.values(rowSelection).some(Boolean) && (
              <Flex alignItems="center" gap={4}>
                <Text
                  fontSize="18px"
                  color="brand.500"
                  fontWeight="normal"
                  ml="100px"
                >
                  {Object.values(rowSelection).filter(Boolean).length} row{Object.values(rowSelection).filter(Boolean).length === 1 ? '' : 's'} selected
                </Text>
                <Button
                  colorScheme="brand"
                  variant="outline"
                  leftIcon={<Icon as={DeleteIcon} />}
                  onClick={() => {
                    const selectedIds = Object.entries(rowSelection)
                      .filter(([_, selected]) => selected)
                      .map(([id]) => id);
                    handleDeleteItem(selectedIds);
                  }}
                  fontSize="14px"
                  fontWeight="normal"
                >
                  Delete
                </Button>
                <Button
                  colorScheme="brand"
                  variant="outline"
                  leftIcon={<Icon as={CopyIcon} />}
                  onClick={async () => {
                    const selectedIds = Object.entries(rowSelection)
                      .filter(([_, selected]) => selected)
                      .map(([id]) => id);
                    
                    // Show loading toast
                    const loadingToast = toast({
                      title: "Duplicating items...",
                      status: "loading",
                      duration: null,
                    });
                    
                    try {
                      // Handle all duplications in parallel
                      await Promise.all(selectedIds.map(id => handleDuplicateItem(id)));
                      
                      // Clear selection after successful duplication
                      setRowSelection({});
                      
                      // Close loading toast and show success
                      toast.close(loadingToast);
                      toast({
                        title: "Items duplicated successfully",
                        description: `${selectedIds.length} item(s) have been duplicated`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                    } catch (error) {
                      // Close loading toast and show error
                      toast.close(loadingToast);
                      toast({
                        title: "Error duplicating items",
                        description: "Some items failed to duplicate",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  }}
                  fontSize="14px"
                  fontWeight="normal"
                >
                  Duplicate
                </Button>
                <Button
                  colorScheme="brand"
                  variant="outline"
                  leftIcon={<Icon as={FaFileExcel} />}
                  onClick={exportSelectedItems}
                  fontSize="14px"
                  fontWeight="normal"
                >
                  Export to Excel
                </Button>
              </Flex>
            )}
          </Flex>
          {!type && (
            <Flex gap={2}>
              <Button 
                colorScheme="brand" 
                leftIcon={<AddIcon />}
                onClick={handleCreateItem}
                fontSize="14px"
                size="sm"
                fontWeight="normal"
              >
                Create New Item
              </Button>
              <Button
                colorScheme="brand"
                variant="outline"
                leftIcon={<Icon as={FaFileExcel} />}
                onClick={() => setIsImportWizardOpen(true)}
                fontSize="14px"
                size="sm"
                fontWeight="normal"
              >
                Import from Excel
              </Button>
            </Flex>
          )}
        </Flex>
        {isLoading ? (
          <Spinner />
        ) : zoneFields?.length === 0 || zoneItems?.length === 0 ? (
          <Text>No data available. Please check your zone configuration.</Text>
        ) : (
          <Box flexGrow={1} overflow="auto">
            <Box>
              <Table variant="simple" style={{ minWidth: `${(zoneFields.length + 2) * 100}px` }} className='zoneListTable'>
                <Thead css={[freezeHeaderStyle, { top: 0, zIndex:9 }]}>
                  <Tr>
                    <Th
                      width="40px"
                      color="gray.700"
                      fontWeight="normal"
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                      padding="3px 12px"
                      textAlign="center"
                    >
                      <Flex justify="center" align="center" height="100%">
                        <Checkbox
                          isChecked={Object.keys(rowSelection).length > 0 && Object.keys(rowSelection).every(key => rowSelection[key])}
                          isIndeterminate={Object.keys(rowSelection).some(key => rowSelection[key]) && !Object.keys(rowSelection).every(key => rowSelection[key])}
                          colorScheme="orange"
                          onChange={() => {
                            const allSelected = Object.keys(rowSelection).every(key => rowSelection[key]);
                            setRowSelection(
                              filteredZoneItems.reduce((acc, item) => ({
                                ...acc,
                                [item._id]: !allSelected
                              }), {})
                            );
                          }}
                        />
                      </Flex>
                    </Th>
                    {zoneFields.map((field) => (
                      <Th
                        key={field._id || field.label + Math.random() * 9999}
                        maxWidth="130px"
                        color="gray.700"
                        fontWeight="normal"
                        borderWidth="1px"
                        borderColor={borderColor}
                        borderStyle="solid"
                        padding="3px 6px"
                      >
                        <Tooltip
                          bg="gray.800"
                          label={field.label}
                          isDisabled={field.label.length <= 20}
                          placement="top"
                          hasArrow
                        >
                          <Flex
                            justify="space-between"
                            align="left"
                            color="gray.700"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {field.label}
                          </Flex>
                        </Tooltip>
                      </Th>
                    ))}
                    {!type && <Th 
                      maxWidth="80px"
                      color="gray.700"
                      fontWeight="normal"
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                     padding="3px 6px">Actions</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredZoneItems.map((item) => (
                    <Tr 
                      key={item._id}
                      bg={rowSelection[item._id] ? '#fff4f2' : 'transparent'}
                      transition="background-color 0.2s"
                    >
                      <Td
                        width="40px"
                        borderColor={borderColor}
                        borderWidth="1px"
                        borderStyle="solid"
                       padding="3px 6px"
                        textAlign="center"
                      >
                        <Flex justify="center" align="center" height="100%">
                          <Checkbox
                            isChecked={rowSelection[item._id] || false}
                            onChange={() => handleRowSelection(item._id)}
                            colorScheme="orange"
                          />
                        </Flex>
                      </Td>
                      {zoneFieldsLabel.map((field, idx) => {
                        const cellContent = getCellContent(item, field);
                        const fieldType = getFieldType(field);
                        const isPrice = fieldType === 'Price';
                        const displayContent = isPrice ? formatPriceWithCurrency(item, field) : cellContent;
                        
                        return (
                          <Td
                            key={`${field}-${item.id}-${idx}`}
                            minWidth="100px"
                            maxWidth="130px"
                            borderColor={borderColor}
                            borderWidth="1px"
                            borderStyle="solid"
                            padding="3px 6px"
                            height="39px"
                            maxHeight="39px"
                            sx={{
                              '& > *': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }
                            }}
                          >
                            <Tooltip
                              bg="gray.800"
                              label={displayContent}
                              isDisabled={!displayContent || displayContent.length <= 20}
                              placement="top"
                              hasArrow
                            >
                              <Box>
                                {zoneFields.find(f => f.id === field)?.type === 'File' && item.data && item.data[field]?.value ? (
                                  <Link 
                                    to={item.data[field].value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Icon 
                                      as={getFileIcon(item.data[field].value)}
                                      boxSize={6}
                                      cursor="pointer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleFileDownload(item.data[field].value);
                                      }}
                                      _hover={{ opacity: 0.8 }}
                                    />
                                  </Link>
                                ) : zoneFields.find(f => f.id === field)?.type === 'Label' && item.data && item.data[field]?.value ? (
                                  <Flex alignItems="center" width="100%">
                                    <Tooltip
                                      bg="gray.800"
                                      label={`${item.data[field].value}`}
                                      placement="top"
                                      hasArrow
                                    >
                                      <Box 
                                        flexShrink={0}
                                        width="20px" 
                                        height="20px" 
                                        borderRadius="50%" 
                                        backgroundColor={
                                          (() => {
                                            const foundField = zoneFields.find(f => f.id === field);
                                            if (foundField?.options && Array.isArray(foundField.options)) {
                                              const option = (foundField.options as Array<LabelOption | string>).find((opt): opt is LabelOption => 
                                                typeof opt === 'object' && opt !== null && 'label' in opt && opt.label === item.data[field].value
                                              );
                                              return option?.color || 'gray.300';
                                            }
                                            return 'gray.300';
                                          })()
                                        } 
                                        marginRight="10px"
                                        cursor="pointer"
                                      />
                                    </Tooltip>
                                    <Box overflow="hidden">
                                      <Tooltip
                                        bg="gray.800"
                                        label={item.data[field].value}
                                        isDisabled={!item.data[field].value || item.data[field].value.length <= 15}
                                        placement="top"
                                        hasArrow
                                      >
                                        <Text
                                          whiteSpace="nowrap"
                                          overflow="hidden"
                                          textOverflow="ellipsis"
                                        >
                                          {item.data[field].value}
                                        </Text>
                                      </Tooltip>
                                    </Box>
                                  </Flex>
                                ) : idx === 0 ? (
                                  <Link to={`/admin/zones/${zoneId}/item/${item._id}/edit`}>
                                    {displayContent}
                                  </Link>
                                ) : (
                                  displayContent
                                )}
                              </Box>
                            </Tooltip>
                          </Td>
                        );
                      })}
                      {!type && (
                        <Td 
                          minWidth="80px"
                          borderColor={borderColor}
                          borderWidth="1px"
                          borderStyle="solid"
                          padding="3px"
                        >
                          <Menu>
                            <MenuButton
                               as={IconButton}
                               aria-label='Options'
                               icon={<MenuDots boxSize={6} />}
                               variant='ghost'
                               size='xs'
                               _hover={{
                                 bg: "gray.200"
                               }} 
                            />
                            <MenuList className='smallMenuDrop'>
                              <MenuItem 
                                icon={<Icon as={EditIcon} color="orange.500" />}
                                onClick={() => handleEditItem(item._id)}>
                                Edit
                              </MenuItem>
                              <MenuItem 
                              icon={<Icon as={CopyIcon} color="orange.500" />}
                              onClick={() => handleDuplicateItem(item._id)}>
                                Duplicate
                              </MenuItem>
                              <MenuItem 
                               icon={<Icon as={DeleteIcon} color="orange.500" />}
                               onClick={() => handleDeleteItem(item._id)}>
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Card>
      {isOpen && 
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Item</ModalHeader>
          <ModalBody>
            Are you sure you want to delete {itemsToDelete.length} selected item{itemsToDelete.length > 1 ? 's' : ''}? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="solid" colorScheme="red" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>}
      <ImportWizardTabs
        isOpen={isImportWizardOpen}
        onClose={() => setIsImportWizardOpen(false)}
        zoneId={zoneId}
        onImport={handleImportItems}
      />
    </Box>
  );
};

export default ZoneListPage;
//* react
import React from "react";

//* material-ui
import { Box as MuiBox, ThemeProvider } from "@mui/material";
import { Button, VStack, Box, useToast, Switch } from "@chakra-ui/react";
import StyledFormControlLabel from "components/StyledFormControlLabel";

//* types
import {
  GridColDef,
  GridColType,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridFilterModel,
  GridRowSelectionModel,
  GridSingleSelectColDef,
  GridToolbar,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  CalculationOption,
  ColumnOrder,
  Columns,
  ColumnTypes,
  SelectOption,
  ColumnSizing,
  LabelOption,
  AddColumnType,
  ColumnNames,
  MultiSelectOption,
} from "types/zone-datagrid";

//* system
import { DibiDataGrid, muiTheme } from "views/admin/main/zones/MuiTheme";
import TableHeaderRenderer from "./TableHeaderRenderer";
import AddColumnHeader from "components/AddColumnHeader";
import CustomColumnMenu from "./ColumnMenu";
import CustomFooter from "./Footer";
import CenteredPopover from "components/CenteredPopover";
import FieldsEditor from "components/FieldsEditor";
import CalculationEditor from "components/CalculationEditor";
import LabelOptionEditor from "components/fields/LabelOptionEditor";
import LabelCell from "components/fields/LabelCellMui";
import LabelField from "components/fields/LabelFieldMui";
import Card from "components/card/Card";
import ImportWizard from "views/admin/main/zones/ImportWizard";
import { isValid } from "date-fns";
import CardHeaderView from "./CardHeaderView";
import MultiSelectCell from "components/fields/MultiSelectCellMui";
import MultiSelectField from "components/fields/MultiSelectFieldMui";

interface ZoneDataGridProps {
  reportResult?: null | any;
  zoneId?: string;
  rows?: any[];
  columns?: Columns;
  columnNames?: ColumnNames;
  columnTypes?: ColumnTypes;
  columnOrder?: ColumnOrder;
  columnWidths?: ColumnSizing;
  selectOptions?: {
    [key: string]: SelectOption;
  };
  onRenameColumn?: (oldField: string, newHeader: string) => void;
  onProcessRowUpdateData?: (
    rowIndex: number,
    columnId: string,
    value: any,
  ) => void;
  onColumnOrderChange?: (newOrder: ColumnOrder) => void;
  onColumnResize?: (columnId: string, width: number) => void;
  onAddColumn?: (type: AddColumnType) => void;
  onRowAdd?: () => void;
  onDeleteColumn?: (columnName: string) => void;
  manageSelectOptions?: (columnId: string, options: string[] | MultiSelectOption) => void;
  manageLabelsOptions?: (columnId: string, options: LabelOption[]) => void;
  onUpdateCalculationOptions?: (
    columnId: string,
    options: CalculationOption,
  ) => void;
  onImportData?: (
    cleanCurrentData: any[],
    processedImportedData: any[],
    newColumns: {
      name: string;
      type: string;
    }[],
    updatedColumns: string[],
  ) => void;
  onDeleteSelectedRows?: (newData: any[]) => void;
  onDuplicateSelectedRows?: (newData: any[]) => void;
  onExportSelectedRows?: (selectedData: any[]) => void;
  isAddNewRow?: boolean;
  isAddNewColumn?: boolean;
  columnsEditable?: boolean;
  isImportExcel?: boolean;
}

const DEFAULT_COLUMN_WIDTH = 150;

// Helper function to check if an array is string[]
function isStringArray(arr: any[]): arr is string[] {
  return arr.every(item => typeof item === 'string');
}

const ZoneDataGrid: React.FC<ZoneDataGridProps> = React.memo(
  ({
    zoneId = undefined,
    reportResult = null,
    rows = [],
    columns = [],
    columnNames = {},
    columnTypes = {},
    columnOrder = [],
    columnWidths = {},
    selectOptions = {},
    onRenameColumn = () => {},
    onProcessRowUpdateData = () => {},
    onColumnOrderChange = () => {},
    onColumnResize = () => {},
    onAddColumn = () => {},
    onRowAdd = () => {},
    onDeleteColumn = () => {},
    manageSelectOptions = () => {},
    manageLabelsOptions = () => {},
    onUpdateCalculationOptions = () => {},
    onImportData = () => {},
    onDeleteSelectedRows = () => {},
    onDuplicateSelectedRows = () => {},
    onExportSelectedRows = () => {},
    isAddNewRow = true,
    isAddNewColumn = true,
    columnsEditable = true,
    isImportExcel = true,
  }) => {
    //* data & states
    const [editingColumn, setEditingColumn] = React.useState<string | null>(
      null,
    );
    const [rowSelectionModel, setRowSelectionModel] =
      React.useState<GridRowSelectionModel>([]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
      items: [],
      quickFilterValues: [],
    });
    const [activePopover, setActivePopover] = React.useState<{
      id: string | null;
      type:
        | "columnOptions"
        | "editSelectOptions"
        | "editCalculation"
        | "editLabel"
        | null;
    }>({ id: null, type: null });
    const [isEditingLabel, setIsEditingLabel] = React.useState<boolean>(false);

    //* Card header
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [isImportWizardOpen, setIsImportWizardOpen] =
      React.useState<boolean>(false);
    const toast = useToast();

    //* handlers
    const removeDuplicates = (arr: string[]): string[] => {
      return Array.from(new Set(arr));
    };
    const handleColumnReorder = React.useCallback(
      (params: GridColumnOrderChangeParams) => {
        if (!params.column || params.oldIndex === params.targetIndex) return;

        const newOrder = [...columnOrder];
        const [movedColumn] = newOrder.splice(params.oldIndex - 1, 1); // Remove column from old index
        newOrder.splice(params.targetIndex - 1, 0, movedColumn); // Insert column at new index

        onColumnOrderChange(newOrder);
      },
      [columnOrder, onColumnOrderChange],
    );
    const handleColumnResize = React.useCallback(
      (params: GridColumnResizeParams) => {
        onColumnResize(params.colDef.field, params.width);
      },
      [onColumnResize],
    );

    //* excel import
    const processDateValue = (value: any): string | null => {
      //console.log('processDateValue input:', value, 'type:', typeof value);

      if (!value) {
        //console.log('processDateValue: null/empty value');
        return null;
      }

      try {
        // If already in correct format
        if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
          //console.log('processDateValue: already in correct format:', value);
          const date = new Date(value);
          return isValid(date) ? value : null;
        }

        // Try to parse the date
        const date = new Date(value);
        //console.log('processDateValue: parsed date:', date, 'isValid:', isValid(date));

        if (isValid(date)) {
          const result = date.toISOString().split("T")[0];
          //console.log('processDateValue: formatted result:', result);
          return result;
        }

        //console.log('processDateValue: failed to parse date');
        return null;
      } catch (error) {
        console.warn("processDateValue error:", error, "for value:", value);
        return null;
      }
    };
    const processImport = (
      importedData: any[],
      newColumns: {
        name: string;
        type: string;
      }[],
      updatedColumns: string[],
    ) => {
      const cleanCurrentData = processedRows.filter((row) =>
        Object.values(row).some(
          (value) => value !== null && value !== "" && value !== undefined,
        ),
      );

      const processedImportedData = importedData.map((row, index) => {
        //console.log(`Processing row ${index}:`, row);
        const processedRow = { ...row };
        Object.entries(row).forEach(([key, value]) => {
          if (columnTypes[key] === "date") {
            //console.log(`Processing date field '${key}':`, value);
            processedRow[key] = processDateValue(value);
            //console.log(`Processed date result:`, processedRow[key]);
          }
        });
        return processedRow;
      });

      console.log("Processed imported data:", processedImportedData);

      onImportData(
        cleanCurrentData,
        processedImportedData,
        newColumns,
        updatedColumns,
      );
    };

    //* rows
    const processedRows = React.useMemo(() => {
      const dataWithIDs = rows.map((row, index) => ({ ...row, id: index }));
      return dataWithIDs;
    }, [rows]);

    const filteredRows = React.useMemo(() => {
      if (!searchTerm || searchTerm === "") return processedRows;
      return processedRows.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }, [searchTerm, processedRows]);

    const handleDeleteSelectedRows = () => {
      const selectedCount = rowSelectionModel.length;
      const newData = processedRows.filter(
        (_, index) => !rowSelectionModel.includes(index),
      );
      setRowSelectionModel([]);
      toast({
        title: "Rows Deleted",
        description: `${selectedCount} row(s) have been deleted`,
        status: "info",
        duration: 3000,
        isClosable: true,
      });

      onDeleteSelectedRows(newData);
    };
    const handleDuplicateSelectedRows = () => {
      const newData = [...processedRows];
      const selectedIndices = rowSelectionModel
        .map(Number)
        .sort((a, b) => a - b);

      console.log(selectedIndices);

      let offset = 0;
      selectedIndices.forEach((index) => {
        const adjustedIndex = index + offset;
        console.log(adjustedIndex);
        const rowToDuplicate = newData[adjustedIndex];
        newData.splice(adjustedIndex + 1, 0, { ...rowToDuplicate });
        offset++;
      });
      setRowSelectionModel([]);
      toast({
        title: "Rows Duplicated",
        description: `${selectedIndices.length} row(s) have been duplicated`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onDuplicateSelectedRows(newData);
    };
    const handleExportSelectedRows = () => {
      const selectedData = processedRows.filter((_, index) =>
        rowSelectionModel.includes(index),
      );
      setRowSelectionModel([]);

      onExportSelectedRows(selectedData);
    };

    //* columns
    const colTypeByObj: {
      [key: string]: GridColType;
    } = React.useMemo(
      () => ({
        text: "string",
        label: "singleSelect",
        select: "singleSelect",
        number: "number",
        date: columnsEditable ? "date" : "string",
        calculation: columnsEditable ? "number" : "string",
      }),
      [columnsEditable],
    );

    const dataGridColumns: GridColDef[] = React.useMemo(
      () =>
        columns.map((column) => {
          const originalColType = columnTypes[column];
          const colType: GridColType = colTypeByObj[originalColType];
          const headerName = columnNames[column] || column;

          const gridCol: GridColDef = {
            field: column,
            headerName: headerName,
            width: columnWidths[column] || DEFAULT_COLUMN_WIDTH,
            type: colType,
            editable: columnsEditable && originalColType !== "calculation",
            sortable: true,
            resizable: true,
            valueGetter: (value, row) => {
              if (colType === "date" && columnsEditable) {
                return value && new Date(value);
              }
              if (originalColType === "calculation" && columnsEditable) {
                const calculateValue = () => {
                  const option = selectOptions[column] as CalculationOption;
                  const formula = option?.formula;
                  const fields = option?.fields;
                  const operators = option?.operators;

                  if (!formula || !fields || !operators) return "N/A";

                  let expression = formula;

                  fields.forEach((field) => {
                    const fieldValue =
                      field in row
                        ? parseFloat(row[field] || "0")
                        : parseFloat(field);

                    if (isNaN(fieldValue)) {
                      //console.warn(`Invalid field value for ${field}:`, row.original[field]);
                      return "Error";
                    }

                    expression = expression.replace(
                      `{${field}}`,
                      fieldValue.toString(),
                    );
                  });

                  // Evaluate the final expression
                  try {
                    // eslint-disable-next-line no-eval
                    const result = eval(expression);
                    if (isNaN(result)) return "Error";

                    // Format the result
                    const option = selectOptions[column] as SelectOption;
                    if ("formatAsInteger" in option && option.formatAsInteger) {
                      return Math.round(result).toString();
                    } else {
                      return (
                        Number.isInteger(result)
                          ? result
                          : parseFloat(result.toFixed(2))
                      ).toString();
                    }
                  } catch (error) {
                    //console.error('Error evaluating formula:', error);
                    return "Error";
                  }
                };

                return calculateValue();
              }

              return value || "";
            },
            renderHeader: (params) => (
              <TableHeaderRenderer
                params={params}
                editingColumn={editingColumn}
                handleRenameColumn={(oldField, newHeader) => {
                  onRenameColumn(oldField, newHeader);
                  setEditingColumn(null);
                }}
              />
            ),
            headerAlign: "left",
          };
          
          // Check if this is a multi-select column
          const isMultiSelect = 
            originalColType === "select" && 
            typeof selectOptions[column] === "object" && 
            !Array.isArray(selectOptions[column]) && 
            (selectOptions[column] as any)?.isMultiSelect === true;

          if (colType === "singleSelect" && originalColType === "select") {
            // Get options for select fields
            let options: string[] = [];
            const optionsData = selectOptions[column];
            
            if (Array.isArray(optionsData) && isStringArray(optionsData)) {
              options = optionsData;
            } else if (
              typeof optionsData === "object" && 
              optionsData !== null && 
              "options" in optionsData &&
              Array.isArray(optionsData.options)
            ) {
              options = optionsData.options;
            }
            
            // Force type cast to avoid TypeScript errors
            (gridCol as any).valueOptions = options;
            
            // If it's a multi-select column, add custom cell and edit cell renderers
            if (isMultiSelect) {
              // Override to remove the singleSelect type since we're customizing it
              gridCol.type = undefined;
              
              // Custom cell renderer to show chips
              gridCol.renderCell = (params) => (
                <Box
                  display="flex"
                  alignItems="center"
                  height="100%"
                >
                  <MultiSelectCell
                    value={params.value}
                    options={options}
                  />
                </Box>
              );
              
              // Custom edit cell renderer for multi-select
              gridCol.renderEditCell = (params) => {
                return (
                  <MultiSelectField
                    value={params.value}
                    onChange={(newValue) =>
                      params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: newValue,
                      })
                    }
                    options={options}
                    api={params.api}
                    id={params.id}
                    field={params.field}
                  />
                );
              };
            }
          }
          if (originalColType === "label") {
            gridCol.renderCell = (params) => (
              <Box
                display="flex"
                alignItems="center"
                height="100%"
              >
                <LabelCell
                  value={params.value}
                  options={selectOptions[params.field] as LabelOption[]}
                />
              </Box>
            );
            (gridCol as GridSingleSelectColDef).valueOptions =
              selectOptions[column] && Array.isArray(selectOptions[column])
                ? (selectOptions[column] as LabelOption[]).map(
                    (option: any) => ({
                      value: option.label,
                      label: option.label,
                    }),
                  )
                : [];
            gridCol.renderEditCell = (params) => {
              return (
                <LabelField
                  value={params.value}
                  onChange={(newValue) =>
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: newValue,
                    })
                  }
                  options={selectOptions[params.field] as LabelOption[]}
                  api={params.api}
                  id={params.id}
                  field={params.field}
                />
              );
            };
          }

          return gridCol;
        }),
      [
        columns,
        columnTypes,
        colTypeByObj,
        columnNames,
        columnWidths,
        columnsEditable,
        selectOptions,
        editingColumn,
        onRenameColumn,
      ],
    );
    const sortedDataGridColumns = React.useMemo(() => {
      // return columnOrder
      //   .map((field) => dataGridColumns.find((col) => col.field === field)) // Match columns in order
      //   .filter(Boolean) as GridColDef[]; // Remove any undefined entries
      const uniqueOrder = removeDuplicates(columnOrder);

      // Filter columns to get only ones that exist at uniqueOrder
      const filteredColumns = dataGridColumns.filter((col) =>
        uniqueOrder.includes(col.field),
      );

      // Sort columns with uniqueOrder
      filteredColumns.sort(
        (a, b) => uniqueOrder.indexOf(a.field) - uniqueOrder.indexOf(b.field),
      );

      return filteredColumns;
    }, [columnOrder, dataGridColumns]);

    React.useEffect(() => {
      // Clear selection when zone ID changes
      return () => {
        setRowSelectionModel([]);
      };
    }, [zoneId]);

    return (
      <Box
        pt={{
          base: reportResult ? "20px" : "130px",
          md: reportResult ? "20px" : "80px",
          xl: reportResult ? "20px" : "80px",
        }}
        // height={
        //   reportResult ? "calc(100vh - 190px)" : "calc(100vh - 50px)"
        // }
        height={"100%"}
        overflow={"hidden"}
      >
        <Card
          height="100%"
          display="flex"
          flexDirection="column"
          p={{
            base: reportResult ? "0px" : "20px",
            md: reportResult ? "0px" : "20px",
            xl: reportResult ? "0px" : "20px",
          }}
        >
          <CardHeaderView
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filteredRows={filteredRows}
            rowSelectionModel={rowSelectionModel}
            handleDeleteSelectedRows={handleDeleteSelectedRows}
            handleDuplicateSelectedRows={handleDuplicateSelectedRows}
            handleExportSelectedRows={handleExportSelectedRows}
            setIsImportWizardOpen={setIsImportWizardOpen}
            isImportExcel={isImportExcel}
          />

          <ThemeProvider theme={muiTheme}>
            <DibiDataGrid
              rows={filteredRows}
              columns={sortedDataGridColumns}
              initialState={{
                density: "compact",
              }}
              processRowUpdate={(
                updatedRow: GridValidRowModel,
                originalRow,
              ) => {
                const rowIndex = processedRows.findIndex(
                  (row) => row.id === originalRow.id,
                );
                if (rowIndex !== -1) {
                  Object.entries(updatedRow).forEach(([columnId, value]) => {
                    if (value !== originalRow[columnId]) {
                      onProcessRowUpdateData(rowIndex, columnId, value);
                    }
                  });
                }
                return updatedRow;
              }}
              checkboxSelection
              disableRowSelectionOnClick
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={(newRowSelectionModel) =>
                setRowSelectionModel(newRowSelectionModel)
              }
              onColumnOrderChange={handleColumnReorder}
              onColumnResize={handleColumnResize}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) =>
                setFilterModel(newFilterModel)
              }
              slots={{
                toolbar: ({ csvOptions, printOptions, ...props }) => (
                  <MuiBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    zIndex={10}
                  >
                    <GridToolbar
                      {...props}
                      csvOptions={{ disableToolbarButton: true }}
                      printOptions={{ disableToolbarButton: true }}
                      showQuickFilter={false}
                    />
                    {isAddNewColumn && (
                      <AddColumnHeader
                        onAddColumn={(type) =>
                          onAddColumn(type as AddColumnType)
                        }
                      />
                    )}
                  </MuiBox>
                ),
                columnMenu: (props) => (
                  <CustomColumnMenu
                    props={props}
                    setActivePopover={setActivePopover}
                    columnTypes={columnTypes}
                    setIsEditingLabel={setIsEditingLabel}
                    deleteColumn={onDeleteColumn}
                    setEditingColumn={setEditingColumn}
                    selectOptions={selectOptions}
                  />
                ),
                footer: isAddNewRow ? CustomFooter : () => <></>,
              }}
              slotProps={{
                footer: {
                  addRow: onRowAdd,
                },
              }}
            />
          </ThemeProvider>

          {isImportExcel && (
            <ImportWizard
              isOpen={isImportWizardOpen}
              onClose={() => setIsImportWizardOpen(false)}
              columns={columns}
              onImport={processImport}
            />
          )}

          {/* Table header popovers */}
          {/* Edit select options */}
          <CenteredPopover
            isOpen={
              activePopover.type === "editSelectOptions" && !!activePopover.id
            }
            onClose={() => setActivePopover({ id: null, type: null })}
            title="Edit Select Options"
          >
            <VStack
              align="stretch"
              spacing={3}
            >
              {/* Add multi-select toggle */}
              <StyledFormControlLabel
                control={
                  <Switch
                    isChecked={
                      typeof selectOptions[activePopover.id] === "object" &&
                      !Array.isArray(selectOptions[activePopover.id]) &&
                      (selectOptions[activePopover.id] as any)?.isMultiSelect === true
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const currentOptions = selectOptions[activePopover.id];
                      let options: string[] = [];
                      
                      // Extract the current options
                      if (Array.isArray(currentOptions) && isStringArray(currentOptions)) {
                        options = currentOptions;
                      } else if (
                        typeof currentOptions === "object" &&
                        currentOptions !== null &&
                        "options" in currentOptions &&
                        Array.isArray(currentOptions.options)
                      ) {
                        options = currentOptions.options;
                      }
                      
                      // Create the new select options structure
                      if (isChecked) {
                        // Cast to any to avoid type errors
                        const multiSelectOption = {
                          options,
                          isMultiSelect: true
                        };
                        manageSelectOptions(activePopover.id, multiSelectOption as any);
                      } else {
                        manageSelectOptions(activePopover.id, options);
                      }
                    }}
                  />
                }
                label="Multi-select"
              />
              
              <FieldsEditor
                field={(() => {
                  const options = selectOptions[activePopover.id];
                  if (Array.isArray(options)) {
                    return options.join("\n");
                  } else if (typeof options === "object" && options !== null) {
                    if ("fields" in options && Array.isArray(options.fields)) {
                      return options.fields.join("\n");
                    } else if ("formula" in options) {
                      return options.formula || "";
                    } else if ("options" in options && Array.isArray(options.options)) {
                      return options.options.join("\n");
                    }
                  }
                  return "";
                })()}
                updateFieldOptionsTable={(columnId, newOptions) => {
                  // Preserve multi-select setting if it exists
                  const currentOptions = selectOptions[columnId];
                  if (
                    typeof currentOptions === "object" &&
                    !Array.isArray(currentOptions) &&
                    (currentOptions as any)?.isMultiSelect === true
                  ) {
                    // Cast to any to avoid type errors
                    const multiSelectOption = {
                      options: newOptions,
                      isMultiSelect: true
                    };
                    manageSelectOptions(columnId, multiSelectOption as any);
                  } else {
                    manageSelectOptions(columnId, newOptions);
                  }
                }}
                valId={activePopover.id}
                size="sm"
                id={`text-area-${Math.random() * 9999}`}
                type="table"
              />
              <Button
                onClick={() => setActivePopover({ id: null, type: null })}
                size="sm"
                variant="brand"
                fontWeight="normal"
              >
                Save and close
              </Button>
            </VStack>
          </CenteredPopover>
          {/* Edit calculation */}
          <CenteredPopover
            isOpen={
              activePopover.type === "editCalculation" && !!activePopover.id
            }
            onClose={() => setActivePopover({ id: null, type: null })}
            title="Set Calculation"
          >
            <CalculationEditor
              field={{
                id: activePopover.id,
                options:
                  columnTypes[activePopover.id] === "calculation"
                    ? (selectOptions[activePopover.id] as any) || {
                        formula: "",
                        fields: [],
                      }
                    : { formula: "", fields: [] },
              }}
              allFields={columns.map((col) => ({
                id: col,
                label: columnNames?.[col] || col,
              }))}
              updateFieldOptions={(_, __, newOptions) => {
                onUpdateCalculationOptions(activePopover.id, newOptions);
              }}
              side="left"
              onClose={() => setActivePopover({ id: null, type: null })}
            />
          </CenteredPopover>
          {/* Edit labels */}
          <CenteredPopover
            isOpen={isEditingLabel && !!activePopover.id}
            onClose={() => setIsEditingLabel(false)}
            title="Edit Label Options"
          >
            <LabelOptionEditor
              options={
                selectOptions[activePopover.id] as unknown as {
                  label: string;
                  color: string;
                }[]
              }
              onChange={(options: any) => {
                manageLabelsOptions(activePopover.id, options);
                setActivePopover({ id: null, type: null });
                setIsEditingLabel(false);
              }}
            />
            <Button
              onClick={() => setIsEditingLabel(false)}
              size="sm"
              mt={4}
            >
              Close
            </Button>
          </CenteredPopover>
          {/* // Table header popovers // */}
        </Card>
      </Box>
    );
  },
);

export default ZoneDataGrid;

import React from 'react';
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  VStack,
  Text,
  Icon,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { AddIcon, CalendarIcon } from '@chakra-ui/icons';
import { BsWindow } from 'react-icons/bs'; 
import { FaFont, FaHashtag, FaList, FaLink, FaCheckSquare, FaFlag, FaCalculator, FaAlignLeft, FaFileUpload, FaToggleOn, FaDollarSign } from 'react-icons/fa';

interface FieldCreationButtonProps {
  side: "right" | "left" | "center";
  isEditable: boolean;
  leftPopoverOpen: boolean;
  centerPopoverOpen: boolean;
  rightPopoverOpen: boolean; 
  setLeftPopoverOpen: (isOpen: boolean) => void; 
  setCenterPopoverOpen: (isOpen: boolean) => void; 
  setRightPopoverOpen: (isOpen: boolean) => void; 
  addField: (side: 'left' | 'center' | 'right', fieldType: string) => void; 
}

const fieldTypes = [
  { name: 'Text', icon: FaFont, color: 'orange.500' },
  { name: 'Number', icon: FaHashtag, color: 'brand.500' },
  { name: 'Select', icon: FaList, color: 'horizonYellow.500' },
  { name: 'Relations', icon: FaLink, color: 'horizonYellow.600' },
  { name: 'Date', icon: CalendarIcon, color: 'red.500' },
  { name: 'Checkbox List', icon: FaCheckSquare, color: 'brand.300' },
  { name: 'Label', icon: FaFlag , color: 'orange.400' },
  { name: 'Calculation', icon: FaCalculator, color: 'purple.500' },
  { name: 'Price', icon: FaDollarSign, color: 'green.700' },
  { name: 'Text Area', icon: FaAlignLeft, color: 'teal.500' },
  { name: 'File', icon: FaFileUpload, color: 'cyan.500' },
  { name: 'Iframe', icon: BsWindow, color: 'blue.500' },
  { name: 'TrueFalse', icon: FaToggleOn, color: 'green.500' },

];

const FieldCreationButton: React.FC<FieldCreationButtonProps> = ({
  side,
  isEditable,
  leftPopoverOpen,
  centerPopoverOpen,
  rightPopoverOpen,
  setLeftPopoverOpen,
  setCenterPopoverOpen,
  setRightPopoverOpen,
  addField,
}) => {
  const isOpen = side === 'left' ? leftPopoverOpen : (side === 'center' ? centerPopoverOpen : rightPopoverOpen);
  const setIsOpen = side === 'left' ? setLeftPopoverOpen : (side === 'center' ? setCenterPopoverOpen : setRightPopoverOpen);

  if (!isEditable) return null;

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Button
          leftIcon={<AddIcon />}
          onClick={() => setIsOpen(true)}
          variant="outline"
          borderStyle="dashed"
          borderColor="gray.300"
          color="gray.500"
          fontWeight="normal"
          _hover={{ bg: 'gray.50', color: 'gray.700' }}
          width="100%"
          justifyContent="flex-start"
          py={5}
        >
          New Field
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width="350px"
        boxShadow="md"
        p={0}
        border="1px"
        borderColor="gray.200"
      >
        <Flex>
          <Box width="100%">
            <Text p={3} fontWeight="medium" color="grey.500" fontSize="sm">
              Select field type
            </Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={2} p={3}>
              {fieldTypes.map((type) => (
                <GridItem key={type.name}>
                  <Button
                    onClick={() => {
                      addField(side, type.name);
                      setIsOpen(false);
                    }}
                    justifyContent="flex-start"
                    variant="ghost"
                    h="33px"
                    fontSize="sm"
                    color="gray.700"
                    borderRadius="md"
                    width="100%"
                    _hover={{ bg: 'gray.200' }}
                  >
                    <Flex align="center">
                      <Box
                        bg={type.color}
                        w="24px"
                        h="24px"
                        borderRadius="sm"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={3}
                      >
                        <Icon as={type.icon} color="white" boxSize={4} />
                      </Box>
                      <Text fontWeight="medium">{type.name}</Text>
                    </Flex>
                  </Button>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Flex>
      </PopoverContent>
    </Popover>
  );
};

export default FieldCreationButton;
import React, { useState, useEffect } from 'react';
import {
  Select,
  Popover,
  PopoverContent,
  PopoverBody,
  Text,
  useDisclosure,
  VStack,
  Box,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { zoneService } from 'services/zoneService';
import { Field } from '../types/Field';
import { useAuth } from 'contexts/AuthContext';
import CenteredPopover from 'components/CenteredPopover';
import { MdInsertDriveFile, MdImage, MdPictureAsPdf } from 'react-icons/md';
import { SiMicrosoftexcel, SiMicrosoftword } from 'react-icons/si';
import { IconType } from 'react-icons';

interface RowData {
  [key: string]: any;
}

interface RelationsFieldProps {
  field: Field;
  updateFieldOptions: (side: 'left' | 'right', fieldId: string, newOptions: any) => void;
  side: 'left' | 'right';
  isEditable: boolean;
  // isTable: boolean;
  itemData: any;
  handleTabFields: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isConfigOpen: boolean;
  onConfigClose: () => void;
}

interface RelationsFieldOptions {
  zoneId: string;
  options: string[];
  value?: string;
}

interface ZoneItemData {
  data: {
    [key: string]: {
      value: any;
    };
  };
}

interface ZoneItemContent {
  data: {
    [key: string]: {
      value: any;
      key?: string;
    };
  };
  _id: string;
}

interface TabInfo {
  id: string;
  name?: string;
  label?: string;
}

const getFileIcon = (fileUrl: string): IconType => {
  if (typeof fileUrl !== 'string' || fileUrl.length === 0) {
    return MdInsertDriveFile;
  }
  const parts = fileUrl.split('.');
  if (parts.length < 2) {
    return MdInsertDriveFile;
  }
  const extension = parts[parts.length - 1].toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return MdImage;
    case 'pdf':
      return MdPictureAsPdf;
    case 'doc':
    case 'docx':
      return SiMicrosoftword;
    case 'xls':
    case 'xlsx':
      return SiMicrosoftexcel;
    default:
      return MdInsertDriveFile;
  }
};

const formatValue = (value: any) => {
  if (typeof value === 'string' && value.startsWith('[')) {
    try {
      // First try to parse as JSON
      const parsedArray = JSON.parse(value);
      if (Array.isArray(parsedArray)) {
        // Clean each array item and join
        return parsedArray
          .map(item => item.toString().trim())
          .filter(Boolean)
          .join(', ');
      }
    } catch {
      // If JSON parsing fails, use string manipulation
      return value
        .slice(1, -1)  // Remove first and last brackets
        .split(',')    // Split into array
        .map(item => item
          .trim()
          .replace(/["\[\]]/g, '')  // Remove quotes and any remaining brackets
        )
        .filter(Boolean)  // Remove empty items
        .join(', ');     // Join with comma and space
    }
  }
  return value;
};

const cleanArrayString = (value: any): any => {
  // If it's already a proper array, join it with commas
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  
  // If it's a string that looks like an array
  if (typeof value === 'string' && value.trim().startsWith('[')) {
    try {
      // Try parsing it as JSON first
      const parsed = JSON.parse(value);
      if (Array.isArray(parsed)) {
        return parsed.join(', ');
      }
    } catch {
      // If JSON parsing fails, do manual string cleanup
      return value
        .trim()
        .slice(1, -1)  // Remove [ ]
        .split(',')
        .map(item => item.trim().replace(/"/g, ''))
        .join(', ');
    }
  }
  
  // Return original value if it's not an array
  return value;
};

const RelationsField: React.FC<RelationsFieldProps> = ({ field, updateFieldOptions, side, isEditable, itemData, handleTabFields, isConfigOpen, onConfigClose }) => {
  const [selectedRowData, setSelectedRowData] = useState<RowData | null>(null);
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();
  const [columnHeaders, setColumnHeaders] = useState<string[]>([]);

  const [zones, setZones] = useState<{ _id: string, name: string }[]>([]);
  const [selectedZone, setSelectedZone] = useState<string>((field.options as RelationsFieldOptions)?.zoneId || '');
  const [options, setOptions] = useState<string[]>((field.options as RelationsFieldOptions)?.options || []);
  const { isOpen, onClose } = useDisclosure({ isOpen: isConfigOpen, onClose: onConfigClose });
  const companyId = useAuth().user.companyId;
  const userType = useAuth().user.role;
  const userEmail = useAuth().user.email;

  const [tabsData, setTabsData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<string>('');

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchZoneItems(selectedZone);
    }
  }, [selectedZone]);

  const fetchZones = async () => {
    try {
      const allZones = await zoneService.getAllZones(companyId, userType, userEmail);
      setZones(allZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchZoneItems = async (zoneId: string) => {
    try {
      const zoneData = await zoneService.getZoneById(zoneId);
      if (!zoneData?.data) return;

      let firstColumnOptions: string[] = [];

      if (zoneData.data.type === 'table') {
        // Handle table zone type (existing logic)
        if (zoneData.data.data && Object.keys(zoneData.data.data).length > 0) {
          const firstColumnKey = Object.keys(zoneData.data.data[0])[0];
          firstColumnOptions = zoneData.data.data.map((item: any) => item[firstColumnKey]);
        }
      } else if (zoneData.data.type === 'tabs') {
        // Handle tabs zone type
        const items = await zoneService.getZoneItems(zoneId);
        firstColumnOptions = await Promise.all(
          items.map(async (item: any) => {
            const fullItem = await zoneService.getZoneItemById(zoneId, item._id, false);
            // Find the first field in the first tab with label containing "Title" or "Name"
            const titleField = Object.entries(fullItem.data as Record<string, { value: any }>).find(([_, content]) => {
              const fieldData = zoneData.data.fields?.find((f: any) => f.id === _);
              return fieldData?.label?.toLowerCase().includes('title') || 
                     fieldData?.label?.toLowerCase().includes('name');
            });
            return titleField ? (titleField[1] as { value: any }).value : item._id;
          })
        );
      }

      setOptions(firstColumnOptions);
      updateFieldOptions(side, field.id, {
        zoneId,
        options: firstColumnOptions,
        value: (field.options as RelationsFieldOptions)?.value || ''
      });
    } catch (error) {
      console.error('Error fetching zone items:', error);
      setOptions([]);
    }
  };

  const handleZoneSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const zoneId = e.target.value;
    setSelectedZone(zoneId);
    fetchZoneItems(zoneId);
    onClose();
  };

  const fetchRowData = async (zoneId: string, value: string) => {
    try {
      const zoneData = await zoneService.getZoneById(zoneId);
      if (!zoneData?.data) return;

      if (zoneData.data.type === 'table') {
        if (zoneData.data.data) {
          const firstColumnKey = Object.keys(zoneData.data.data[0])[0];
          const selectedRow = zoneData.data.data.find((row: any) => row[firstColumnKey] === value);
          if (selectedRow) {
            // Convert the row data to match the same structure as tabs data
            const formattedData = Object.entries(selectedRow).reduce((acc: any, [key, value]: [string, any]) => {
              acc[key] = { 
                value: cleanArrayString(value)
              };
              return acc;
            }, {});
            
            setSelectedRowData(formattedData);
            setColumnHeaders(Object.keys(formattedData));
          }
        }
      } else if (zoneData.data.type === 'tabs') {
        // Handle tabs zone type
        const items = await zoneService.getZoneItems(zoneId);
        let foundItem = null;
        
        // Use a for...of loop to handle async operations
        for (const item of items) {
          const fullItem = await zoneService.getZoneItemById(zoneId, item._id, false);
          if (Object.values(fullItem.data).some((content: any) => content.value === value)) {
            foundItem = item;
            break;
          }
        }

        if (foundItem) {
          // Get all tabs data
          const tabsInfo = zoneData.data.tabs?.map((tab: TabInfo) => ({
            id: tab.id,
            name: tab.name,
            label: tab.label
          })) || [];
          setTabsData(tabsInfo);
          setActiveTab(tabsInfo[0]?.id || '');

          const fullItem = await zoneService.getZoneItemById(zoneId, foundItem._id, false);
          const formattedData = Object.entries(fullItem.data).reduce((acc: any, [key, content]: [string, any]) => {
            const fieldData = zoneData.data.fields?.find((f: any) => f.id === key);
            if (fieldData) {
              acc[fieldData.label] = { 
                value: cleanArrayString(content.value),
                tabId: fieldData.tabId 
              };
            }
            return acc;
          }, {});
          
          setSelectedRowData(formattedData);
          setColumnHeaders(Object.keys(formattedData));
        }
      }
    } catch (error) {
      console.error('Error fetching row data:', error);
    }
  };

  const handleDownload = async (fileUrl: string) => {
    try {
      const fullFileName = fileUrl.split('/').pop() || 'Uploaded File';
      const displayFileName = fullFileName.split('-').slice(1).join('-');
      const companyId = fileUrl.split('/')[2];
      const downloadUrl = `${process.env.REACT_APP_API_URL}/api/company/download-file/${companyId}/${fullFileName}`;
      
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      if (!token) {
        console.error('Authentication error: Please log in again');
        return;
      }

      const response = await fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': '*/*',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`Download failed: ${response.status} ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = displayFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  return (
    <Box position="relative">
      <Flex alignItems="center">
        <Select
          placeholder="Select an option"
          value={itemData[field?.id]?.value || ""}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleTabFields(e as unknown as React.ChangeEvent<HTMLInputElement>)}
          name={field.id}
          disabled={!isEditable}
        >
          {options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </Select>
        {itemData[field?.id]?.value && (
          <IconButton
            color="orange.500"
            aria-label="Show details"
            icon={<InfoIcon />}
            size="md"
            ml={2}
            onClick={async () => {
              const currentValue = itemData[field?.id]?.value;
              if (selectedZone && currentValue) {
                await fetchRowData(selectedZone, currentValue);
              }
              openModal();
            }}
          />
        )}
      </Flex>

      <CenteredPopover
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
          setSelectedRowData(null);
          setColumnHeaders([]);
          setTabsData(null);
          setActiveTab('');
        }}
        title={selectedRowData && columnHeaders.length > 0 
          ? `${selectedRowData[columnHeaders[0]]?.value} Details`
          : "Related Row Details"}
        width="600px"
        maxWidth="600px"
      >
        <Box maxH="600px" overflowY="auto" width="100%">
          {tabsData ? (
            <Box>
              <Tabs colorScheme="orange" onChange={(index) => setActiveTab(tabsData[index].id)}>
                <TabList>
                  {tabsData.map((tab: any) => (
                    <Tab key={tab.id}>{tab.name || tab.label}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {tabsData.map((tab: any) => (
                    <TabPanel key={tab.id}>
                      <Table variant="simple" size="sm" width="100%">
                        <Tbody>
                          {selectedRowData && columnHeaders
                            .filter(header => selectedRowData[header].tabId === tab.id)
                            .map((header) => {
                              const cellValue = selectedRowData[header]?.value;
                              const isFileField = header.toLowerCase().includes('file');

                              return (
                                <Tr key={header}>
                                  <Td fontWeight="bold" width="30%">{header}</Td>
                                  <Td width="70%">
                                    {isFileField ? (
                                      typeof cellValue === 'string' ? (
                                        // Single file
                                        cellValue.startsWith('/uploads/') ? (
                                          <Flex alignItems="center">
                                            <Icon 
                                              as={getFileIcon(cellValue)}
                                              w={5} 
                                              h={5} 
                                              mr={2} 
                                              color="orange.500"
                                              cursor="pointer"
                                              onClick={() => handleDownload(cellValue)}
                                            />
                                            <Text 
                                              color="orange.500"
                                              cursor="pointer"
                                              onClick={() => handleDownload(cellValue)}
                                            >
                                              {cellValue.split('/').pop()?.split('-').slice(1).join('-')}
                                            </Text>
                                          </Flex>
                                        ) : cellValue
                                      ) : Array.isArray(cellValue) ? (
                                        // Multiple files
                                        <VStack align="flex-start" spacing={2}>
                                          {cellValue.map((file, index) => (
                                            <Flex key={index} alignItems="center">
                                              <Icon 
                                                as={getFileIcon(file)}
                                                w={5} 
                                                h={5} 
                                                mr={2} 
                                                color="orange.500"
                                                cursor="pointer"
                                                onClick={() => handleDownload(file)}
                                              />
                                              <Text 
                                                color="orange.500"
                                                cursor="pointer"
                                                onClick={() => handleDownload(file)}
                                              >
                                                {file.split('/').pop()?.split('-').slice(1).join('-')}
                                              </Text>
                                            </Flex>
                                          ))}
                                        </VStack>
                                      ) : cellValue
                                    ) : (
                                      // Non-file field
                                      typeof cellValue === 'object' && cellValue !== null ? 
                                        (cellValue.value !== undefined ? 
                                          (Array.isArray(cellValue.value) ? 
                                            cellValue.value.map((v: string) => v.replace(/[\[\]"]/g, '')).join(', ') : 
                                            typeof cellValue.value === 'string' && cellValue.value.startsWith('[') ?
                                              cellValue.value.slice(1, -1).replace(/"/g, '').split(',').join(', ') :
                                              cellValue.value
                                          ) : 
                                          JSON.stringify(cellValue)
                                        ) : 
                                        (Array.isArray(cellValue) ? 
                                          cellValue.map((v: string) => v.replace(/[\[\]"]/g, '')).join(', ') :
                                          typeof cellValue === 'string' && cellValue.startsWith('[') ?
                                            cellValue.slice(1, -1).replace(/"/g, '').split(',').join(', ') :
                                            cellValue
                                        )
                                    )}
                                  </Td>
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          ) : (
            <Table variant="simple" size="sm" width="100%">
              <Tbody>
                {selectedRowData && columnHeaders.map((header) => {
                  const cellValue = selectedRowData[header]?.value || selectedRowData[header];
                  const isFileField = header.toLowerCase().includes('file'); // Check if it's a file field

                  return (
                    <Tr key={header}>
                      <Td fontWeight="bold" width="30%">{header}</Td>
                      <Td width="70%">
                        {isFileField ? (
                          typeof cellValue === 'string' ? (
                            // Single file
                            cellValue.startsWith('/uploads/') ? (
                              <Flex alignItems="center">
                                <Icon 
                                  as={getFileIcon(cellValue)}
                                  w={5} 
                                  h={5} 
                                  mr={2} 
                                  color="orange.500"
                                  cursor="pointer"
                                  onClick={() => handleDownload(cellValue)}
                                />
                                <Text 
                                  color="orange.500"
                                  cursor="pointer"
                                  onClick={() => handleDownload(cellValue)}
                                >
                                  {cellValue.split('/').pop()?.split('-').slice(1).join('-')}
                                </Text>
                              </Flex>
                            ) : cellValue
                          ) : Array.isArray(cellValue) ? (
                            // Multiple files
                            <VStack align="flex-start" spacing={2}>
                              {cellValue.map((file, index) => (
                                <Flex key={index} alignItems="center">
                                  <Icon 
                                    as={getFileIcon(file)}
                                    w={5} 
                                    h={5} 
                                    mr={2} 
                                    color="orange.500"
                                    cursor="pointer"
                                    onClick={() => handleDownload(file)}
                                  />
                                  <Text 
                                    color="orange.500"
                                    cursor="pointer"
                                    onClick={() => handleDownload(file)}
                                  >
                                    {file.split('/').pop()?.split('-').slice(1).join('-')}
                                  </Text>
                                </Flex>
                              ))}
                            </VStack>
                          ) : cellValue
                        ) : (
                          // Non-file field
                          typeof cellValue === 'object' && cellValue !== null ? 
                            (cellValue.value !== undefined ? 
                              (Array.isArray(cellValue.value) ? 
                                cellValue.value.map((v: string) => v.replace(/[\[\]"]/g, '')).join(', ') : 
                                typeof cellValue.value === 'string' && cellValue.value.startsWith('[') ?
                                  cellValue.value.slice(1, -1).replace(/"/g, '').split(',').join(', ') :
                                  cellValue.value
                              ) : 
                              JSON.stringify(cellValue)
                            ) : 
                            (Array.isArray(cellValue) ? 
                              cellValue.map((v: string) => v.replace(/[\[\]"]/g, '')).join(', ') :
                              typeof cellValue === 'string' && cellValue.startsWith('[') ?
                                cellValue.slice(1, -1).replace(/"/g, '').split(',').join(', ') :
                                cellValue
                            )
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </Box>
      </CenteredPopover>

      {!isEditable && (
        <Popover isOpen={isOpen} onClose={onClose}>
          <PopoverContent>
            <PopoverBody>
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text mb={2} fontWeight="medium">Select a zone to relate:</Text>
                  <Text fontSize="sm" color="gray.600">
                    This will create a dropdown menu containing values from the first column of the selected zone. These values can be used to establish relationships between different zones.
                  </Text>
                </Box>
                <Select onChange={handleZoneSelect} value={selectedZone}>
                  <option value="">Select a zone</option>
                  {zones.map((zone) => (
                    <option key={zone._id} value={zone._id}>
                      {zone.name}
                    </option>
                  ))}
                </Select>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Box>
  );
};

export default RelationsField;
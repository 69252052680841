import React, { useMemo, useCallback } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
  SelectChangeEvent,
  Chip,
  lighten,
  useTheme,
} from "@mui/material";
import {
  GridRenderEditCellParams,
  GridApiCommon,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { getContrastYIQ } from "utils/helpers";
import { grey } from "@mui/material/colors";

interface LabelOption {
  label: string;
  color: string;
}

interface LabelFieldProps {
  options: LabelOption[];
  value: string;
  onChange: (value: string) => void;
  api: GridApiCommon;
  id: GridRowId;
  field: string;
  isDisabled?: boolean;
  variant?: "table" | "default";
}

// Add the getTextColor function near the top of the file
const getTextColor = (backgroundColor: string) => {
  // Convert hex to RGB
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  
  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
  // Return black for light colors, white for dark colors
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const LabelField: React.FC<LabelFieldProps> = React.memo(
  ({ options, value = "", onChange, api, id, field, isDisabled }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true); // Open immediately

    const selectedOption = useMemo(() => {
      if (!value)
        return { label: "Please select", color: theme.palette.grey[300] };
      return (
        options.find((option) => option.label === value) || {
          label: "Please select",
          color: theme.palette.grey[300],
        }
      );
    }, [value, options, theme]);

    const handleChange = useCallback(
      (event: SelectChangeEvent<string>) => {
        onChange(event.target.value);
        // **Do not** close select or edit mode here
      },
      [onChange],
    );

    return (
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        disabled={isDisabled}
        sx={{ height: "24px" }}
      >
        <Select
          value={value}
          onChange={handleChange}
          open={open} // Open immediately
          onOpen={() => setOpen(true)}
          onClose={(event) => {
            setOpen(false);
            api.stopCellEditMode({ id, field }); // **Exit edit mode only when closing select**
          }}
          displayEmpty
          autoFocus // Auto-focus for instant interaction
          sx={{ height: "24px" }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Chip
                label={selectedOption.label}
                sx={{
                  bgcolor: selectedOption.color,
                  color: getTextColor(selectedOption.color),
                }}
              />
              {/* {selectedOption.color && (
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      borderRadius: "50%",
                      backgroundColor: selectedOption.color,
                      marginRight: 1,
                    }}
                  />
                )}
                <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {selectedOption.label}
                </Typography> */}
            </Box>
          )}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.label}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label={option.label}
                  sx={{
                    bgcolor: option.color,
                    color: getTextColor(option.color),
                    height: '24px'
                  }}
                />
                {/* <Box
                    sx={{
                      width: 16,
                      height: 16,
                      borderRadius: "50%",
                      backgroundColor: option.color,
                      marginRight: 1,
                    }}
                  />
                  <Typography>{option.label}</Typography> */}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
  (prevProps, nextProps) =>
    prevProps.value === nextProps.value &&
    prevProps.isDisabled === nextProps.isDisabled &&
    JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options),
);

export default LabelField;

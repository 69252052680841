import React, { memo, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  Th,
  Popover,
  PopoverTrigger,
  Icon,
} from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

const ResizeHandle = styled(Box)`
  position: absolute;
  right: -4px;
  top: 0;
  height: 100%;
  width: 8px;
  background-color: transparent;
  cursor: col-resize;
  touch-action: none;
  opacity: 0;
  transition: opacity 0.15s ease;
  z-index: 1;
  transform: translateZ(0);
  will-change: opacity;

  &:hover, &.isResizing {
    background-color: var(--chakra-colors-orange-500);
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    transform: translateX(-50%);
    background-color: var(--chakra-colors-orange-500);
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  &:hover::after, &.isResizing::after {
    opacity: 1;
  }
`;

const StyledTh = styled(Th)`
  transition: width 0.1s ease;
  position: relative;
  user-select: none;

  &.resizing {
    cursor: col-resize;
    user-select: none;
  }
`;

const SortableHeader = memo(({
  header,
  children,
  borderColor,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  over,
  handleSort
}: {
  header: any;
  children: React.ReactNode;
  borderColor: string;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  over?: { id: string } | null;
  handleSort?: (columnId: string) => void;
}) => {
  const handleClick = useCallback((e: React.MouseEvent) => {
    if (handleSort && !(e.target as HTMLElement).closest('.drag-handle')) {
      handleSort(header.id);
    }
  }, [handleSort, header.id]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: header.id,
  });

  const style = useMemo(() => ({
    transform: CSS.Transform.toString(transform),
    transition,
  }), [transform, transition]);

  return (
    <StyledTh
      ref={setNodeRef}
      {...attributes}
      key={header.id}
      colSpan={header.colSpan}
      padding="0"
      borderColor={borderColor}
      borderWidth="1px"
      borderStyle="solid"
      width={header.getSize()}
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      fontSize="md"
      color="gray.700"
      bg={over?.id === header.id ? 'orange.50' : 'white'}
      transition="background-color 0.2s"
      style={style}
      onClick={handleClick}
      className={header.column.getIsResizing() ? 'resizing' : ''}
    >
      <Flex align="center" height="100%" p="8px">
        <Box
          {...listeners}
          cursor="grab"
          mr="4px"
          mb="3px"
          p="0px"
          borderRadius="4px"
          _hover={{ bg: "gray.100" }}
          opacity={isHovered ? 1 : 0}
          transition="opacity 0.1s ease-out"
        >
          <Icon as={DragHandleIcon} boxSize={3} color="gray.400" />
        </Box>
        <Flex flex="1" justify="space-between" align="center">
          {React.Children.map(children, child => {
            if (React.isValidElement(child) && child.type === Popover) {
              return React.cloneElement(child as React.ReactElement<any>, {
                children: React.Children.map(child.props.children, popoverChild => {
                  if (React.isValidElement(popoverChild) && popoverChild.type === PopoverTrigger) {
                    return React.cloneElement(popoverChild as React.ReactElement<any>, {},
                      'children' in (popoverChild.props as any) && React.isValidElement((popoverChild.props as any).children)
                        ? React.cloneElement((popoverChild.props as any).children as React.ReactElement<any>, {
                          style: {
                            opacity: isHovered ? 1 : 0,
                            transition: "opacity 0.2s",
                          }
                        })
                        : (popoverChild.props as any).children
                    );
                  }
                  return popoverChild;
                })
              });
            }
            return child;
          })}
        </Flex>
      </Flex>
      <ResizeHandle
        onMouseDown={header.getResizeHandler()}
        onTouchStart={header.getResizeHandler()}
        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
        _hover={{
          opacity: 1,
        }}
        data-resize-handle="true"
      />
      {over?.id === header.id && (
        <Box
          position="absolute"
          left={0}
          top={0}
          bottom={0}
          width="4px"
          bg="orange.500"
        />
      )}
    </StyledTh>
  );
});

SortableHeader.displayName = 'SortableHeader';

export default SortableHeader;
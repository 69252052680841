import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  AddIcon,
  EditIcon,
  CopyIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { MenuDots } from "components/icons/Icons";
import Card from "components/card/Card";
import {  useNavigate, useLocation } from "react-router-dom";
import { zoneService } from "services/zoneService";
import TabContentSection from "../../../../components/TabContentSection";
import { useAuth } from "contexts/AuthContext";
import { Field } from "../../../../types/Field";
import FileField from "../../../../components/FileField";
import TabTableContentSection from "components/TabTableContentSectionNew";
import {
  ColumnNames,
  ColumnOrder,
  Columns,
  ColumnSizing,
  ColumnTypes,
  SelectOption,
} from "types/zone-datagrid";

interface TabData {
  id: string;
  name: string;
  content: string;
  isEditing?: boolean;
  type?: "fields" | "table";
  table?: {
    columns: Columns;
    columnOrder: ColumnOrder;
    columnTypes: ColumnTypes;
    columnWidths: ColumnSizing;
    selectOptions: {
      [key: string]: SelectOption;
    };
    columnNames?: ColumnNames;
  };
}

interface ZoneTabPageProps {
  setCurrentZoneName: (name: string) => void;
  zoneId: string;
  isEditable?: boolean;
  itemId: string;
}

interface TabFieldValue {
  key: string;
  value: any;
  type?: string;
  label?: string;
  options?: {
    currency?: string;
    [key: string]: any;
  };
  repeaterValues?: any[];
}

interface ZoneItemContent {
  value: any;
  type?: string;
  label?: string;
  [key: string]: any;
}

interface FieldsByTab {
  [key: string]: {
    left: any[];
    center: any[];
    right: any[];
  };
}

const ZoneTabPage: React.FC<ZoneTabPageProps> = ({
  setCurrentZoneName,
  zoneId,
  isEditable = true,
  itemId = "",
}) => {
  const [zoneName, setZoneName] = useState<string>("");
  const [tabData, setTabData] = useState<Record<string, TabFieldValue>>({});
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [tabs, setTabs] = useState<TabData[]>([]); // Changed to an empty array
  const [tabToDelete, setTabToDelete] = useState<string | number | null>(null); // Changed to string | number | null
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const cancelRef = useRef<HTMLButtonElement>(null);
  const [currentTabId, setCurrentTabId] = useState<string>("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const companyId = useAuth().user.companyId;
  const [zoneFields, setZoneFields] = useState<Field[]>([]);
  const [isNewItem, setIsNewItem] = useState(!itemId);
  const [hasChanges, setHasChanges] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  //const location = useLocation();
  const [lastSavedData, setLastSavedData] = useState<any>(null);
  const [fieldsByTab, setFieldsByTab] = useState<FieldsByTab>({});
  //const [activePopover, setActivePopover] = useState({ id: null, type: null });

  const validateRequiredFields = () => {
    const errors: string[] = [];
    zoneFields.forEach((field) => {
      if (field.required && (!tabData[field.id] || !tabData[field.id].value)) {
        errors.push(`${field.label} is required`);
      }
    });
    return errors;
  };

  const handleTabFields = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | { target: { name: string; value: string | string[] } },
  ) => {
    setTabData((prv: any) => {
      const newData = {
        ...prv,
        [e.target.name]: {
          ...prv[e.target.name],
          key: e.target.name,
          value: e.target.value,
        },
      };
      return newData;
    });
    setHasChanges(true);
  };

  const saveItemsData = async () => {
    const errors = validateRequiredFields();
    if (errors.length > 0) {
      toast({
        title: "Validation Error",
        description: errors.join(", "),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    try {
      let response;
      const dataToSave: Record<string, TabFieldValue> = {};

      Object.entries(tabData).forEach(([key, value]) => {
        if (value && "value" in value) {
          dataToSave[key] = {
            key: key,
            value: value.value,
            ...(value.type && { type: value.type }),
            ...(value.label && { label: value.label }),
            ...(value.options && { options: value.options }),
            ...(value.repeaterValues && { repeaterValues: value.repeaterValues }),
          };
        }
      });

      if (isNewItem) {
        response = await zoneService.createZoneItem(
          zoneId,
          dataToSave,
          companyId,
        );
      } else {
        response = await zoneService.updateZoneItem(zoneId, itemId, dataToSave);
      }

      if (response?.data) {
        const updatedData: Record<string, TabFieldValue> = {};
        Object.entries(response.data).forEach(([key, value]) => {
          if (value && typeof value === "object" && "value" in value) {
            const typedValue = value as {
              value: any;
              type?: string;
              label?: string;
              options?: any;
              repeaterValues?: any[];
            };
            updatedData[key] = {
              key: key,
              value: typedValue.value,
              ...(typedValue.type && { type: typedValue.type }),
              ...(typedValue.label && { label: typedValue.label }),
              ...(typedValue.options && { options: typedValue.options }),
              ...(typedValue.repeaterValues && { repeaterValues: typedValue.repeaterValues }),
            };
          }
        });

        setTabData(updatedData);
        setLastSavedData(updatedData);
        setIsNewItem(false);
        setHasChanges(false);

        toast({
          title: "Success",
          description: isNewItem
            ? "Item created successfully"
            : "Data updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error saving/updating data:", error);
      toast({
        title: "Error",
        description: `Failed to ${
          isNewItem ? "create" : "update"
        } data. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  };
  const updateItemDataWithParams = React.useCallback(
    async (params: Record<string, any>) => {
      try {
        const newData = { ...tabData, ...params };
        setTabData(newData);
        setTimeout(() => {
          setHasChanges(true);
        }, 50);
      } catch (error) {
        console.error("Error updating data with params:", error);
        toast({
          title: "Error",
          description: "Failed to update data. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    },
    [tabData, toast],
  );
  const openDeleteModal = (index: number) => {
    setTabToDelete(index);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setTabToDelete(null);
  };

  const addNewTab = async (type: "fields" | "table") => {
    try {
      const newTabId = Date.now().toString();
      const newTab: TabData = {
        id: newTabId,
        name: `New Tab ${tabs.length + 1}`,
        content: "",
        type,
      };
      if (type === "table") {
        newTab.table = {
          columns: [],
          columnOrder: [],
          columnTypes: {},
          columnWidths: {},
          selectOptions: {},
          columnNames: {},
        };
      }

      // Create new tabs array with the new tab
      const newTabs = [...tabs, newTab];
      //console.log('Current tabs:', tabs);
      //console.log('New tabs array:', newTabs);

      onChooseTabTypeClose();

      // Save tabs to database
      const response = await zoneService.saveTabData(zoneId, newTabs);

      if (!response || !response.tabs) {
        throw new Error("Invalid response from server");
      }

      // Update local state with the response data
      //console.log('Setting tabs with:', response.tabs);
      setTabs(response.tabs);
      setCurrentTabId(newTabId);
      setSelectedTabIndex(newTabs.length - 1);

      // Initialize empty fields structure for the new tab
      setFieldsByTab((prevFields) => ({
        ...prevFields,
        [newTabId]: { left: [], center: [], right: [] },
      }));

      toast({
        title: "Success",
        description: "New tab created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error in addNewTab:", error);
      toast({
        title: "Error",
        description: "Failed to create new tab. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTabAction = async (
    index: number,
    action: "rename" | "duplicate" | "delete",
  ) => {
    switch (action) {
      case "rename":
        setTabs(
          tabs.map((tab, i) =>
            i === index ? { ...tab, isEditing: true } : tab,
          ),
        );
        break;
      case "duplicate":
        const newTab = {
          ...tabs[index],
          id: Date.now().toString(),
          name: `${tabs[index].name} (Copy)`,
        };
        const newTabs = [
          ...tabs.slice(0, index + 1),
          newTab,
          ...tabs.slice(index + 1),
        ];
        setTabs(newTabs);
        try {
          await zoneService.saveTabData(zoneId, newTabs);
          //.log('Tab duplicated and saved successfully');
        } catch (error) {
          // console.error('Error saving duplicated tab:', error);
          // Optionally, revert the state if saving fails
          setTabs(tabs);
        }
        break;
      case "delete":
        openDeleteModal(index);
        break;
    }
  };

  const handleTabNameChange = async (index: number, newName: string) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab, i) =>
        i === index ? { ...tab, name: newName, isEditing: false } : tab,
      ),
    );

    try {
      await zoneService.saveTabData(
        zoneId,
        tabs.map((tab, i) => (i === index ? { ...tab, name: newName } : tab)),
      );
    } catch (error) {
      console.error("Error saving tab name change:", error);
      // Optionally, revert the state if saving fails
      setTabs((prevTabs) =>
        prevTabs.map((tab, i) =>
          i === index ? { ...tab, name: tabs[index].name } : tab,
        ),
      );
    }
  };

  const confirmDelete = async () => {
    if (tabToDelete !== null) {
      const newTabs = tabs.filter((_, index) => index !== tabToDelete);
      try {
        await zoneService.saveTabData(zoneId, newTabs);
        setTabs(newTabs);
        setTabToDelete(null);
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting tab:", error);
        toast({
          title: "Error",
          description: "Failed to delete tab. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        closeDeleteModal();
      }
    }
  };

  const fetchZoneItem = async () => {
    try {
      const item = await zoneService.getZoneItemById(zoneId, itemId, false);
      if (item?.data) {
        const transformedData: Record<string, TabFieldValue> = {};
        Object.entries(item.data).forEach(([key, content]) => {
          if (
            (typeof content === "object" || Array.isArray(content)) &&
            content !== null
          ) {
            if ("value" in content) {
              // Type assertion for content
              const typedContent = content as ZoneItemContent;
              transformedData[key] = {
                key,
                value: typedContent.value,
                ...(typedContent.type && { type: typedContent.type }),
                ...(typedContent.label && { label: typedContent.label }),
                ...(typedContent.options && { options: typedContent.options }),
                ...(typedContent.repeaterValues && { repeaterValues: typedContent.repeaterValues }),
              };
            } else {
              // Handle imported structure
              if (Array.isArray(content)) {
                transformedData[key] = { key: key, value: content };
              } else {
                Object.entries(content).forEach(([fieldId, value]) => {
                  transformedData[fieldId] = {
                    key: fieldId,
                    value,
                  };
                });
              }
            }
          }
        });
        setTabData(transformedData);
        setLastSavedData(transformedData);
        setIsNewItem(false);
      }
    } catch (error) {
      console.error("Error fetching zone item:", error);
      toast({
        title: "Error",
        description: "Failed to fetch zone item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateSingleTab = React.useCallback(
    async (updatedTab: TabData) => {
      let result = false;
      try {
        const newTabs = [...tabs].map((tab) => {
          if (tab.id === updatedTab.id) {
            return updatedTab;
          }
          return tab;
        });

        const response = await zoneService.saveTabData(zoneId, newTabs);
        if (response?.tabs) {
          setTabs(response.tabs);
          // toast({
          //   title: "Success",
          //   description: "Tab updated successfully",
          //   status: "success",
          //   duration: 3000,
          //   isClosable: true,
          // });
          result = true;
        }
      } catch (error) {
        console.error("Error updating tab:", error);
        toast({
          title: "Error",
          description: "Failed to update tab. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        return result;
      }
    },
    [tabs, toast, zoneId],
  );

  const updateDataItem = async () => {
    const errors = validateRequiredFields();
    if (errors.length > 0) {
      // Highlight empty required fields in red
      setZoneFields((prevFields) =>
        prevFields.map((field) => ({
          ...field,
          isInvalid:
            field.required && (!tabData[field.id] || !tabData[field.id].value),
        })),
      );

      // Show toast alert
      toast({
        title: "Validation Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Make sure to preserve all properties when updating
      const dataToSave = { ...tabData };
      
      const response = await zoneService.updateZoneItem(
        zoneId,
        itemId,
        dataToSave,
      );

      if (response?.data) {
        setTabData(response.data);
        toast({
          title: "Success",
          description: "Data updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast({
        title: "Error",
        description: "Failed to update data. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchZoneData = async () => {
      if (!zoneId) {
        return;
      }
      try {
        const response = await zoneService.getZoneById(zoneId);
        const zoneData = response.data;
        if (zoneData.name) {
          setZoneName(zoneData.name);
          setCurrentZoneName(zoneData.name);
        }
        if (zoneData.tabs && Array.isArray(zoneData.tabs)) {
          const tabsWithUniqueIds = zoneData.tabs.map(
            (tab: Partial<TabData>) => ({
              ...tab,
              id: tab.id || Date.now().toString(),
              isEditing: false,
            }),
          );
          setTabs(tabsWithUniqueIds);
          setSelectedTabIndex(0);
        } else {
          const defaultTab = {
            id: Date.now().toString(),
            name: "Main",
            content: "",
            isEditing: false,
          };
          setTabs([defaultTab]);
          await zoneService.saveTabData(zoneId, [defaultTab]);
          setSelectedTabIndex(0);
        }
      } catch (error) {
        // console.error('Error fetching zone data:', error);
        setTabs([{ id: "main", name: "Main", content: "" }]);
      }
    };

    (async () => {
      await fetchZoneData();
      if (itemId) {
        await fetchZoneItem();
      }
    })();
  }, [zoneId, setCurrentZoneName]);

  useEffect(() => {
    const fetchZoneFields = async () => {
      try {
        const fields = await zoneService.getZoneFields(zoneId);
        setZoneFields(fields);
      } catch (error) {
        console.error("Error fetching zone fields:", error);
      }
    };

    fetchZoneFields();
  }, [zoneId]);

  useEffect(() => {
    const compareData = async () => {
      if (lastSavedData) {
        // Compare with last saved data instead of fetching
        const hasChanges = Object.entries(tabData).some(([key, value]) => {
          // Skip comparison for Calculation fields
          if (
            zoneFields.find(
              (field) => field.id === key && field.type === "Calculation",
            )
          ) {
            return false;
          }
          // console.log("compareData", value, lastSavedData[key]);
          return JSON.stringify(value) !== JSON.stringify(lastSavedData[key]);
        });

        setHasChanges(hasChanges);
      } else if (itemId) {
        try {
          const originalData = await zoneService.getZoneItem(itemId);
          if (originalData?.data) {
            setLastSavedData(originalData.data);
            const hasChanges = Object.entries(tabData).some(([key, value]) => {
              if (
                zoneFields.find(
                  (field) => field.id === key && field.type === "Calculation",
                )
              ) {
                return false;
              }
              return (
                JSON.stringify(value) !== JSON.stringify(originalData.data[key])
              );
            });
            setHasChanges(hasChanges);
          }
        } catch (error) {
          console.error("Error comparing data:", error);
        }
      } else {
        // For new items, check if any field has a value
        const hasAnyValue = Object.entries(tabData).some(([key, field]) => {
          if (typeof field === "object" && field !== null) {
            if (
              zoneFields.find(
                (zoneField) =>
                  zoneField.id === key && zoneField.type === "Calculation",
              )
            ) {
              return false;
            }
            return (
              (field as { value?: unknown }).value !== undefined &&
              (field as { value?: unknown }).value !== ""
            );
          }
          return false;
        });
        setHasChanges(hasAnyValue);
      }
    };

    compareData();
  }, [tabData, zoneFields, itemId, lastSavedData]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [navigationPath, setNavigationPath] = useState<string | null>(null);

  //* Choose tab type
  const {
    isOpen: isChooseTabTypeOpen,
    onOpen: onChooseTabTypeOpen,
    onClose: onChooseTabTypeClose,
  } = useDisclosure();
  //* Chakra problem Fix: Model doesn't really close when it's supposed to
  React.useEffect(() => {
    if (!isChooseTabTypeOpen) {
      setTimeout(() => {
        const modalAnchor = document.getElementById(
          "choose-tab-type-modal-anchor",
        );
        console.log(modalAnchor);
        if (modalAnchor) {
          const parentDialogPortal = modalAnchor.closest(".chakra-portal");
          parentDialogPortal?.remove();
        }
      }, 500);
    }
  }, [isChooseTabTypeOpen]);

  const handleNavigation = (path: string) => {
    if (hasChanges) {
      setNavigationPath(path);
      onOpen();
    } else {
      navigate(path);
    }
  };

  const handleNavigationConfirm = async () => {
    if (navigationPath) {
      const saved = await saveItemsData();
      if (saved) {
        navigate(navigationPath);
        onClose();
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
    >
      <Card
        height="calc(100vh - 100px)"
        overflow="hidden"
      >
        {isEditable && (
          <Flex
            justifyContent="flex-start"
            alignItems="left"
            mb="6"
          >
            <Box>
              <Text
                color={textColor}
                fontSize="2xl"
                fontWeight="700"
              >
                Form Builder
              </Text>
              <Text
                color={textColor}
                fontSize="md"
              >
                Here you can manage the fields and tabs of this zone
              </Text>
            </Box>
          </Flex>
        )}
        {!isEditable && (
          <Flex justifyContent="flex-end">
            <Button
              colorScheme="gray"
              size="sm"
              fontWeight="normal"
              mr={2}
              onClick={() =>
                handleNavigation(`/admin/zones/${zoneId}/list?type=tabs`)
              }
            >
              Back to Zone List
            </Button>
            <Button
              width="160px"
              colorScheme="brand"
              size="sm"
              fontWeight="normal"
              onClick={async () => {
                const saved = await saveItemsData();
                if (saved) {
                  setHasChanges(false);
                }
              }}
              isDisabled={!hasChanges}
            >
              Save Data
            </Button>
          </Flex>
        )}
        <Tabs
          index={selectedTabIndex}
          onChange={(index) => setSelectedTabIndex(index)}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Flex
                key={tab.id}
                position="relative"
                alignItems="center"
                marginRight="6px"
              >
                <Tab>
                  <Box>{!tab.isEditing ? <Text>{tab.name}</Text> : null}</Box>
                </Tab>
                {tab.isEditing && (
                  <Box
                    width="100%"
                    height="100%"
                    zIndex={1}
                  >
                    <Input
                      size="sm"
                      defaultValue={tab.name}
                      onBlur={(e) => handleTabNameChange(index, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleTabNameChange(index, e.currentTarget.value);
                        }
                        if (e.key === "Escape") {
                          handleTabNameChange(index, tab.name);
                        }
                      }}
                      autoFocus
                      backgroundColor="#fff"
                      width="100%"
                      height="36px"
                      px={4}
                      my="-6px"
                    />
                  </Box>
                )}
                {isEditable && (
                  <Box>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MenuDots boxSize={6} />}
                        variant="ghost"
                        size="xs"
                        ml="-14px"
                        _hover={{
                          bg: "gray.200",
                        }}
                      />
                      <MenuList className="smallMenuDrop">
                        <MenuItem
                          onClick={() => handleTabAction(index, "rename")}
                          icon={
                            <Icon
                              as={EditIcon}
                              color="orange.500"
                            />
                          }
                        >
                          Rename
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleTabAction(index, "duplicate")}
                          icon={
                            <Icon
                              as={CopyIcon}
                              color="orange.500"
                            />
                          }
                        >
                          Duplicate
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleTabAction(index, "delete")}
                          icon={
                            <Icon
                              as={DeleteIcon}
                              color="orange.500"
                            />
                          }
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                )}
              </Flex>
            ))}
            {isEditable && (
              <Button
                // onClick={addNewTab}
                onClick={onChooseTabTypeOpen}
                variant="outline"
                borderWidth={0}
                h="30px"
                borderColor="gray.200"
                color="gray.400"
                fontWeight="400"
                p={0}
                m={0}
                ml={7}
                mt="5px"
                leftIcon={<AddIcon />}
                _hover={{
                  color: "gray.700",
                }}
              >
                New tab
              </Button>
            )}
          </TabList>
          <TabPanels
            height={isEditable ? "calc(100vh - 300px)" : "calc(100vh - 240px)"}
            overflowY="auto"
          >
            {tabs.map((tab, index) => (
              <TabPanel
                key={tab.id}
                pt={tab.type === "table" ? 0 : 5}
                pl={0}
                pr={0}
                height={tab.type === "table" ? "100%" : undefined}
              >
                {tab.type === "table" ? (
                  <TabTableContentSection
                    zoneId={zoneId}
                    currentTabId={tab.id}
                    tabData={tab}
                    updateSingleTab={updateSingleTab}
                    setCurrentTabId={setCurrentTabId}
                    itemData={tabData}
                    setItemData={setTabData}
                    isEditable={isEditable}
                    updateItemDataWithParams={updateItemDataWithParams}
                  />
                ) : (
                  <TabContentSection
                    zoneId={zoneId}
                    currentTabId={tab.id}
                    setCurrentTabId={setCurrentTabId}
                    isEditable={isEditable}
                    handleTabFields={handleTabFields}
                    itemData={tabData}
                    setItemData={setTabData}
                    allFields={zoneFields.map((field) => ({
                      id: field.id,
                      label: field.label,
                    }))}
                    FileField={FileField}
                    toast={toast}
                  />
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Card>

      {isDeleteModalOpen && (
        <AlertDialog
          isOpen={isDeleteModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={closeDeleteModal}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader
                fontSize="lg"
                fontWeight="bold"
              >
                Delete Tab
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete this tab? This action cannot be
                undone.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={closeDeleteModal}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={confirmDelete}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unsaved Changes</ModalHeader>
          <ModalBody>
            You have unsaved changes. Do you want to save before leaving?
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              onClick={() => {
                if (navigationPath) {
                  navigate(navigationPath);
                  onClose();
                }
              }}
            >
              Don't Save
            </Button>
            <Button
              variant="solid"
              ml={2}
              colorScheme="orange"
              onClick={handleNavigationConfirm}
            >
              Save and Leave
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isChooseTabTypeOpen}
        onClose={onChooseTabTypeClose}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <Box
            sx={{
              display: "none",
            }}
            id={"choose-tab-type-modal-anchor"}
          />
          <ModalHeader>Please choose tab type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Please choose the new tab type between two options: "Fields view" or
            "Table view"
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              ml={2}
              colorScheme="orange"
              onClick={() => addNewTab("fields")}
            >
              Fields
            </Button>
            <Button
              variant="solid"
              ml={2}
              colorScheme="orange"
              onClick={() => addNewTab("table")}
            >
              Table
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ZoneTabPage;

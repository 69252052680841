import React, { useState, useEffect, useCallback } from 'react';
import { VStack, HStack, Input, Button, Box, Popover, PopoverTrigger, PopoverContent, PopoverBody } from '@chakra-ui/react';
import ColorPalette from './ColorPalette';

export interface LabelOption {
  label: string;
  color: string;
}

interface LabelOptionEditorProps {
  options: LabelOption[];
  onChange: (newOptions: LabelOption[]) => void;
}

const LabelOptionEditor: React.FC<LabelOptionEditorProps> = React.memo(({ options, onChange }) => {
  const defaultOption = { label: '', color: '#dbdbdb' };
  const [localOptions, setLocalOptions] = useState<LabelOption[]>(() => 
    Array.isArray(options) && options.length > 0 ? options : [defaultOption]
  );
  const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);

  // Sync with external options only when options prop changes significantly
  useEffect(() => {
    if (!options || !localOptions) return;
    
    const hasSignificantChange = 
      options.length !== localOptions.length || 
      options.some((opt, i) => 
        !localOptions[i] || // Check if option exists
        (opt.label && localOptions[i].label && opt.label !== localOptions[i].label) || // Compare labels only if both exist
        (opt.color && localOptions[i].color && opt.color !== localOptions[i].color) // Compare colors only if both exist
      );

    if (hasSignificantChange) {
      setLocalOptions(options);
    }
  }, [options]);

  const handleLabelChange = useCallback((index: number, newLabel: string) => {
    setLocalOptions(prev => prev.map((opt, i) => 
      i === index ? { ...opt, label: newLabel } : opt
    ));
  }, []);

  const handleColorChange = useCallback((index: number, newColor: string) => {
    setLocalOptions(prev => prev.map((opt, i) => 
      i === index ? { ...opt, color: newColor } : opt
    ));
    setOpenPopoverIndex(null);  // Close color picker popup
  }, []);

  const addOption = useCallback(() => {
    setLocalOptions(prev => [...prev, { label: '', color: '#dbdbdb' }]);
  }, []);

  const removeOption = useCallback((index: number) => {
    setLocalOptions(prev => prev.filter((_, i) => i !== index));
  }, []);

  const handleSaveAndClose = useCallback(() => {
    onChange(localOptions);
  }, [localOptions, onChange]);

  return (
    <VStack spacing={4} align="stretch">
      {localOptions.map((option, index) => (
        <HStack key={index}>
          <Input
            value={option.label}
            onChange={(e) => handleLabelChange(index, e.target.value)}
            placeholder="Enter label"
          />
          <Popover
            isOpen={openPopoverIndex === index}
          >
            <PopoverTrigger>
              <Button
                size="sm"
                width="40px"
                height="40px"
                padding="0"
                onClick={() => setOpenPopoverIndex(index)}
              >
                <Box width="20px" height="20px" borderRadius="50%" backgroundColor={option.color} />
              </Button>
            </PopoverTrigger>
            <PopoverContent width="220px">
              <PopoverBody>
                <ColorPalette
                  selectedColor={option.color}
                  onColorSelect={(color) => handleColorChange(index, color)}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Button onClick={() => removeOption(index)} size="sm" fontWeight="normal">
            Remove
          </Button>
        </HStack>
      ))}
      <Button onClick={addOption} size="sm" colorScheme="black" variant="outline" fontWeight="normal">
        Add Option
      </Button>
      <Button onClick={handleSaveAndClose} size="sm" colorScheme="brand" fontWeight="normal">
        Save and close
      </Button>
    </VStack>
  );
});

LabelOptionEditor.displayName = 'LabelOptionEditor';

export default LabelOptionEditor;

import React, { useState, useRef, useEffect } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  Box,
  Flex,
  Button,
  Popover,
  PopoverContent,
  PopoverHeader,
  VStack,
  Text,
  Checkbox,
  Input,
  FormControl,
  FormLabel,
  Select,
  IconButton,
  PopoverBody,
  useDisclosure,
  InputGroup,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
  useToast,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import { AddIcon, CalendarIcon, DeleteIcon, CloseIcon } from '@chakra-ui/icons';
import {
  FaFont,
  FaHashtag,
  FaList,
  FaLink,
  FaCheckSquare,
  FaFlag,
  FaCalculator,
  FaAlignLeft,
  FaFileUpload,
  FaTable,
} from 'react-icons/fa';
import { BsWindow } from 'react-icons/bs';

import { zoneService } from 'services/zoneService';
import RelationsField from './RelationsField';
import FieldCreationButton from './FieldCreationButton';
import FieldMenu from './FieldMenu/index';
import FieldsEditor from './FieldsEditor';
import DatePickerField from './fields/DatePickerField';
import LabelField from './fields/LabelField';
import LabelOptionEditor from './fields/LabelOptionEditor';
import { LabelOption } from './fields/LabelOptionEditor';
import { useAuth } from 'contexts/AuthContext';
import CalculationEditor from './CalculationEditor';
import FileField from './FileField';
import { setActiveZone } from 'store/slices/zoneSlice';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import MultiSelectField from './fields/MultiSelectField';
import _ from 'lodash';
import CenteredPopover from './CenteredPopover';
import TrueFalseField from "./TrueFalseField";
import PriceField from './fields/PriceField';


interface Field {
  id: string;
  type: string;
  label: string;
  appearInTable: boolean;
  required: boolean;
  repeater: boolean;
  isMultiSelect?: boolean;
  fieldLabel?: string;
  options?:
    | string[]
    | { zoneId: string; options: string[] }
    | { label: string; color: string }[]
    | { formula: string; fields: string[]; operators: string[] }
    | { currency: string };
  tabId: string;
  order: number;
  position: 'left' | 'center' | 'right';
  isInvalid?: boolean;
}

const TabContentSection: React.FC<{
  zoneId: string;
  currentTabId: string;
  setCurrentTabId: (tabId: string) => void;
  isEditable?: boolean;
  handleTabFields: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | { target: { name: string; value: string | string[] } }
  ) => void;
  itemData: any;
  setItemData: React.Dispatch<React.SetStateAction<any>>;
  FileField: React.ComponentType<any>;
  toast: any;
  allFields: { id: string; label: string }[];
}> = ({
  zoneId,
  currentTabId,
  setCurrentTabId,
  isEditable = true,
  handleTabFields,
  itemData = {},
  setItemData,
  allFields,
}) => {
  const [fieldsByTab, setFieldsByTab] = useState<
    Record<string, { left: Field[]; center: Field[]; right: Field[] }>
  >({});
  const [leftPopoverOpen, setLeftPopoverOpen] = useState(false);
  const [centerPopoverOpen, setCenterPopoverOpen] = useState(false);
  const [rightPopoverOpen, setRightPopoverOpen] = useState(false);
  const [activePopover, setActivePopover] = useState<{
    id: string | null;
    type: string | null;
  }>({ id: null, type: null });
  const [editingLabel, setEditingLabel] = useState<string | null>(null);
  const [fieldToDelete, setFieldToDelete] = useState<{
    side: 'left' | 'center' | 'right';
    id: string;
  } | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const labelInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [zones, setZones] = useState<{ id: string; name: string }[]>([]);
  const [isRelationsConfigOpen, setIsRelationsConfigOpen] = useState<
    string | null
  >(null);
  const companyId = useAuth().user.companyId;
  const userEmail = useAuth().user.email;
  const userType = useAuth().user.role;
  const toast = useToast();
  const dispatch = useDispatch();

  const saveFieldsToDatabase = async (
    updatedFieldsByTab: Record<
      string,
      { left: Field[]; center: Field[]; right: Field[] }
    >
  ) => {
    try {
      //console.log('Saving fields to database:', updatedFieldsByTab);
      const allFields = Object.entries(updatedFieldsByTab).flatMap(
        ([tabId, tabFields]) =>
          [...tabFields.left, ...tabFields.center, ...tabFields.right].map(
            (field) => ({ ...field, tabId })
          )
      );
      // console.log('Processed fields:', allFields);

      await zoneService.updateZoneFields(zoneId, allFields);
    } catch (error) {
      console.error('Error in saveFieldsToDatabase:', error);
      toast({
        title: 'Error',
        description: 'Failed to save fields. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const debouncedSave = React.useCallback(
    _.debounce(
      (
        fields: Record<
          string,
          { left: Field[]; center: Field[]; right: Field[] }
        >
      ) => {
        saveFieldsToDatabase(fields);
      },
      500
    ),
    [zoneId]
  );

  const fetchFieldData = async () => {
    try {
      setIsLoading(true);
      const fields = await zoneService.getZoneFields(zoneId);

      // Organize fields by tabs
      const fieldsByTab = fields.reduce(
        (
          acc: Record<
            string,
            { left: Field[]; center: Field[]; right: Field[] }
          >,
          field: Field
        ) => {
          if (!acc[field.tabId]) {
            acc[field.tabId] = { left: [], center: [], right: [] };
          }
          if (field.position === 'left') {
            acc[field.tabId].left.push(field);
          } else if (field.position === 'center') {
            acc[field.tabId].center.push(field);
          } else {
            acc[field.tabId].right.push(field);
          }
          return acc;
        },
        {}
      );

      // Ensure the 'default' tab exists
      if (!fieldsByTab['default']) {
        fieldsByTab['default'] = { left: [], center: [], right: [] };
      }

      setFieldsByTab(fieldsByTab);

      // Set the current tab ID if it's not set
      if (!currentTabId) {
        setCurrentTabId('default');
      }

      // Ensure the current tab exists in fieldsByTab
      if (currentTabId && !fieldsByTab[currentTabId]) {
        fieldsByTab[currentTabId] = { left: [], center: [], right: [] };
      }
    } catch (error) {
      console.error('Error fetching field data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (itemData['default-title']?.value?.length) {
      dispatch(setActiveZone(itemData['default-title']?.value));
    }
  }, [itemData]);
  useEffect(() => {
    fetchFieldData();
  }, [zoneId, currentTabId, setCurrentTabId]);

  useEffect(() => {
    if (!isLoading && Object.keys(fieldsByTab).length > 0) {
      setItemData((prev: any) => {
        const newData = { ...prev };

        Object.entries(fieldsByTab).forEach(([tabId, tabFields]) => {
          Object.values(tabFields).forEach((fields: Field[]) => {
            fields.forEach((field: Field) => {
              if (field.type === 'Calculation' && field.options) {
                const options = field.options as {
                  formula: string;
                  fields: string[];
                };
                const { formula, fields: calcFields } = options;

                // Only proceed if we have a valid formula and fields
                if (formula && calcFields?.length > 0) {
                  const values = calcFields.map((f) => {
                    const value = parseFloat(newData[f]?.value) || 0;
                    return isNaN(value) ? 0 : value;
                  });

                  try {
                    const safeEval = (
                      formula: string,
                      fieldValues: number[]
                    ) => {
                      // Replace field placeholders with actual values
                      let processedFormula = formula;
                      const fieldMatches = formula.match(/\{([^}]+)\}/g) || [];

                      fieldMatches.forEach((match, index) => {
                        processedFormula = processedFormula.replace(
                          match,
                          fieldValues[index].toString()
                        );
                      });

                      // Create a safe function that only allows basic arithmetic
                      const safeFn = new Function(
                        'return ' +
                          processedFormula.replace(/[^\d\s+\-*/().]/g, '')
                      );

                      try {
                        return safeFn();
                      } catch (error) {
                        console.error('Error evaluating formula:', error);
                        return 0;
                      }
                    };

                    const result = safeEval(formula, values);

                    if (!isNaN(result)) {
                      newData[field.id] = {
                        value: Number.isInteger(result)
                          ? result.toString()
                          : parseFloat(result.toFixed(2)).toString(),
                        key: field.id,
                      };
                    }
                  } catch (error) {
                    console.error('Calculation error:', error);
                    newData[field.id] = { value: '0', key: field.id };
                  }
                }
              }
            });
          });
        });
        return newData;
      });
    }
  }, [
    isLoading,
    fieldsByTab,
    JSON.stringify(
      Object.entries(itemData).filter(([key, value]) =>
        Object.values(fieldsByTab)
          .flatMap((tab) => [...tab.left, ...tab.center, ...tab.right])
          .some((field) => {
            if (field.type === 'Calculation' && field.options) {
              const calcOptions = field.options as {
                formula: string;
                fields: string[];
              };
              return calcOptions.fields?.includes(key);
            }
            return false;
          })
      )
    ),
  ]);

  useEffect(() => {
    fetchZones();
  }, []);

  const fetchZones = async () => {
    try {
      const allZones = await zoneService.getAllZones(
        companyId,
        userType,
        userEmail
      );
      const tableZones = allZones.filter(
        (zone: { type: string }) => zone.type === 'table'
      );
      setZones(tableZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  try {
    const addField = (side: 'left' | 'center' | 'right', fieldType: string) => {
      let tabId = currentTabId;

      if (Object.keys(fieldsByTab).length === 0) {
        tabId = 'default';
        setFieldsByTab({ [tabId]: { left: [], center: [], right: [] } });
        setCurrentTabId(tabId);
      } else if (!currentTabId) {
        tabId = Object.keys(fieldsByTab)[0];
        setCurrentTabId(tabId);
      }

      const newField: Field = {
        id: Date.now().toString(),
        type: fieldType,
        label: `New ${fieldType} Field`,
        appearInTable: false,
        required: false,
        repeater: false,
        options:
          fieldType === 'Select' || fieldType === 'Checkbox List'
            ? ['Option 1', 'Option 2', 'Option 3']
            : fieldType === 'Relations'
            ? { zoneId: '', options: [] as string[] }
            : fieldType === 'Calculation'
            ? { formula: '', fields: [] as string[], operators: [] as string[] }
            : fieldType === 'Price'
            ? { currency: 'USD' }
            : undefined,
        tabId: tabId,
        order: fieldsByTab[tabId]?.[side]?.length || 0,
        position: side,
      };

      setFieldsByTab((prev) => {
        const updatedFieldsByTab = {
          ...prev,
          [tabId]: {
            ...prev[tabId],
            [side]: [...(prev[tabId]?.[side] || []), newField],
          },
        };

        // Use the debounced save here
        debouncedSave(updatedFieldsByTab);

        return updatedFieldsByTab;
      });

      setLeftPopoverOpen(false);
      setCenterPopoverOpen(false);
      setRightPopoverOpen(false);
    };

    const handleEditOptions = (fieldId: string, fieldType: string) => {
      setActivePopover({ id: fieldId, type: 'editOptions' });
    };

    const updateFieldOptions = (
      side: 'left' | 'center' | 'right',
      fieldId: string,
      newOptions: any
    ) => {
      setFieldsByTab((prev) => ({
        ...prev,
        [currentTabId]: {
          ...prev[currentTabId],
          [side]: prev[currentTabId][side].map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  options:
                    field.type === 'Label'
                      ? newOptions // Directly use the newOptions array for Label type
                      : newOptions,
                }
              : field
          ),
        },
      }));
      setFieldsByTab((updatedFieldsByTab) => {
        saveFieldsToDatabase(updatedFieldsByTab);
        return updatedFieldsByTab;
      });
    };

    const handleRename = (fieldId: string) => {
      setEditingLabel(fieldId);
      setTimeout(() => labelInputRef.current?.focus(), 0);
    };

    const handleLabelChange = (
      side: 'left' | 'center' | 'right',
      fieldId: string,
      newLabel: string
    ) => {
      setFieldsByTab((prev) => ({
        ...prev,
        [currentTabId]: {
          ...prev[currentTabId],
          [side]: prev[currentTabId][side].map((field) =>
            field.id === fieldId ? { ...field, label: newLabel } : field
          ),
        },
      }));
    };

    const handleLabelChangeComplete = (
      side: 'left' | 'center' | 'right',
      fieldId: string,
      newLabel: string
    ) => {
      setFieldsByTab((prev) => {
        const updatedFieldsByTab = {
          ...prev,
          [currentTabId]: {
            ...prev[currentTabId],
            [side]: prev[currentTabId][side].map((field) =>
              field.id === fieldId ? { ...field, label: newLabel } : field
            ),
          },
        };
        saveFieldsToDatabase(updatedFieldsByTab);
        return updatedFieldsByTab;
      });
    };

    const handleDuplicate = (
      side: 'left' | 'center' | 'right',
      fieldToDuplicate: Field
    ) => {
      const newField = {
        ...fieldToDuplicate,
        id: Date.now().toString(),
        label: `${fieldToDuplicate.label} (Copy)`,
      };
      setFieldsByTab((prev) => ({
        ...prev,
        [currentTabId]: {
          ...prev[currentTabId],
          [side]: [
            ...prev[currentTabId][side].slice(
              0,
              prev[currentTabId][side].findIndex(
                (field) => field.id === fieldToDuplicate.id
              ) + 1
            ),
            newField,
            ...prev[currentTabId][side].slice(
              prev[currentTabId][side].findIndex(
                (field) => field.id === fieldToDuplicate.id
              ) + 1
            ),
          ],
        },
      }));
      setFieldsByTab((updatedFieldsByTab) => {
        saveFieldsToDatabase(updatedFieldsByTab);
        return updatedFieldsByTab;
      });
    };

    const handleDeleteConfirmation = (
      side: 'left' | 'center' | 'right',
      fieldId: string
    ) => {
      if (fieldId === 'default-title') {
        // Show an alert or toast notification
        alert('The Title/Name field cannot be deleted.');
        return;
      }
      setFieldToDelete({ side, id: fieldId });
      onOpen();
    };

    const handleDelete = () => {
      if (fieldToDelete) {
        const { side, id } = fieldToDelete;
        setFieldsByTab((prev) => ({
          ...prev,
          [currentTabId]: {
            ...prev[currentTabId],
            [side]: prev[currentTabId][side].filter((field) => field.id !== id),
          },
        }));
      }
      onClose();
      setFieldToDelete(null);
      setFieldsByTab((updatedFieldsByTab) => {
        saveFieldsToDatabase(updatedFieldsByTab);
        return updatedFieldsByTab;
      });
    };

    const onDragEnd = (result: DropResult) => {
      const { source, destination } = result;

      // Dropped outside the list
      if (!destination) {
        return;
      }

      const sourceList =
        fieldsByTab[currentTabId][
          source.droppableId as 'left' | 'center' | 'right'
        ];
      const destList =
        fieldsByTab[currentTabId][
          destination.droppableId as 'left' | 'center' | 'right'
        ];

      const [reorderedItem] = sourceList.splice(source.index, 1);
      destList.splice(destination.index, 0, reorderedItem);

      setFieldsByTab((prev) => ({
        ...prev,
        [currentTabId]: {
          ...prev[currentTabId],
          [source.droppableId]: sourceList,
          [destination.droppableId]: destList,
        },
      }));
      setFieldsByTab((updatedFieldsByTab) => {
        saveFieldsToDatabase(updatedFieldsByTab);
        return updatedFieldsByTab;
      });
    };

    const handleFieldSettingChange = (
      side: 'left' | 'center' | 'right',
      fieldId: string,
      setting: 'appearInTable' | 'required' | 'repeater',
      value: boolean
    ) => {
      setFieldsByTab((prev) => ({
        ...prev,
        [currentTabId]: {
          ...prev[currentTabId],
          [side]: prev[currentTabId][side].map((field) =>
            field.id === fieldId ? { ...field, [setting]: value } : field
          ),
        },
      }));
      setFieldsByTab((updatedFieldsByTab) => {
        if (
          setting === 'required' &&
          value &&
          updatedFieldsByTab[currentTabId][side].find((f) => f.id === fieldId)
            ?.type === 'Calculation'
        ) {
          const calculationField = updatedFieldsByTab[currentTabId][side].find(
            (f) => f.id === fieldId
          );
          if (calculationField) {
            setActivePopover({ id: fieldId, type: 'editCalculation' });
          }
        }
        saveFieldsToDatabase(updatedFieldsByTab);
        return updatedFieldsByTab;
      });
    };

    const handleRepeaterFieldChange = (
      fieldId: string,
      index: number,
      value: any
    ) => {
      setItemData((prev: Record<string, any>) => {
        const field = Object.values(fieldsByTab)
          .flatMap((tab) => [...tab.left, ...tab.center, ...tab.right])
          .find((f) => f.id === fieldId);

        const newValue = field?.type === 'date' ? value : value;

        return {
          ...prev,
          [fieldId]: {
            ...prev[fieldId],
            repeaterValues: prev[fieldId]?.repeaterValues.map(
              (v: any, i: number) => (i === index ? newValue : v)
            ),
          },
        };
      });
    };

    const addRepeaterField = (fieldId: string, fieldType: string) => {
      setItemData((prev: Record<string, any>) => ({
        ...prev,
        [fieldId]: {
          ...prev[fieldId],
          repeaterValues: [
            ...(prev[fieldId]?.repeaterValues || []),
            fieldType === 'Checkbox List'
              ? []
              : fieldType === 'date'
              ? format(new Date(), 'yyyy-MM-dd')
              : '',
          ],
        },
      }));
    };

    const removeRepeaterField = (fieldId: string, index: number) => {
      setItemData((prev: Record<string, any>) => ({
        ...prev,
        [fieldId]: {
          ...prev[fieldId],
          repeaterValues: prev[fieldId]?.repeaterValues.filter(
            (_: any, i: number) => i !== index
          ),
        },
      }));
    };

    const handleTabFields = (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | { target: { name: string; value: string | string[] } }
    ) => {
      const { name, value } = e.target;
      setItemData((prev: any) => ({
        ...prev,
        [name]: { value },
      }));
    };

    const getEmbedUrl = (url: string): string => {
      if (!url) return '';

      // Handle Vimeo URLs
      const vimeoRegex = /(?:vimeo.com\/)(\d+)/;
      const vimeoMatch = url.match(vimeoRegex);
      if (vimeoMatch) {
        return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
      }

      // Return original URL if no matches
      return url;
    };

    const renderFields = (
      fields: Field[] | undefined,
      side: 'left' | 'center' | 'right'
    ) => {
      return (
        <VStack align="stretch" spacing={4} width="90%">
          {currentTabId === 'default' && side === 'left' && (
            <FormControl className="tabFieldSection" isRequired>
              <FormLabel>Title/Name</FormLabel>
              <Input
                placeholder="Enter title or name"
                name={'default-title'}
                disabled={isEditable}
                onChange={handleTabFields}
                value={itemData['default-title']?.value || ''}
              />
            </FormControl>
          )}
          {(fields || [])
            .filter((field) => field.tabId === currentTabId)
            .map((field, index) => {
              if (field.id === 'default-title') {
                return null;
              }

              return (
                <Draggable
                  key={field.id}
                  draggableId={field.id}
                  index={index}
                  isDragDisabled={!isEditable}
                >
                  {(provided, snapshot) => (
                    <FormControl
                      className="tabFieldSection"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      width="100%"
                      alignSelf="left"
                      position="relative"
                      opacity={snapshot.isDragging ? 0.5 : 1}
                      mb={2}
                      isRequired={field.required}
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                      >
                        {editingLabel === field.id ? (
                          <Input
                            ref={labelInputRef}
                            value={field.label}
                            onChange={(e) =>
                              handleLabelChange(side, field.id, e.target.value)
                            }
                            onBlur={() => {
                              handleLabelChangeComplete(
                                side,
                                field.id,
                                field.label
                              );
                              setEditingLabel(null);
                            }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleLabelChangeComplete(
                                  side,
                                  field.id,
                                  field.label
                                );
                                setEditingLabel(null);
                              }
                            }}
                          />
                        ) : (
                          <FormLabel mb={0}>{field.label}</FormLabel>
                        )}
                        <Flex alignItems="center">
                          {field.appearInTable && isEditable && (
                            <Box mr={2} mt={2}>
                              <Icon as={FaTable} color="orange.500" />
                            </Box>
                          )}
                          {isEditable && (
                            <FieldMenu
                              field={field}
                              side={side as 'left' | 'right'}
                              handleRename={handleRename}
                              handleDuplicate={handleDuplicate}
                              handleDeleteConfirmation={
                                handleDeleteConfirmation
                              }
                              handleEditOptions={handleEditOptions}
                              setIsRelationsConfigOpen={
                                setIsRelationsConfigOpen
                              }
                              setActivePopover={setActivePopover}
                              handleFieldSettingChange={
                                handleFieldSettingChange
                              }
                              handleSetCalculation={(fieldId: string) =>
                                setActivePopover({
                                  id: fieldId,
                                  type: 'editCalculation',
                                })
                              }
                            />
                          )}
                        </Flex>
                      </Flex>
                      {field.type === 'File' && (
                        <Box mb={2}>
                          <Text fontSize="sm" color="gray.500">
                            {field.repeater
                              ? 'Multiple file uploads allowed'
                              : 'Single file upload'}
                          </Text>
                        </Box>
                      )}
                      <Box role="group" position="relative">
                        {field.type === 'Select' ? (
                          <>
                            {field.isMultiSelect ? (
                              <MultiSelectField
                                options={
                                  Array.isArray(field.options)
                                    ? field.options.map((opt) =>
                                        typeof opt === 'string'
                                          ? opt
                                          : opt.label
                                      )
                                    : []
                                }
                                value={
                                  Array.isArray(itemData[field?.id]?.value)
                                    ? itemData[field?.id]?.value
                                    : []
                                }
                                onChange={(newValue) =>
                                  handleTabFields({
                                    target: { name: field.id, value: newValue },
                                  })
                                }
                                isDisabled={isEditable}
                                name={field.id}
                                isInvalid={field.isInvalid}
                              />
                            ) : (
                              <Select
                                placeholder="Select an option"
                                value={itemData[field?.id]?.value || ''}
                                onChange={(
                                  e: React.ChangeEvent<HTMLSelectElement>
                                ) =>
                                  handleTabFields(
                                    e as unknown as React.ChangeEvent<HTMLInputElement>
                                  )
                                }
                                name={field.id}
                                disabled={isEditable}
                                id={field.id}
                                borderColor={
                                  field.isInvalid ? 'red.500' : undefined
                                }
                              >
                                {Array.isArray(field.options) &&
                                  field.options.map((option, index) => (
                                    <option
                                      key={index}
                                      value={
                                        typeof option === 'string'
                                          ? option
                                          : option.label
                                      }
                                    >
                                      {typeof option === 'string'
                                        ? option
                                        : option.label}
                                    </option>
                                  ))}
                              </Select>
                            )}
                            <Popover
                              isOpen={
                                activePopover.type === 'editOptions' &&
                                activePopover.id === field.id
                              }
                              onClose={() =>
                                setActivePopover({ id: null, type: null })
                              }
                              placement="right"
                            >
                              <PopoverContent width="300px">
                                <PopoverBody>
                                  <VStack align="stretch" spacing={2}>
                                    <FormControl
                                      display="flex"
                                      alignItems="center"
                                      mb={4}
                                    >
                                      <FormLabel mb={0}>Multi-select</FormLabel>
                                      <Switch
                                        colorScheme="orange"
                                        isChecked={field.isMultiSelect}
                                        onChange={(e) => {
                                          setFieldsByTab((prev) => {
                                            const updatedFieldsByTab = {
                                              ...prev,
                                              [currentTabId]: {
                                                ...prev[currentTabId],
                                                [side]: prev[currentTabId][
                                                  side
                                                ].map((f) =>
                                                  f.id === field.id
                                                    ? {
                                                        ...f,
                                                        isMultiSelect:
                                                          e.target.checked,
                                                        value: e.target.checked
                                                          ? []
                                                          : ('' as
                                                              | string
                                                              | string[]),
                                                      }
                                                    : f
                                                ),
                                              },
                                            };

                                            // Save to database
                                            saveFieldsToDatabase(
                                              updatedFieldsByTab
                                            );

                                            return updatedFieldsByTab;
                                          });
                                        }}
                                      />
                                    </FormControl>
                                    <Text fontWeight="normal" mb={2}>
                                      Edit {field.type} Options
                                    </Text>
                                    <FieldsEditor
                                      field={
                                        Array.isArray(field.options)
                                          ? field.options.join('\n')
                                          : ''
                                      }
                                      side={side}
                                      updateFieldOptionsTab={updateFieldOptions}
                                      type="tabs"
                                      valId={field.id}
                                    />
                                    <Button
                                      onClick={() =>
                                        setActivePopover({
                                          id: null,
                                          type: null,
                                        })
                                      }
                                      size="sm"
                                      variant="brand"
                                      fontWeight="normal"
                                    >
                                      Save and close
                                    </Button>
                                  </VStack>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </>
                        ) : field.type === 'Checkbox List' ? (
                          <>
                            <VStack align="start" spacing={2}>
                              {Array.isArray(field.options) &&
                                field.options.map((option, index) => (
                                  <Checkbox
                                    key={index}
                                    colorScheme="orange"
                                    isChecked={itemData[
                                      field?.id
                                    ]?.value?.includes(option)}
                                    onChange={(e) => {
                                      const newValue = e.target.checked
                                        ? [
                                            ...(itemData[field?.id]?.value ||
                                              []),
                                            option,
                                          ]
                                        : (
                                            itemData[field?.id]?.value || []
                                          ).filter((v: string) => v !== option);
                                      handleTabFields({
                                        target: {
                                          name: field.id,
                                          value: newValue,
                                        },
                                      } as React.ChangeEvent<HTMLInputElement>);
                                    }}
                                    disabled={isEditable}
                                    id={`${Date.now().toString()}-${Math.random()}`}
                                    borderColor={
                                      field.isInvalid ? 'red.500' : undefined
                                    }
                                  >
                                    {typeof option === 'string'
                                      ? option
                                      : option.label}
                                  </Checkbox>
                                ))}
                            </VStack>
                            <Popover
                              isOpen={
                                activePopover.type === 'editOptions' &&
                                activePopover.id === field.id
                              }
                              onClose={() =>
                                setActivePopover({ id: null, type: null })
                              }
                              placement="right"
                            >
                              <PopoverContent width="300px">
                                <PopoverBody>
                                  <Text fontWeight="bold" mb={2}>
                                    Edit {field.type} Options
                                  </Text>
                                  <VStack align="stretch" spacing={2}>
                                    <FieldsEditor
                                      field={
                                        Array.isArray(field.options)
                                          ? field.options.join('\n')
                                          : ''
                                      }
                                      side={side}
                                      updateFieldOptionsTab={updateFieldOptions}
                                      type="tabs"
                                      valId={field.id}
                                    />
                                    <Button
                                      onClick={() =>
                                        setActivePopover({
                                          id: null,
                                          type: null,
                                        })
                                      }
                                      size="sm"
                                    >
                                      Close
                                    </Button>
                                  </VStack>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </>
                        ) : field.type === 'Relations' ? (
                          <RelationsField
                            field={field as import('../types/Field').Field}
                            updateFieldOptions={updateFieldOptions}
                            side={side as 'left' | 'right'}
                            isEditable={!isEditable}
                            itemData={itemData}
                            handleTabFields={handleTabFields}
                            isConfigOpen={isRelationsConfigOpen === field.id}
                            onConfigClose={() => setIsRelationsConfigOpen(null)}
                            //border={field.isInvalid ? "red.500" : undefined}
                          />
                        ) : field.type === 'Date' ? (
                          <DatePickerField
                            field={field}
                            value={itemData[field?.id]?.value}
                            onChange={(formattedDate) => {
                              handleTabFields({
                                target: {
                                  name: field.id,
                                  value: formattedDate,
                                },
                              } as React.ChangeEvent<HTMLInputElement>);
                            }}
                            isDisabled={isEditable}
                            //borderColor={field.isInvalid ? "red.500" : undefined}
                          />
                        ) : field.type === 'Label' ? (
                          <>
                            <LabelField
                              options={field.options as LabelOption[]}
                              value={itemData[field.id]?.value || ''}
                              onChange={(value) =>
                                handleTabFields({
                                  target: { name: field.id, value },
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                              isDisabled={isEditable}
                              updateFieldOptions={(newOptions) =>
                                updateFieldOptions(side, field.id, newOptions)
                              }
                            />
                            <Popover
                              isOpen={
                                activePopover.type === 'editOptions' &&
                                activePopover.id === field.id &&
                                field.type === 'Label'
                              }
                              onClose={() =>
                                setActivePopover({ id: null, type: null })
                              }
                              placement="right"
                            >
                              <PopoverContent
                                width="300px"
                                borderRadius="16px"
                                boxShadow="0px 3px 27px -20px rgba(112, 144, 176, 0.51)"
                              >
                                <PopoverHeader
                                  fontWeight="normal"
                                  p="20px 0 0 20px!important"
                                  borderBottom="0"
                                >
                                  Edit Label Options
                                </PopoverHeader>
                                <PopoverBody p={5}>
                                  <VStack align="stretch" spacing={3}>
                                    <LabelOptionEditor
                                      options={field.options as LabelOption[]}
                                      onChange={(newOptions) => {
                                        updateFieldOptions(
                                          side,
                                          field.id,
                                          newOptions
                                        );
                                        setActivePopover({
                                          id: null,
                                          type: null,
                                        });
                                      }}
                                    />
                                  </VStack>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </>
                        ) : field.type === 'Calculation' ? (
                          <>
                            <Input
                              value={itemData[field?.id]?.value ?? '0'}
                              isReadOnly
                              placeholder="Calculated value"
                            />
                            {isEditable && (
                              <Popover
                                isOpen={
                                  activePopover.type === 'editCalculation' &&
                                  activePopover.id === field.id
                                }
                                onClose={() =>
                                  setActivePopover({ id: null, type: null })
                                }
                                placement="right"
                              >
                                <PopoverContent width="400px">
                                  <PopoverBody>
                                    <Text fontWeight="bold" mb={2}>
                                      Set Calculation
                                    </Text>
                                    <CalculationEditor
                                      field={{
                                        id: field.id,
                                        options: field.options as {
                                          formula: string;
                                          fields: string[];
                                        },
                                      }}
                                      allFields={Object.values(fieldsByTab)
                                        .flatMap((tabFields) =>
                                          Object.values(tabFields).flat()
                                        )
                                        .filter(
                                          (f) =>
                                            f.type === 'Number' &&
                                            f.id !== field.id
                                        )
                                        .map((f) => ({
                                          id: f.id,
                                          label: f.label,
                                        }))}
                                      updateFieldOptions={(_, __, newOptions) =>
                                        updateFieldOptions(
                                          side,
                                          field.id,
                                          newOptions
                                        )
                                      }
                                      side={side}
                                      onClose={() =>
                                        setActivePopover({
                                          id: null,
                                          type: null,
                                        })
                                      }
                                    />
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            )}
                          </>
                        ) : field.type === 'Text Area' ? (
                          <Textarea
                            value={itemData[field?.id]?.value || ''}
                            onChange={handleTabFields}
                            name={field.id}
                            placeholder={`Enter ${field.label}`}
                            disabled={isEditable}
                            borderColor={
                              field.isInvalid ? 'red.500' : undefined
                            }
                          />
                        ) : field.type === 'File' ? (
                          <FileField
                            field={field}
                            isEditable={!isEditable}
                            handleTabFields={(event: {
                              target: {
                                name: string;
                                value: string | string[];
                              };
                            }) => {
                              // Cast the event to ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                              handleTabFields(
                                event as React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              );
                            }}
                            itemData={itemData}
                            uploadUrl={`${process.env.REACT_APP_API_URL}/api/company/upload-file`}
                            onUploadSuccess={(fileUrl) => {
                              handleTabFields({
                                target: {
                                  name: field.id,
                                  value: field.repeater
                                    ? (itemData[field.id]?.value || []).concat(
                                        fileUrl
                                      )
                                    : fileUrl,
                                },
                              } as React.ChangeEvent<HTMLInputElement>);
                            }}
                            onUploadError={(error) => {
                              console.error('Error uploading file:', error);
                              // You can add a toast notification here
                            }}
                            acceptedFileTypes={[
                              'image/*',
                              'application/pdf',
                              'application/msword',
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                              'application/vnd.ms-excel',
                              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            ]}
                            maxFileSize={10 * 1024 * 1024} // 10MB
                            //borderColor={field.isInvalid ? "red.500" : undefined}
                          />
                        ) : field.type === 'Iframe' ? (
                          <Box>
                            <InputGroup>
                              <Input
                                type="text"
                                placeholder="Enter iframe URL"
                                name={field.id}
                                value={itemData[field.id]?.value || ''}
                                onChange={handleTabFields}
                                disabled={isEditable}
                                borderColor={
                                  field.isInvalid ? 'red.500' : undefined
                                }
                              />
                              {itemData[field.id]?.value && (
                                <IconButton
                                  aria-label="Open in iframe"
                                  icon={<BsWindow />}
                                  size="sm"
                                  ml={2}
                                  mt={1}
                                  colorScheme="orange"
                                  onClick={() => {
                                    setActivePopover({
                                      id: field.id,
                                      type: 'iframe',
                                    });
                                  }}
                                />
                              )}
                            </InputGroup>

                            <CenteredPopover
                              isOpen={
                                activePopover.type === 'iframe' &&
                                activePopover.id === field.id
                              }
                              onClose={() =>
                                setActivePopover({ id: null, type: null })
                              }
                              title={field.label}
                              width="90vw"
                              height="90vh"
                            >
                              <Box
                                width="100%"
                                height="calc(90vh - 100px)"
                                position="relative"
                              >
                                <iframe
                                  src={getEmbedUrl(itemData[field.id]?.value)}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'none',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                  }}
                                  title={field.label}
                                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowFullScreen
                                />
                              </Box>
                            </CenteredPopover>
                          </Box>
                        ) : field.type === 'TrueFalse' ? (
                          <TrueFalseField
                            key={field.id}
                            id={field.id}
                            name={field.id}
                            label=""
                            value={getBooleanValue(itemData[field.id]?.value)}
                            onChange={handleTrueFalseField}
                            isDisabled={isEditable}
                            fieldLabel={field.fieldLabel || ""}
                          />
                        ) : field.type === 'Price' ? (
                          <PriceField
                            field={field}
                            value={itemData[field.id]?.value || ''}
                            onChange={handleTabFields}
                            isDisabled={isEditable}
                            isInvalid={field.isInvalid}
                          />
                        ) : (
                          <InputGroup>
                            <Input
                              type={field.type === 'Number' ? 'number' : 'text'}
                              placeholder={`Enter ${field.type.toLowerCase()}`}
                              disabled={isEditable}
                              onChange={handleTabFields}
                              name={field.id}
                              value={itemData[field?.id]?.value}
                              borderColor={
                                field.isInvalid ? 'red.500' : undefined
                              }
                            />
                          </InputGroup>
                        )}
                      </Box>
                      {field.repeater && (
                        <Box mt={2}>
                          {(itemData[field.id]?.repeaterValues || []).map(
                            (repeaterValue: any, index: number) => (
                              <Flex key={index} mt={2} alignItems="center">
                                <Box flex="1">
                                  {field.type === 'Select' ? (
                                    <Select
                                      value={repeaterValue}
                                      onChange={(e) =>
                                        handleRepeaterFieldChange(
                                          field.id,
                                          index,
                                          e.target.value
                                        )
                                      }
                                      id={`select-${Math.random() * 9999}`}
                                    >
                                      {Array.isArray(field.options) &&
                                        field.options.map(
                                          (option, optionIndex) => (
                                            <option
                                              key={optionIndex}
                                              value={
                                                typeof option === 'string'
                                                  ? option
                                                  : option.label
                                              }
                                            >
                                              {typeof option === 'string'
                                                ? option
                                                : option.label}
                                            </option>
                                          )
                                        )}
                                    </Select>
                                  ) : field.type === 'Checkbox List' ? (
                                    <VStack align="start" spacing={2}>
                                      {Array.isArray(field.options) &&
                                        field.options.map(
                                          (option, optionIndex) => (
                                            <Checkbox
                                              key={optionIndex}
                                              isChecked={repeaterValue.includes(
                                                option
                                              )}
                                              onChange={(e) => {
                                                const newValue = e.target
                                                  .checked
                                                  ? [...repeaterValue, option]
                                                  : repeaterValue.filter(
                                                      (v: string) =>
                                                        v !== option
                                                    );
                                                handleRepeaterFieldChange(
                                                  field.id,
                                                  index,
                                                  newValue
                                                );
                                              }}
                                            >
                                              {typeof option === 'string'
                                                ? option
                                                : option.label}
                                            </Checkbox>
                                          )
                                        )}
                                    </VStack>
                                  ) : field.type === 'date' ? (
                                    <DatePickerField
                                      field={field}
                                      value={repeaterValue}
                                      onChange={(date) =>
                                        handleRepeaterFieldChange(
                                          field.id,
                                          index,
                                          date
                                        )
                                      }
                                      isDisabled={!isEditable}
                                    />
                                  ) : field.type === 'File' ? null : ( // Don't render anything extra for File type
                                    <Input
                                      type={
                                        field.type === 'Number'
                                          ? 'number'
                                          : 'text'
                                      }
                                      value={repeaterValue}
                                      onChange={(e) =>
                                        handleRepeaterFieldChange(
                                          field.id,
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </Box>
                                {field.type !== 'File' && ( // Only show delete button for non-File fields
                                  <IconButton
                                    aria-label="Remove repeater field"
                                    icon={<DeleteIcon />}
                                    colorScheme="gray"
                                    size="sm"
                                    ml={2}
                                    onClick={() =>
                                      removeRepeaterField(field.id, index)
                                    }
                                  />
                                )}
                              </Flex>
                            )
                          )}
                          {field.type !== 'File' && ( // Only show Add button for non-File fields
                            <Button
                              leftIcon={<AddIcon />}
                              size="sm"
                              mt={2}
                              colorScheme="secondaryGray"
                              fontWeight="normal"
                              variant="outline"
                              onClick={() =>
                                addRepeaterField(field.id, field.type)
                              }
                            >
                              Add {field.label}
                            </Button>
                          )}
                        </Box>
                      )}
                    </FormControl>
                  )}
                </Draggable>
              );
            })}
          <FieldCreationButton
            side={side as 'left' | 'right'}
            isEditable={isEditable}
            leftPopoverOpen={leftPopoverOpen}
            centerPopoverOpen={centerPopoverOpen}
            rightPopoverOpen={rightPopoverOpen}
            setLeftPopoverOpen={setLeftPopoverOpen}
            setCenterPopoverOpen={setCenterPopoverOpen}
            setRightPopoverOpen={setRightPopoverOpen}
            addField={addField}
          />
        </VStack>
      );
    };

    const getFieldLabel = (fieldId: string): string => {
      return (
        Object.values(fieldsByTab)
          .flatMap((tab) => [...tab.left, ...tab.center, ...tab.right])
          .find((field) => field.id === fieldId)?.label || fieldId
      );
    };

    const validateFields = (fields: Field[]) => {
      return fields.every((field) => {
        return (
          field.id && field.type && field.label && field.position && field.tabId
        );
      });
    };

    const handleTrueFalseField = (e: { target: { name: string; value: boolean } }) => {
      handleTabFields({
        target: {
          name: e.target.name,
          value: e.target.value ? "true" : "false"
        }
      });
    };

    const getBooleanValue = (value: any): boolean => {
      if (typeof value === 'boolean') return value;
      if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
      }
      return Boolean(value);
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        {isLoading ? (
          <Box>Loading field data...</Box>
        ) : (
          <Flex>
            <Droppable droppableId="left">
              {(provided) => (
                <Box
                  width="33%"
                  pr={2}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {renderFields(fieldsByTab[currentTabId]?.left || [], 'left')}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
            <Droppable droppableId="center">
              {(provided) => (
                <Box
                  width="33%"
                  px={2}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {renderFields(
                    fieldsByTab[currentTabId]?.center || [],
                    'center'
                  )}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
            <Droppable droppableId="right">
              {(provided) => (
                <Box
                  width="33%"
                  pl={2}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {renderFields(
                    fieldsByTab[currentTabId]?.right || [],
                    'right'
                  )}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Flex>
        )}

        {isOpen && (
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Field
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want to delete this field? This action cannot
                  be undone.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}
      </DragDropContext>
    );
  } catch (error) {
    console.error('An error occurred in TabContentSection:', error);
    return <div>An error occurred. Please try refreshing the page.</div>;
  }
};

export default TabContentSection;
